import React, { useState, useRef, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import useFetchData from '../../../hooks/getData';
import axios from 'axios';
import useFetchCommon from '../../../hooks/getCommonData';
import { BASE_URL_TWO } from '../../../../utils';
import { message } from "antd";
import { Rating } from '@mui/material';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function EmployeeKPIQuestion() {
  const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const [showSubmit, setShowSubmit] = useState(false);
  const [answers, setAnswers] = useState([]);
  const commandRef = useRef(null);
  const navigate = useNavigate();

  let { fetchedData } = useFetchData('getKpiQuestions');
  const id = Cookies.get('userId');
  const token = Cookies.get("token");

  const { commonDataFetch } = useFetchCommon('getKpiQuestionsMessage', { id, token });

  const [isvisible, setIsvisible] = useState(true);
  const handleAlert = () => {
    setIsvisible(false);
  }

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  useEffect(() => {
    if (fetchedData && fetchedData?.length > 0) {
      setAnswers(fetchedData.map((data) => ({
        kpiQuestionId: data?.kpiQuestionId,
        rating: data?.rating ? data?.rating : 0,
        answer: data?.comments ? data?.comments : ""
      })));
    }
  }, [fetchedData]);

  const showMessage = () => {
    message.success({
      content: 'Answers submitted',
      duration: 4
    });
  };

  const showFailureMessage = () => {
    message.info({
      content: 'Failed to submit',
      duration: 2
    });
  };

  const handleRatingChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index].rating = parseInt(value); // Convert value to integer if necessary
    setAnswers(newAnswers);
  };

  const handleCommentChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index].answer = value;
    setAnswers(newAnswers);
  };

  const handleContinueClick = () => {
    if (currentTableIndex < fetchedData?.length - 1) {
      setCurrentTableIndex(currentTableIndex + 1);
    } else {
      handleSubmit();
      setShowSubmit(true);
    }
  };

  const handlePreviousClick = () => {
    if (currentTableIndex > 0) {
      setCurrentTableIndex(currentTableIndex - 1);
    }
  };

  const handleSubmit = async () => {
    try {
      await Promise.all(
        answers.map(async (data) => {
          await axios.post(`${BASE_URL_TWO}`, {
            sp: "insEmployeeKpiAnswers",
            userId: parseInt(id),
            kpiQuestionId: data?.kpiQuestionId,
            rating: data?.rating,
            comments: data?.answer
          }, { headers });
        })
      );
      showMessage();
    } catch (error) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error("Failed to submit")
      }
    }
  };

  const currentQuestion = fetchedData ? fetchedData[currentTableIndex] : null;
  const currentAnswer = answers ? answers[currentTableIndex] : {};

  return (
    <>
      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
        KPI Questions
      </h5>

      {isvisible && (
        <div className="alert alert-dismissible bg-warning-subtle d-flex flex-column flex-sm-row mb-15">
          <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
            <i className="bi bi-exclamation-triangle-fill fs-2hx px-5 svg-icon-muted text-warning"></i>
          </span>
          <div className="d-flex flex-column pe-0 pe-sm-10">
            {commonDataFetch?.map((data, index) => (
              <div key={index}>
                <h5 className="mt-2 text-primary">{data?.policyTitle}</h5>
                <span className='fw-bold fs-7 text-gray-600'>
                  {data?.description}
                </span>
              </div>
            ))}
          </div>
          <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" onClick={() => handleAlert()}>
            <span className="svg-icon svg-icon-1 svg-icon-primary">
              <i className="bi bi-x fs-2hx px-5 svg-icon-muted text-primary"></i>
            </span>
          </button>
        </div>
      )}

      {currentQuestion && <Card>
        <Card.Header className="d-flex justify-content-center">
          <div className='d-flex align-items-center w-600px w-sm-600px flex-column mt-3'>
            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
              <span className='fw-bold fs-6 text-gray-600'>Question Completion</span>
              <span className='fw-bolder fs-6 text-primary'>{currentTableIndex + 1}/{fetchedData?.length}</span>
            </div>
            <div className='h-8px mx-3 w-100 bg-body-secondary mb-3'>
              <div
                className='bg-primary rounded h-8px'
                role='progressbar'
                style={{ width: `${((currentTableIndex + 1) / fetchedData?.length) * 100}% ` }}
              ></div>
            </div>
          </div>
        </Card.Header>

        <Card.Body className='mb-10 mt-5 p-20'>
          <div>
            <h3 className='text-primary '>Deadline: <span>{currentQuestion?.deadLine.split('-').reverse().join("-")}</span></h3>
          </div>
          <table className="table table-rounded border border-gray-100 table-row-bordered table-row-gray-100 gy-4 gs-7 shadow" style={{ background: "#f0eded" }}>
            <thead>
              <tr className="fw-bold fs-6 text-white" style={{ background: "#6255a3" }}>
                <th>{currentQuestion?.questionType}</th>
                <th>My Rating</th>
              </tr>
            </thead>
            <tbody>
              {currentQuestion && (
                <tr style={{ background: '' }} className="text-primary fw-bold fs-7">
                  <td>{currentQuestion?.questionText}</td>
                  <td>
                    <div className="rating">
                      <Rating
                        name="simple-controlled"
                        value={answers[currentTableIndex]?.rating ? answers[currentTableIndex]?.rating : (currentQuestion?.rating) ? currentQuestion?.rating : 0}
                        onChange={(e) => handleRatingChange(currentTableIndex, e.target.value)}
                        sx={{ fontSize: "2rem" }}
                      />
                      {/* {[1, 2, 3, 4, 5].map((value) => (
                        <React.Fragment key={value}>
                          <label className="rating-label" htmlFor={`kt_rating_input_${value}`}>
                            <KTSVG
                              path="/media/icons/duotune/general/gen029.svg"
                              className="svg-icon svg-icon-1"
                            />
                          </label>
                          <input
                            className="rating-input"
                            name="rating"
                            value={(currentQuestion?.rating)?currentQuestion?.rating:value}
                            type="radio"
                            id={`kt_rating_input_${value}`}
                            checked={currentAnswer?.rating == value}
                            onChange={(e) => handleRatingChange(currentTableIndex, e.target.value)}
                          />
                        </React.Fragment>
                      ))} */}
                    </div>
                  </td>
                </tr>
              )}
              {currentQuestion && (

                <tr style={{ background: '' }}>
                  <td colSpan={2}>
                    <div className="mb-3">
                      <textarea
                        ref={commandRef}
                        className="form-control fs-7"
                        style={{ background: "#f2f0f0" }}
                        placeholder='Enter your comments....'
                        value={(answers[currentTableIndex]?.answer) ? answers[currentTableIndex]?.answer : ""}
                        onChange={(e) => handleCommentChange(currentTableIndex, e.target.value)}
                      ></textarea>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-grid gap-2 d-md-flex justify-content-md-between mt-5">
            <Button className="btn btn-sm" variant="primary" onClick={handlePreviousClick} disabled={currentTableIndex === 0}>
              Previous
            </Button>
            <Button className="btn btn-sm" variant="primary" onClick={handleContinueClick}>
              {currentTableIndex === fetchedData?.length - 1 ? 'Save All' : 'Continue'}
            </Button>
          </div>
        </Card.Body>
      </Card>}
      {
        !currentQuestion && <Card className="d-flex justify-content-center align-items-center  " style={{ minHeight: "300px" }}>
          <p className='text-primary fw-bold fs-3'>No Questions</p>
        </Card>
      }

      {/* {showSubmit && (
        <Card className="mt-5 mb-5" style={{ background: "#f0eded" }}>
          <Card.Body>
            <p className='text-danger fw-bold'>The submission option is currently unavailable. Submissions can only be made at the end of the year, specifically in December. </p>
            <Button className="btn btn-sm" variant="danger" onClick={handleSubmit}>
              Submit
            </Button>
          </Card.Body>
        </Card>
      )} */}
    </>
  );
}

export { EmployeeKPIQuestion };
