import { color, minHeight } from '@mui/system';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const RichTextEditor = ({ value, onChange, className ,suppressContentEditableWarning}) => {
  
  return (
    <div className=''>
      <ReactQuill
        value={value}
        className={className}
        onChange={onChange}
        modules={RichTextEditor.modules}
        formats={RichTextEditor.formats}
        style={{ backgroundColor: "white", borderRadius:"10px", minHeight: "250px" }}
      />
      <style>
        {`
          .ql-container {
            min-height: 250px;
          }
        `}
      </style>
    </div>
  );
};

RichTextEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link'],
    ['clean']
  ],
};

RichTextEditor.formats = [
  'header', 'font',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'script',
  'color', 'background',
  'align',
  'link',
];

export default RichTextEditor;
