import { bgcolor } from '@mui/system';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { CardsWidget21, TablesWidget8 } from '../../../../_metronic/partials/widgets';

import { Announcements } from './Announcements';
import { EXPdocuments } from './EXPdocuments';
import { LeaveStatus } from './LeaveStatus';
import { Grid } from '@mui/material';
import { useState } from 'react';
import useFetchData from '../../../hooks/getData';
import { CardsWidget44 } from '../../../../_metronic/partials/widgets/_new/cards/CardWidget44';

const DashboardWrapper = () => {
  const [isVisible,setIsVisible] = useState(false)
  const  leaveDatas = useFetchData('getLeaveStatus').fetchedData;
  const  expDocuments = useFetchData('GetExpiredDocumentsForEmployee').fetchedData;
  const  announcements = useFetchData('getAnnouncements').fetchedData;
  return (
    <>
      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>Employee Dashboard</h5>

      <div className='row g-3 g-md-5 g-xl-10 d-flex justify-content-center'>
        <div className='col-xl-40 me-5 flex-fill'>
            <TablesWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>

        <div
          className='col-12 col-sm-6 col-md-3  col-lg-3 col-xl-3 col-xxl-3 mb-3 mb-md-4 text-white'>
          <a className="text-white" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" data-bs-target="#collapseExample" >
            <CardsWidget44
              className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
              color='#6255a3'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Announcements'
              url='#'
              count={announcements?.length ? announcements?.length : 0}
            />
          </a>
        </div>


        <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3 mb-md-4 text-white'>
          <a className="text-white" data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2" data-bs-target="#collapseExample2" >
            <CardsWidget44
              className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
              color='#143983'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Expired Documents'
              count={expDocuments?.filter(item=>item?.ExpiryType === "Already Expired")[0]?.totalCount || 0}
              url='#'
            />
          </a>
        </div>

        <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3 mb-md-4 text-white'>
          <a className="text-white" data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample3" data-bs-target="#collapseExample3" onClick={()=>setIsVisible(true)} >
            <CardsWidget44
              className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
              color='#34a8cb'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Leaves Status'
              count={leaveDatas?.reduce((sum, item) => sum + item.totalcount, 0)}
              url='#'
            />
          </a>
        </div>

      </div>

      <div className='accordion accordion-borderless' id='kt_accordion'>

        <Announcements />

        <EXPdocuments />

        <LeaveStatus isVisible={isVisible}/>

      </div>

    </>
  )
};

export { DashboardWrapper };
