import { useEffect, useState } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import axios from 'axios';
import { GET_EMPLOYEE_ALL_FOLDERS } from '../../../../apis';
import { useFolderPathContext } from '../../../hooks/findPathContext';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';
import { BASE_URL_TWO } from '../../../../utils';
import CompneyDocUpload from '../Organisation/CompneyDocUpload';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';

const EmployeeFolder = () => {
    const { folderPath, setFolderPath } = useFolderPathContext();
    const [employeesFolders, setEmployeesFolders] = useState([])
    const [searchQuery,setSearchQuery] = useState('')

    const handleSearch = (e)=>{
        setSearchQuery(e.target.value)
    }

    const id = Cookies.get('userId');
    const token = Cookies.get('token');


    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        axios.get(`${GET_EMPLOYEE_ALL_FOLDERS}&userId=${id}`, { headers }).then((response) => {
            if (response.data && response.data.Data) {
                setEmployeesFolders(response?.data?.Data)
            }
        });
    }, []);


    // const handleSearch = (e) => {
    //     setSearchQuery(e.target.value);
    // };

    // const filteredFolders = folderData?.Folders?.filter((folder) =>
    //     folder?.Name.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    // const filteredFiles = folderData?.Files?.filter((file) =>
    //     file?.Name?.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    return (
        <>
            <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                <div className='d-flex my-2'>
                    <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                            type='text'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder='Search'
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>
                </div>
                {employeesFolders?.filter(item=>item.employeeName.toLowerCase().includes(searchQuery.toLowerCase()))?.map((folder, index) => (
                    <div className='col-12 col-sm-12 col-xl' key={index} >
                        <Card4
                            icon='/media/svg/files/download.svg'
                            title={folder?.employeeName}
                            link={`EmployeeFamilyMemberPage/${folder?.userId}`}
                            check={true}
                        // description={`${folder?.Files?.length + folder?.Folders?.length} Files`}
                        />
                    </div>
                ))}
                {employeesFolders?.filter(item=>item.employeeName.toLowerCase().includes(searchQuery.toLowerCase())).length <=0 && <NoFiles/>}
            </div>
        </>
    );
};

export { EmployeeFolder };
