import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import axios from 'axios';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
// import { KTIcon } from '../../../_metronic/helpers';
import { message } from "antd";
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import PopUp from '../../../components/Modal/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaKey } from 'react-icons/fa';
import EditPassword from './EditPassword';
import EditStaff from './EditStaff';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { KTIcon } from '../../../../_metronic/helpers';
import AddStaff from './AddStaff';

export default function StaffList() {

    const id = Cookies.get('userId');
    const token = Cookies.get('token')
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [staffList, setStaffList] = useState([])
    const [selectedStaff, setSelectedStaff] = useState()
    const [show, setShow] = useState(false);
    const [actionStatus, setActionStatus] = useState(false);
    const [staff, setStaff] = useState()
    const [visible, setVisible] = useState(false);
    const [deactivateStatuses, setDeactivateStatuses] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState()
    const [companyList, setCompanyList] = useState()
    const [addVisible, setAddVisible] = useState(false)

    const navigate = useNavigate()

    const fetchCompanies = async () => {
        const response = await axios.get(`${BASE_URL}getCompanies`)
        setCompanyList(response?.data?.Data);
    }
    useEffect(() => {
        fetchCompanies()
    }, [])

    const companyOptions = companyList?.map(item => ({
        label: item?.companyName,
        id: item?.companyId
    }))

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };


    const fetchStaffs = async () => {
        const response = await axios.get(`${BASE_URL}getEmployersByCompanyId&userId=${id}&companyId=${selectedCompany ? selectedCompany : ''}`, { headers })
        setLoading(false)
        setStaffList(response?.data?.Data);
    }
    useEffect(() => {
        fetchStaffs()
    }, [shouldRefetch, selectedCompany])


    const showMessage = (msg) => {
        message.destroy();
        message.success({
            content: msg,
            duration: 1
        });
    };

    const a = () => {
        message.info({
            content: "This division can't delete",
            duration: 2
        });
    }

    const handleStatus = async () => {
        try {
            if (actionStatus && staff) {
                const response = await axios.post(`${BASE_URL}`, {
                    "sp": "updEmployeeStatus",
                    userId: id,
                    status: !staff?.status,
                    employeeId: staff?.userId
                }, { headers });
                message.success(`${staff?.status === true ? "Successfully De-activated" : "Successfully Activated"}`)
                setActionStatus(false)
                setStaff(null)
                setShouldRefetch(!shouldRefetch)
            }
        } catch (error) {
            setActionStatus(false)
            if (error?.response?.status === 401) {
                message.destroy();
                message.error("Session Timeout, Please Login to continue")
                navigate('/error/login')
            }
        }
    }

    useEffect(() => {
        if (actionStatus && staff) {
            handleStatus()
        }
    }, [actionStatus, staff])

    useEffect(() => {
        setDeactivateStatuses(staffList?.map(item => item?.status))
    }, [staffList])

    return (
        <>
            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title={`${staff?.status == true ? 'De activate the employer' : "Activate the employer"}`}
                message={`${staff?.status == true ? 'Are you sure you want to de-activate the employer' : 'Are you sure you want to activate the employer'}`}
                btnName="Continue"
            />
            <AddStaff visible={addVisible} onClose={() => setAddVisible(false)} setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch} />
            <EditPassword selectedStaff={selectedStaff} />
            <EditStaff onClose={() => setVisible(false)} setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch} visible={visible} staffData={selectedStaff} />
            <h5 className="text-black-50 mb-10">
                <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Staff List
            </h5>
            {<div className=' d-flex justify-content-between align-items-center mb-3'>
                <div className='col-3 col-md-3'>
                    <Autocomplete
                        disablePortal
                        id='yearField'
                        color="primary"
                        options={companyOptions}
                        onInputChange={(event, value) => {
                            // Check for valid value before updating state
                            setSelectedCompany(value?.id || ''); // Set to null if no valid company is selected
                            setShouldRefetch(prevState => !prevState);
                        }}
                        onChange={(event, value) => {
                            // Handle the unselect case where value is null or undefined
                            setSelectedCompany(value?.id || ''); // Set to null if no valid company is selected
                            setShouldRefetch(prevState => !prevState);
                        }}
                        classes={{
                            input: 'text-dark'
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                focused
                                label="Company"
                                placeholder="Select a company"
                            />
                        }
                    />

                </div>
                <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <span
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        onClick={() => setAddVisible(true)}
                    >
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </span>
                </div>

            </div >}

            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid container>
                    {!loading && <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                        <thead>
                            <tr className="fw-bold fs-5 bg-primary rounded text-white">
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th>Company Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                staffList?.map((data, index) =>
                                    <tr style={{ borderBottom: "" }} key={index}>
                                        <td className="text-primary fw-bold"><span className="badge">{index + 1}</span></td>
                                        <td className="text-primary fw-bold">{data?.firstName}</td>
                                        <td className="text-primary fw-bold">{data?.lastName}</td>
                                        <td className="text-primary fw-bold">{data?.phoneNumber}</td>
                                        <td className="text-primary fw-bold">{data?.email}</td>
                                        <td className="text-primary fw-bold">{data?.companyName}</td>
                                        <td className="text-primary fw-bold d-flex gap-3">
                                            <div className="form-check form-switch form-check-custom form-check-solid me-2">
                                                <input
                                                    className="form-check-input h-20px w-40px"
                                                    type="checkbox"
                                                    value=""
                                                    id={`employerStatus${index}`}
                                                    checked={deactivateStatuses?.[index]}
                                                    onChange={(e) => {
                                                        handleStatus(index, e.target.checked)
                                                        setStaff(data)
                                                        setShow(true)
                                                    }}
                                                />
                                                {/* <label className="form-check-label fw-bold text-primary" htmlFor={`flexSwitch${index}`}>
                                                     disable question
                                                  </label> */}
                                            </div>
                                            <span
                                                // onClick={() => setSelectedStaff(data)}
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                                // data-bs-toggle="modal"
                                                // data-bs-target="#kt_modal_4"
                                                title="Edit"
                                                onClick={() => { setVisible(true); setSelectedStaff(data) }}
                                            >
                                                <ModeEditIcon
                                                    sx={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </span>
                                            <a href="/"
                                                onClick={() => setSelectedStaff(data)}
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_3"
                                                title="Reset password"
                                            >
                                                <FaKey
                                                    sx={{
                                                        cursor: 'pointer',
                                                        ml: 3
                                                    }}
                                                />
                                            </a>
                                        </td>
                                    </tr>
                                )
                            }
                            { }
                            {staffList?.length <= 0 && <tr><td className='text-center' colSpan={7}>No Records Found</td></tr>}
                        </tbody>
                    </table>}
                    {loading && <div className='text-center d-flex justify-content-center align-items-center' style={{ height: "500px", width: "100%" }}><ClipLoader color='#143983' size={45} /></div>}
                </Grid>
            </Grid>
        </>
    )
}
