import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { message } from "antd";
import axios from 'axios';
import { FiEye, FiEyeOff } from "react-icons/fi"; // Import React Icons
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, 'New password must be at least 8 characters') // Minimum 8 characters validation
        .required('New password is required'),
    reEnterPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match') // Ensures passwords match
        .required('Re-enter password is required'),
});


export default function EditPassword({ selectedStaff, setShouldRefetch }) {

    const id = Cookies.get('userId');
    const token = Cookies.get("token");

    const headers = {
        'token': token,
        'Content-Type': 'application/json',
    };

    const showMessage = () => {
        message.success({
            content: 'Password updated successfully',
            duration: 1,
        });
    };

    const initialValues = {
        newPassword: '',
        reEnterPassword: '',
    };

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showReEnterPassword, setShowReEnterPassword] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            try {
                await axios.post(`${BASE_URL_TWO}`, {
                    sp: 'resetUserPassword ',
                    newPassword: values?.newPassword,
                    staffId: selectedStaff?.userId,
                    userId:Number(id)
                },{headers});
                showMessage();
                resetForm(); // Reset the form to initial values
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                setStatus('Failed to submit');
                console.error(error);
            }
        },
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Reset Your Password</h5>
                            <button
                                type="button"
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={formik?.resetForm}
                            >
                                ✖
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">
                                {/* New Password */}
                                <div className="col-12">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">New Password</label>
                                    <div className="d-flex align-items-center border rounded px-2 bg-body-secondary">
                                        <input
                                            type={showNewPassword ? 'text' : 'password'}
                                            className="form-control border-0 bg-transparent"
                                            name="newPassword"
                                            value={formik.values.newPassword}
                                            onChange={formik.handleChange}
                                            placeholder="Enter new password"
                                            style={{ boxShadow: 'none' }}
                                        />
                                        <button
                                            type="button"
                                            className="btn bg-transparent"
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                        >
                                            {showNewPassword ? <FiEye size={16} /> :<FiEyeOff size={16} /> }
                                        </button>
                                    </div>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.newPassword}</span>
                                </div>

                                {/* Re-Enter Password */}
                                <div className="col-12">
                                    <label className="form-label fw-bold fs-7 text-light-emphasis">Re-Enter Password</label>
                                    <div className="d-flex align-items-center border rounded px-3 bg-body-secondary">
                                        <input
                                            type={showReEnterPassword ? 'text' : 'password'}
                                            className="form-control border-0 bg-transparent"
                                            name="reEnterPassword"
                                            value={formik.values.reEnterPassword}
                                            onChange={formik.handleChange}
                                            placeholder="Re-enter new password"
                                            style={{ boxShadow: 'none' }}
                                        />
                                        <button
                                            type="button"
                                            className="btn border-0 bg-transparent"
                                            onClick={() => setShowReEnterPassword(!showReEnterPassword)}
                                        >
                                            {showReEnterPassword ? <FiEye size={16} /> : <FiEyeOff size={16} /> }
                                        </button>
                                    </div>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.reEnterPassword}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={formik?.resetForm}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
