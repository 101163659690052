import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useFetchData from '../../../hooks/getData';
import { KTSVG } from '../../../../_metronic/helpers';
import { message, Modal } from "antd";
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Department Name is  required'),
    companyName: Yup.string().required('Company is  required')
})

export default function CreateDepartment({ companyList, visible, onClose,setShouldRefetch,shouldRefetch }) {

    const [employeId, setEmployeName] = useState()

    const [selectedCompany, setSelectedCompany] = useState(null)

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.destroy()
        message.success({
            content: 'Added new Department',
            duration: 1
        });
    };

    const a = () => {
        message.info({
            content: 'Department not created',
            duration: 2
        });
    }

    const initialValues = {
        companyName: '',
        name: ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'insDepartment',
                userId: id,
                deptName: values?.name,
                companyId: Number(selectedCompany)
            }, { headers }).then(() => {
                showMessage()
                resetForm();
                onClose();
                formik.setFieldValue('companyName', '')
                setSelectedCompany(null)
                setShouldRefetch(!shouldRefetch);
            }).catch((err) => {
                message.destroy()
                message.error(err.response?.data?.Message ? err.response?.data?.Message : "Something went wrong")
            })
        },
    });

    const handleClose = () => {
        formik.resetForm();
        onClose();
        setSelectedCompany(null);
        formik.setFieldValue('companyName', null)
    }

    return (
        <Modal
            title="Create new department"
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
            centered
            width={800} // Adjust modal width
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="" role="document">
                    <div>
                        <div>
                            <div className="row g-3">

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company Name</label>
                                    <select
                                        name="companyName"
                                        onChange={(e) => {
                                            setSelectedCompany(e.target.value)
                                            formik.handleChange(e);
                                        }}
                                        className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'>
                                        <option value=''>Select Company</option>
                                        {companyList?.map(item => <option value={item?.companyId}>{item?.companyName}</option>)}

                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.companyName}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Department Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='name'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer mt-3">
                            <button type="button" className="btn btn-light"  onClick={handleClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary ms-3">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
