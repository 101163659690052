import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { FC } from 'react';
import useFetchData from '../../../hooks/getData';
import { CardsWidget30 } from '../../../../_metronic/partials/widgets';

const LeavePolicy: FC = () => {
  // Fetch company policies data
  const { fetchedData } = useFetchData('getCompanyPolicies');

  // Predefined leave policy items
  const leaveItems = [
    { title: 'Sick Leave', color: '#34a8cb' },
    { title: 'Maternity Leave', color: '#6255a3' },
    { title: 'Paternity Leave', color: '#143983' },
    { title: 'Marriage Leave', color: '#6255a3' },
    { title: 'Unpaid Leave', color: '#143983' },
    { title: 'Hajj Leave', color: '#6255a3' },
    { title: 'Emergency Leave', color: '#143983' },
    { title: 'Annual Leave', color: '#6255a3' },
    { title: 'Additional Leave', color: '#34a8cb' },
    { title: 'Flex Work', color: '#143983' },
  ];

  // State for selected title
  const [checkTitle, setCheckTitle] = useState('');

  // Filter fetched data based on the selected title
  const filteredData = fetchedData?.filter((data) => data?.policyTitle === checkTitle);

  return (
    <>
      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
        Leave Policy
      </h5>

      {/* Leave items (cards) */}
      <div className="row g-6 g-xl-30 mb-10 mb-xl-4 justify-content-md-center">
        <div className="row col-md-6 g-6 g-xl-30 mb-5 mb-xl-2 justify-content-md-center">
          {leaveItems.map((item, index) => (
            <div
              key={index}
              onClick={() => setCheckTitle(item.title)} // Set selected title on click
              className="col-md-4 col-sm-6 mb-md-2"
            >
              <a
                href={`#${item.title.replace(/\s+/g, '_')}`} // Anchor link to the section
                className="text-white"
              >
                <CardsWidget30
                  className='h-md-45 mb-1'
                  color={item?.color}
                  img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                  title={item?.title}
                  description=''
                />
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Policy content sections */}
      <div id="box-policy">
        {filteredData?.map((data, index) => (
          <div
            key={index}
            className="accordion accordion-borderless"
            id={`accordion_${data.policyTitle.replace(/\s+/g, '_')}`}
          >
            <div
              className="accordion-item bg-transparent pb-3"
              id={data.policyTitle.replace(/\s+/g, '_')} // Unique ID for the section
            >
              <div className="accordion-body text-body-secondary rounded-4 p-15 container bg-primary-subtle text-primary">
                <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export { LeavePolicy };
