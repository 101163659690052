import React, { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormik } from 'formik'
import { Grid } from '@mui/material';
import { KTIcon } from '../../../../../../_metronic/helpers';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../../../utils';
import { message } from 'antd';
import Cookies from 'js-cookie';


const validationSchema = Yup.object().shape({
  businessDescription: Yup.string(),

});

const validationSchema2 = Yup.object().shape({
  businessDescription: Yup.string()
});

const Step3 = (props) => {
  const [benefits, setBenefits] = useState([]);
  const id = Cookies.get('userId');
  const token = Cookies.get('token');
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  const { setJobDescriptionBenifits, setSteps, result } = props
  localStorage.setItem('jobDescription2', JSON.stringify(JSON.parse(localStorage.getItem('jobDescription2')) ? JSON.parse(localStorage.getItem('jobDescription2')) : result))

  const localStorageData = JSON.parse(localStorage.getItem('jobDescription2'))

  const names = localStorageData.benefits ? JSON.parse(localStorageData.benefits)?.map(item => item?.benefitName) : localStorageData?.selectedItems?.map(item => item?.benefitName);
  const [data, setData] = useState(names ? benefits?.map(item => names?.includes(item.benefitName) ? { benefitName: item.benefitName, status: true } : item) : benefits);
  const [filename, setFilename] = useState(localStorageData?.filename || '');

  useEffect(() => {
    const fetchBenefits = async () => {
      const response = await axios.get(`${BASE_URL}getBenefitsForDropdown&userId=${id}`, { headers })
      setBenefits(response?.data?.Data);
      setData(names ? response?.data?.Data?.map(item => names?.includes(item.benefitName) ? { benefitName: item.benefitName, status: true } : item) : response?.data?.Data)
    }
    fetchBenefits()
  }, [])


  const [one, setOne] = useState()

  const handleCheckboxChange = (index) => {
    const newData = [...data];
    newData[index].status = !newData[index].status;
    setData(newData);
  };


  const selectedItems = data?.filter((item) => item.status);


  const [newBenefitName, setNewBenefitName] = useState('');

  const handleInputChange = (e) => {
    setNewBenefitName(e.target.textContent);
  };

  /// for handling file 
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const fileSizeInKB = file.size / 1024; // convert bytes to kilobytes

      if (fileSizeInKB > 500) {
        alert("File size exceeds 500KB");
        return;
      }

      setFile(event.currentTarget.files[0]);
      setFilename(event.currentTarget.files[0].name);
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // Convert file to base64 string
    });
  };

  const handleDownload = async () => {
    try {
      if (localStorageData?.businessDescription) {
        const response = await fetch(`${BASE_URL_TWO}${localStorageData?.businessDescription}`);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = result?.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href); // Clean up the URL object
      } else {
        message.error("No Document Found")
        return;
      }
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const initialValues = {

    businessDescription: localStorageData?.businessDescription ? localStorageData?.businessDescription : result?.jobDescription ? result?.jobDescription : '',
    benifits: localStorageData?.selectedItems ? localStorageData?.selectedItems : ''

  }

  const formik = useFormik({
    initialValues,
    validationSchema: result.jobDescription ? validationSchema2 : validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {

      // console.log("first", values)

      console.log(localStorageData?.businessDescription)
      const fileBase64 = file ? await convertFileToBase64(file) : localStorageData?.businessDescription;

      localStorage.setItem('jobDescription2', JSON.stringify({
        jobRoleId: localStorageData?.jobRoleId,
        businessDescription: fileBase64 ? fileBase64 : localStorage.businessDescription,
        selectedItems: selectedItems,
        filename: filename
      }))

      setJobDescriptionBenifits(
        {
          jobRoleId: localStorageData?.jobRoleId,
          businessDescription: fileBase64 ? fileBase64 : localStorage.businessDescription,
          benifits: selectedItems,
          filename: filename
        }
      )

      setSteps("step4")

    },
    enableReinitialize: true
  });


  return (
    <div className='w-100'>

      <div className='pb-10 pb-lg-15'>
        <h4 className='fw-bolder text-danger'>Set Employee Job Description & Company Benefits</h4>
      </div>

      <form>

        <div className='fv-row mb-10'>
          <label className='form-label text-primary fw-bold'><i className="bi bi-person-lines-fill fs-3 svg-icon-muted text-primary me-3 "></i>Job Description / Employee Responsibilities </label> <br />
          <button type="button" className="btn btn-primary" onClick={() =>
            handleDownload()}>
            Download Document
          </button>
          <div className='mt-2'>
            <input
              type="file"
              id="businessDescription"
              name="businessDescription"
              style={{ display: 'none' }} // Hide the default file input
              onChange={(event) => {
                handleFileChange(event);
                formik.setFieldValue('businessDescription', event.currentTarget.files[0]); // Update Formik's state
              }}
            />
            <label htmlFor="businessDescription" className="custom-file-label btn btn-primary me-2">Upload</label>
            <label htmlFor="" className='text-primary fs-5' >
              {filename ? filename : 'Choose file'}
            </label>
          </div>
          <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.businessDescription}</span>
        </div>

        <div className="d-md-flex justify-content-md-center" >
          <table className="table table-striped table-hover table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gs-7 shadow">

            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200" style={{ backgroundColor: '#143983' }}>
                <th style={{ color: 'white' }}><i className="bi bi-ui-checks-grid fs-3 me-3 svg-icon-muted text-white"></i>Benefit Name<span className='text-danger'>*</span></th>
              </tr>
            </thead>

            <tbody>

              {
                data?.map((item, index) => (
                  <tr key={index}>
                    <td className='text-primary fw-bold'>
                      <label className='form-check form-check-inline form-check-solid me-5'>
                        <input
                          className='form-check-input border border-primary'
                          name={`communication[${index}]`}
                          type='checkbox'
                          checked={item?.status}
                          onChange={() => handleCheckboxChange(index)} // Pass index to identify the checkbox
                        />
                        <span className='fw-bold ps-2 fs-8'>{item?.benefitName}</span>
                      </label>
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>

        <Grid container justifyContent="space-between" sx={{ mt: 4, mb: 7 }}>

          <div className='mr-2'>
            <button
              onClick={() => setSteps('step2')}
              type='button'
              className='btn btn-lg btn-light-primary'
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1 mb-1' />
              Back
            </button>
          </div>

          <button onClick={formik.handleSubmit} type='button' className='btn btn-lg btn-primary'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='indicator-label'>
                Continue
              </span>
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0 mt-1' />
            </div>
          </button>
        </Grid>

      </form>

    </div>
  )
}

export { Step3 }
