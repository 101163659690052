import { FC, useEffect, useState } from 'react'
import { Grid, IconButton, TextField, Tabs, Tab } from '@mui/material'
import axios from 'axios'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import useFetchData from '../../../hooks/getData'
import Cookies from 'js-cookie'
import { BASE_URL_TWO } from '../../../../utils'
import { Button, message } from "antd";
import PopUp from '../../../components/Modal/Modal'
import { FadeLoader } from 'react-spinners'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles'


const LeaveNotification = () => {
  const [comment, setComment] = useState('');
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery,setSearchQuery] = useState('')
  const [dummy,setDummy] = useState('')

  const statusMap = ['pending', 'approved', 'rejected'];
  const status = statusMap[tabIndex];

  const { fetchedData, isLoading } = useFetchData(`getLeaveRequestsForEmployee&filter=${status}`, [shouldRefetch]);
  const data = fetchedData || [];
  const id = Cookies.get('userId');
  const token = Cookies.get("token")

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };


  const [expandedItem, setExpandedItem] = useState(null);

  const handleToggleExpand = (leaveRequestId) => {
    setExpandedItem(expandedItem === leaveRequestId ? null : leaveRequestId);
  };

  const showMessage = (msg) => {
    message.success({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const a = (msg) => {
    message.info({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const handleSearch = ()=>{
    setSearchQuery(dummy)
  }


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setShouldRefetch(prevState => !prevState);
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();

    // Title text
    const title = `Leave Details of ${data?.employeeName}`;

    // Calculate text width and center it
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getTextWidth(title);
    const x = (pageWidth - textWidth) / 2;

    // Add centered title
    doc.text(title, x, 20);

    // Convert the single data object to rows for the table
    const tableRows = [
      ["Employee Name", data.employeeName || ''],
      ["Start Date", data.startDate || ''],
      ["End Date", data.endDate || ''],
      ["Duration", data.duration || 0],
      ["Contact During Leave", data.contactDuringLeave || ''],
      ["Workplace", data.workplace || ''],
      ["Leave Type", data.leaveType || ''],
      ["Email", data.email || ''],
      ["Job Title", data.jobTitle || ''],
      ["Employee Comments", data.employeeComments || 'No comments']
    ];

    // Generate the table
    doc.autoTable({
      body: tableRows,
      startY: 30,
      styles: {
        fontSize: 8,
        cellPadding: 2
      },
      margin: { top: 20 },
      theme: 'grid'
    });

    // Save the PDF
    doc.save('leave-details.pdf');
  };

  return (
    <>
      <h5 className="text-black-50 mb-3"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>Leave Histories<span className="badge badge-circle badge-danger mx-3">{data?.length}</span></h5>
      <div className="d-md-flex  justify-content-between align-items-center">
        <div>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="leave request tabs" className='mb-5'>
          <Tab label="Pending" className='fw-bold ' style={{ fontSize: "14px" }} />
          <Tab label="Approved" className='fw-bold ' style={{ fontSize: "14px" }} />
          <Tab label="Rejected" className='fw-bold ' style={{ fontSize: "14px" }} />
        </Tabs>
        </div>
        <div className="d-flex  align-items-center gap-2 mb-4 mb-md-0">
          <input type="search" className="form-control form-control-lg  border border-1 fs-7 bg-body-secondary" style={{border:"1px solid gray"}} placeholder="Search here" style={{maxWidth:"350px"}} onChange={(e)=>setDummy(e.target.value)}/>
          <button className="btn btn-primary" onClick={()=>setSearchQuery(dummy)}>Search</button>
        </div>
      </div>

      {(data.length === 0 && !isLoading) ? (
        <div className='d-flex justify-content-center align-items-center w-100 mt-2' style={{ minHeight: "500px" }} >
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <img src='https://cdn-icons-png.flaticon.com/512/7466/7466140.png' alt="icon" style={{ width: "100%", maxWidth: '80px', height: 'auto' }} />
            <h6 style={{ background: '' }} className='container mx-auto text-center my-5 text-2xl'>
              No leave requests...
            </h6>
          </div>
        </div>
      ) : (
        <>
          {data?.filter(item=>item?.employeeName.toLowerCase().includes(searchQuery.toLowerCase()))?.map((data, index) => {
            const isExpanded = expandedItem === data.leaveRequestId;
            const bgColores = ["#143983", "#34a8cb", "#6255a3", "#1ABC9C", "#F1C40F", "#78281F"];
            const randomIndices = Array.from({ length: bgColores.length }, (_, index) => index);
            let currentIndex = 0;
            for (let i = randomIndices.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [randomIndices[i], randomIndices[j]] = [randomIndices[j], randomIndices[i]];
            }
            const randomIndexGenerate = randomIndices[index % bgColores.length];

            return (
              <>
                {isLoading && <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "400px", width: "100%" }}><FadeLoader color='#0d6efd' /></div>}
                {!isLoading && <div className="accordion accordion-icon-toggle" id="kt_accordion_3" key={data.leaveRequestId}>
                  <div className="card text-white mb-2 mb-xl-5" style={{ backgroundColor: "#143983" }}>
                    <div className="card-header px-4 d-flex align-items-center justify-content-center" style={{ border: 'none' }}>
                      <div className="symbol symbol-50px">
                        <img src={data?.profileImage ? BASE_URL_TWO + data?.profileImage:toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
                      </div>
                      <div className='d-flex justify-content-start accordion-header flex-grow-1 m-4' data-bs-toggle="collapse" data-bs-target={`#kt_accordion_3_item_${index}`} onClick={() => handleToggleExpand(data?.leaveRequestId)}>
                        <h2 className="text-white fw-bold mx-2 mx-xl-5">{data?.employeeName}</h2>
                      </div>
                      <div className='m-4'>
                        {statusMap[tabIndex] !== "pending" && <span className='text-white' style={{ fontSize: "18px" }}>{data?.employerComments}</span>}
                      </div>
                    </div>
                    <div id={`kt_accordion_3_item_${index}`} className={`fs-6 collapse ps-10 pe-10 ${isExpanded ? 'show' : ''}`} data-bs-parent="#kt_accordion_3">
                      <div className='d-flex'>
                        <div className='flex-grow-1 p-2'>
                          <h6 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-telephone-fill p-2 text-primary fs-3 "></i> Contact During Leave  </span>{'+'+data?.contactDuringLeave}</h6>
                          {data?.workplace ? <h6 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-buildings-fill p-2 text-primary fs-3"></i>Place of Work  </span> {data?.workplace}</h6> : ''}
                          <h6 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-check-all p-2 text-primary fs-2"></i>Leave Type  </span> {data?.leaveType}</h6>
                          <h6 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-envelope-at-fill p-2 text-primary fs-3"></i>Mail ID  </span> {data?.email}</h6>
                          <h6 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-diagram-3-fill p-2 text-primary fs-3"></i>Job Title  </span>{data?.jobTitle}</h6>
                          <h6 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-airplane-fill p-2 text-primary fs-3"></i>Airline Ticket  </span>{data?.airlineTicket}</h6>
                          <a onClick={()=>{
                            if(!data?.supportingDocumentPath){
                              alert("No file found")
                            }
                          }} href={data?.supportingDocumentPath ?`${BASE_URL_TWO}${data?.supportingDocumentPath}`:"#"} target={data?.supportingDocumentPath ?'_blank':"_self"} className="btn btn-sm badge badge-danger fw-bold fs-6 mb-3" type="button" title="View Document" >
                            View Submitted Document <i className="bi bi-file-earmark-arrow-down-fill fs-2 mx-2 m-1 text-white"></i>
                          </a>
                          <h6 className='text-white mb-5'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-chat-left-text-fill text-primary p-2 fs-3"></i>My Comments</span></h6>
                          <div className="mb-3 w-100">
                            {data?.employeeComments == null ? (
                              <h6 className='text-white mb-3'>'No comments'</h6>
                            ) : statusMap[tabIndex] == "pending" ? (
                              <textarea className="form-control bg-info-subtle bg-opacity-75 text-light fs-7" id="exampleFormControlTextarea1" style={{ height: '110px' }} readOnly>{data?.employeeComments}</textarea>
                            ) : (<textarea className="form-control bg-info-subtle bg-opacity-75 text-light fs-7" id="exampleFormControlTextarea1" style={{ height: '110px' }} readOnly>{data?.employeeComments}</textarea>)}
                          </div>
                        </div>
                        <div className='p-2'>
                          <h4 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"> <i className="bi bi-calendar2-day-fill p-1 text-primary fs-2"> </i> Start Date: {data?.startDate}</span></h4>
                          <h4 className='text-white mb-3'><span className="badge badge-light-primary me-5 fs-6 pe-3"><i className="bi bi-calendar2-day-fill p-1 text-primary fs-2"></i> Return Date: {data?.endDate}</span></h4>
                          <h4 className='text-white mb-3'><span className="badge badge-light-danger me-5 fs-6 pe-3"><i className="bi bi-hourglass-top p-1 text-danger fs-2"></i>Duration: {data?.duration} Days</span></h4>
                          {statusMap[tabIndex] == "approved" && <h4 className='text-white mb-3' onClick={() => generatePDF(data)}><span className="badge badge-light-danger me-5 fs-6 pe-3"><i className="bi bi-download p-1 text-danger fs-2"></i>Download as document</span></h4>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </>
            );
          })}
          {data?.filter(item=>item?.employeeName.toLowerCase().includes(searchQuery.toLowerCase()))?.length <=0 && <NoFiles/>}
        </>
      )}
    </>
  )
}

const MyLeaveNotificationPage = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>Notifications</PageTitle> */}
      <LeaveNotification />
    </>
  )
}

export { MyLeaveNotificationPage }
