import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  CardsWidget7,
  CardsWidget30
} from '../../../_metronic/partials/widgets'
import { FaRegEdit } from "react-icons/fa";
import { useMemo, FC, useState, useRef, useEffect, SetStateAction } from 'react';
import { Autocomplete, Box, IconButton, TableRow, TextField, Modal } from '@mui/material';
import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import { VscEye } from "react-icons/vsc";
import { MdClose } from "react-icons/md";
import { MaterialReactTable, type MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import Cookies from 'js-cookie';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { message } from 'antd';
import RichTextEditor from '../../../_metronic/helpers/components/RichTextEditor';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';

export type FlexibleWorkDetails = {
  employeeName: string
  department: string
  flexibleWorkType: string
  reason: string
  date: string
  requestDate: string
  approvalDate: string
  employeeComment: string
  comment: string
  status: string
};

interface TermCondition {
  FlexWorkType: string;
  contentText: string;
}

export type FlexibleWorkRequestDetails = {
  FlexbleWorkId: any;
  employeeName: string
  department: string
  flexibleWorkType: string
  reason: string
  date: string
  requestDate: string
  employeeComment: string
  comment: string
};


interface NewFlexibleWorkRequestDetails {
  id: string;
  original: {
    employeeName: string;
    department: string;
    flexibleWorkType: string;
    reason: string;
    date: string;
    requestDate: string;
    employeeComment: string;
  };
}



const EmployeeFlexWorkTracker = () => {

  const MonthOptions = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const nowDate = new Date()
  const [month, setMonth] = useState<number>(nowDate.getMonth())
  const [comment, setComment] = useState<String>();
  const [selectedRow, setSelectedRow] = useState<NewFlexibleWorkRequestDetails | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const halfDayRef = useRef<HTMLSpanElement>(null);
  const remoteWorkRef = useRef<HTMLSpanElement>(null);
  const [termsAndConditions, setTermsAndConditions] = useState<TermCondition[]>([])
  const [pendingRequests, setPendingRequests] = useState<FlexibleWorkRequestDetails[]>([])
  const [comments, setComments] = useState<Record<string, string>>({});
  const [historyDetails, setHistoryDetails] = useState<FlexibleWorkDetails[]>([])
  const [editedText1, setEditedText1] = useState<string >('')
  const [editedText2, setEditedText2] = useState<string>('')
  const navigate = useNavigate()

  const Token = Cookies.get('token')
  const id = Cookies.get('userId')

  const headers = {
    'token': Token,
    'Content-Type': 'application/json'
  };

  function handleToggle(targetId:any) {
    const allAccordions = document.querySelectorAll('.collapse');
    
    allAccordions.forEach((accordion) => {
      if (accordion.id === targetId) {
        accordion.classList.toggle('show');
      } else {
        accordion.classList.remove('show');
      }
    });
  }

  // Function to handle the submission
  const updateTermsAndConditions = async (flexType: any) => {

    try {
      const response = await axios.post(`${BASE_URL_TWO}`, {
        "sp": "updFlexWorkTermsConditions",
        "contentText": flexType === "Half Day" ? editedText2 : editedText1,
        "userId": Number(id),
        "flexWorkType": flexType
      }, { headers });
      if (response) {
        message.success("Successfully Updated");
        setEditedText1('');
        setEditedText2('')
        setTimeout(()=>{
          window.location.reload()
        },1000)
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
     
    }
  };

  const fetchTermsAndConditions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}getFlexWorkTermsConditions&userId=${id}`,{headers});
      setTermsAndConditions(response?.data?.Data)
    } catch (error: any) {
      message.error(error?.Message || "Something went Wrong");
      console.error("error fetching terms and conditions", error)
    }
  }

  const fetchPendingRequests = async () => {
    try {
      const response = await axios.get(`${BASE_URL_TWO}`, {
        headers,
        params: {
          sp: "getFlexWorkLeavePending",
          userId: Number(id),
        },
      });
      setPendingRequests(response?.data?.Data)
    } catch (error: any) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
    }
  }

  const fetchHistoryDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL_TWO}`, {
        headers,
        params: {
          sp: "getFlexWorkLeaveAcceptORReject",
          userId: Number(id),
          month: month + 1
        }
      });
      setHistoryDetails(response?.data?.Data)
    } catch (error: any) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
    }
  }

  useEffect(() => {
    fetchTermsAndConditions();
    fetchPendingRequests();
  }, [])

  useEffect(() => {
    fetchHistoryDetails();
  }, [month])



  const handleAcceptOrReject = async (data: any, status: any) => {
    try {
      const response = await axios.post(`${BASE_URL_TWO}`, {
        "sp": "updFlexWorkLeaveAcceptORReject",
        "userId": Number(id),
        "status": status,
        "employerComments": comments[data?.original?.FlexbleWorkId] || '',
        "FlexbleWorkId": data?.original.FlexbleWorkId
      }, { headers });
      if (status === "Approved") {
        delete comments[data?.original?.FlexbleWorkId]
        setComments(comments)
        fetchPendingRequests()
        fetchHistoryDetails();
        message.success("Request Approved")
      } else {
        delete comments[data?.original?.FlexbleWorkId]
        setComments(comments)
        fetchPendingRequests()
        fetchHistoryDetails();
        message.error("Request Rejected")
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
    }
  }

  const handleOpenModal = (row: NewFlexibleWorkRequestDetails) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setModalOpen(false);
  };
  
  const columns = useMemo<MRT_ColumnDef<FlexibleWorkDetails>[]>(
    () => [
      {
        accessorFn: (row) => `${row.employeeName}`,
        id: 'employeeName',
        header: 'Employee Name',
        size: 200,
        filterFn: 'multipleFilter',
        enablePinning: true,
        muiTableHeadCellProps: {
          style: {
            paddingLeft: '1rem'
          }
        },
        muiTableBodyCellProps: {
          style: {
            paddingLeft: '1rem'
          }
        }
      },
      {
        accessorFn: (row) => row.department,
        id: 'department',
        header: 'Department',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.flexibleWorkType,
        id: 'flexibleWorkType',
        header: 'Flexible Work Type',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.reason,
        id: 'reason',
        header: 'Reason',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.date,
        id: 'Date',
        header: 'Date',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? value : '';
        },

      },
      {
        accessorFn: (row) => new Date(row.requestDate), //convert to Date for sorting and filtering
        id: 'requestDate',
        header: 'Request Date',
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      },
      {
        accessorFn: (row) => new Date(row.approvalDate), //convert to Date for sorting and filtering
        id: 'approvalDate',
        header: 'Approval Date',
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        size: 200,
        enablePinning: false,
        Cell: ({ cell, row }) => {
          const value = row.original.status;
          const statusClass =
            value === 'Rejected' ? 'badge badge-light-danger px-0 fw-normal' :
              ''; // Default class if needed

          return <span className={statusClass} style={{ fontSize: "13px" }}>{cell.getValue<Date>()?.toLocaleDateString()}</span>;
        },
      },
      {
        accessorFn: (row) => row.employeeComment ? row.employeeComment : "N/A",
        id: 'employeeComment',
        header: 'Employee Comment',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.comment ? row.comment : 'N/A',
        id: 'comment',
        header: 'Comment',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.status,
        id: 'status',
        header: 'Status',
        size: 200,
        enablePinning: false,
        Cell: ({ row }) => {
          const value = row.original.status;
          const statusClass =
            value === 'Approved' ? 'badge badge-light-success fs-7 fw-semibold' :
              value === 'Rejected' ? 'badge badge-light-danger fs-7 fw-semibold' :
                ''; // Default class if needed

          return <span className={statusClass}>{value}</span>;
        },
      }

    ],
    []
  );

  const requestTableColumns = useMemo<MRT_ColumnDef<FlexibleWorkRequestDetails>[]>(
    () => [
      {
        accessorFn: (row) => `${row.employeeName}`,
        id: 'employeeName',
        header: 'Employee Name',
        size: 200,
        filterFn: 'multipleFilter',
        enablePinning: true,
        muiTableHeadCellProps: {
          style: {
            paddingLeft: '1rem'
          }
        },
        muiTableBodyCellProps: {
          style: {
            paddingLeft: '1rem'
          }
        }
      },
      {
        accessorFn: (row) => row.department,
        id: 'department',
        header: 'Department',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.flexibleWorkType,
        id: 'flexibleWorkType',
        header: 'Flexible Work Type',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.reason,
        id: 'reason',
        header: 'Reason',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.date,
        id: 'Date',
        header: 'Date',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? value : '';
        },
      },
      {
        accessorFn: (row) => new Date(row.requestDate), //convert to Date for sorting and filtering
        id: 'requestDate',
        header: 'Request Date',
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      },

      {
        accessorFn: (row) => row.employeeComment ? row.employeeComment : "N/A",
        id: 'employeeComment',
        header: 'Employee Comment',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.comment,
        id: 'comment',
        header: 'Comment',
        size: 200,
        enablePinning: false,
        Cell: ({ cell, row }) => (
          <TextField
            className='text-black'
            fullWidth
            label='Add Comment'
            id='Comments'
            value={comments[row?.original?.FlexbleWorkId]}
            onChange={(event) => {
              setComments((prevComments) => ({ ...prevComments, [row?.original?.FlexbleWorkId]: event.target.value }));
            }}
            inputProps={{
              className: 'text-black fs-4'
            }}
            InputLabelProps={{
              className: 'text-black'
            }}
            sx={{
              color: 'black', // Text color
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black', // Border color for the default state
                },
                '&:hover fieldset': {
                  borderColor: 'black', // Border color when hovering
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black', // Border color when focused
                },
              },
            }}
          />
        ),
      },

    ],
    [comments]
  );

  const exportToExcel = () => {
    const filteredData = historyDetails.map((data) => {
      return {
        "Employee Name": data.employeeName,
        "Department": data.department,
        "flexible Work Type": data.flexibleWorkType,
        "Reason": data.reason,
        "Date": data.date,
        "Request Date": data.requestDate,
        "Approval Date": data.approvalDate,
        "Employee Comment": data.employeeComment,
        "Comment": data.comment,
        "Status": data?.status
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'FlexWork History.xlsx');
  };


  const renderRowActions = ({ row }: { row: any }) => (
    <Box sx={{ justifyContent: 'center' }}>
      <IconButton className='px-2' onClick={() => handleAcceptOrReject(row, "Approved")}>
        <KTSVG path="/media/icons/duotune/arrows/arr016.svg" className="svg-icon-muted text-success svg-icon-2hx" />
      </IconButton>
      <IconButton className='px-2' onClick={() => handleAcceptOrReject(row, "Rejected")}>
        <KTSVG path="/media/icons/duotune/arrows/arr015.svg" className="svg-icon-muted text-danger svg-icon-2hx" />
      </IconButton>
      <IconButton className='px-2' onClick={() => handleOpenModal(row)}>
        <VscEye />
      </IconButton>
    </Box>
  );


  return (
    <div>
        <h5 className="text-black-50 mb-10">
    <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
    Flexible Work Tracker
  </h5>

  <div className='row g-6 g-xl-30 mb-10 mb-xl-4 justify-content-md-center'>

    <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3 mb-md-4 text-white'>
      <a className="text-white" onClick={() => handleToggle('FW_pendingRequests')}>
        <CardsWidget30
          className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
          color='#6255a3'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          title='Flexible Work Requests'
          description=''
        />
      </a>
    </div>

    <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3 mb-md-4 text-white'>
      <a className="text-white" onClick={() => handleToggle('FW_history')}>
        <CardsWidget30
          className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
          color='#143983'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          title='Flexible Work History'
          description=''
        />
      </a>
    </div>

    <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mb-3 mb-md-4 text-white'>
      <a className="text-white" onClick={() => handleToggle('UpdateTC')}>
        <CardsWidget30
          className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
          color='#34a8cb'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          title='Update Terms & Conditions'
          description=''
        />
      </a>
    </div>

  </div>

      <div className='accordion accordion-borderless' id='kt_accordion_1' style={{ border: 'shadow-white' }}>

        <div className='accordion-item bg-transparent pb-3 '>
          <div
            id='FW_pendingRequests'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_1'
            data-bs-parent='#kt_accordion_1'
          >
            <div className={`card`}>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5 pb-2'>
                <h3 className='card-title align-items-start flex-column'>
                  <strong>
                    Flexible Work Pending Requests
                  </strong>
                </h3>
              </div>
              {/* end::Header */}
              <div className='card-body'>

                <MaterialReactTable
                  columns={requestTableColumns}
                  data={pendingRequests}
                  enableDensityToggle={false}
                  enableColumnFilters
                  enableColumnOrdering
                  enablePinning
                  enableRowActions
                  initialState={{
                    showColumnFilters: false,
                    sorting: [
                      { id: "employeeName", desc: false },
                    ],
                    expanded: true,
                    columnPinning: { left: ["employeeName"] }
                  }}
                  positionToolbarAlertBanner="bottom"
                  positionActionsColumn='last'
                  renderRowActions={renderRowActions}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Action',
                      size: 145,
                      muiTableHeadCellProps: {
                        className: 'px-7 py-4 text-black',
                        sx: {
                          alignItems: 'center',
                          verticalAlign: 'middle',
                          backgroundColor: '#EBEBD3 !important',
                          outline: '6px',
                          outlineStyle: 'solid',
                          outlineColor: 'var(--bs-light)',
                          outlineOffset: '-5px',
                          borderRadius: '20px',
                        }
                      }
                    },
                  }}

                  filterFns={{
                    multipleFilter: (row: { getValue: (arg0: any) => any; }, columnId: any, filterValue: string) => {
                      const filterArray = filterValue.replaceAll(',', ';').split(';')
                      const cellVal = row.getValue(columnId)
                      for (let item of filterArray) {
                        if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                          return true
                      }
                      return false
                    }
                  }}

                  muiTablePaperProps={{
                    sx: {
                      backgroundColor: 'white !important',
                      border: 'none',
                      boxShadow: 'none'
                    }
                  }}
                  muiTableProps={{
                    className: 'table',
                    sx: {
                      borderCollapse: 'separate',
                      borderSpacing: '0px 4px',
                      '&::-webkit-scrollbar': {
                        height: '12px', // Adjust height for the horizontal scrollbar
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888', // Custom thumb color
                        borderRadius: '10px', // Optional: round the thumb
                        border: '3px solid #fff', // Optional: add border around the thumb
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: 'black', // Darker color on hover
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'black', // Track color (background behind the thumb)
                      },
                    },
                  }}
                  muiTableContainerProps={{
                    className: 'mb-2',
                    sx: {
                      backgroundColor: 'var(--bs-light)',
                      borderRadius: 'var(--bs-card-border-radius)',
                    }
                  }}
                  muiTableHeadRowProps={{
                    className: 'fw-bold bg-light',
                    sx: {
                      boxShadow: 'none'
                    }
                  }}
                  renderEmptyRowsFallback={() => (
                    <Box sx={{ textAlign: 'center', fontSize: '1.2rem', color: '#999', padding: '1rem' }}>
                      No records found
                    </Box>
                  )}
                  muiTableHeadCellProps={{
                    className: 'px-7 py-3 text-black',
                    sx: {
                      verticalAlign: 'middle',
                      backgroundColor: '#EBEBD3 !important',
                      outline: '10px',
                      outlineStyle: 'solid',
                      outlineColor: 'var(--bs-light)',
                      outlineOffset: '-5px',
                      borderRadius: '20px',
                    }
                  }}
                  muiTableBodyRowProps={{
                    hover: false,
                    sx: {
                      backgroundColor: 'transparent'
                    }
                  }}
                  muiTableBodyCellProps={{
                    className: 'bg-light bg-hover-secondary'
                  }}
                  muiTopToolbarProps={{
                    className: 'card text-light mb-2',
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: '#143983'
                    },
                  }}
                  muiBottomToolbarProps={{
                    className: 'text-light mb-5 d-flex',
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: '#143983',
                      borderRadius: 'var(--bs-card-border-radius)',
                      minHeight: '50px'
                    },
                  }}
                  muiSearchTextFieldProps={{
                    className: 'bg-light text-light text-muted'
                  }}
                  // muiTableHeadCellFilterTextFieldProps={{
                  //   helperText: false,
                  //   focused: true,
                  //   inputProps: {
                  //     className: "text-black",
                  //   },
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  // muiTableHeadCellColumnActionsButtonProps={{
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  // muiTableHeadCellDragHandleProps={{
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  icons={{
                    FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                    FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                    ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                    FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                    FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                    SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                    CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                  }}
                  // muiTablePaginationProps={{
                  //   className: 'text-white',
                  // }}
                  enableGlobalFilter={false}
                  muiToolbarAlertBannerProps={{
                    className: 'text-white',
                    sx: {
                      backgroundColor: '#143983',
                    }
                  }}
                  muiToolbarAlertBannerChipProps={{
                    className: 'text-white'
                  }}
                />
                <Modal open={modalOpen} onClose={handleCloseModal}>
                  <div className="modal-dialog" style={{ maxWidth: '800px', margin: '1.75rem auto' }}>
                    <div className="modal-content" style={{ backgroundColor: 'white', borderRadius: '4px', padding: '20px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)' }}>
                      <div className="modal-header d-flex justify-content-between" style={{ borderBottom: '1px solid #e5e5e5', paddingBottom: '15px' }}>
                        <h5 className="modal-title pt-2" style={{ marginBottom: '0' }}>Request Details</h5>
                        <button
                          className="btn btn-icon btn-sm btn-active-light-primary me-1"
                          aria-label="Close"
                          onClick={handleCloseModal}
                        >
                          <MdClose />
                        </button>
                      </div>
                      <div className="modal-body" style={{ paddingTop: '15px' }}>
                        {selectedRow && (
                          <div>
                            <p><strong >Employee Name: </strong>{selectedRow.original.employeeName}</p>
                            <p><strong>Department: </strong>{selectedRow.original.department}</p>
                            <p><strong>Flexible Work Type: </strong>{selectedRow.original.flexibleWorkType}</p>
                            <p><strong>Reason: </strong>{selectedRow.original.reason}</p>
                            <p><strong>Date Range: </strong>{selectedRow.original.date}</p>
                            <p><strong>Request Date: </strong>{new Date(selectedRow.original.requestDate).toLocaleDateString()}</p>
                            <p><strong>Employee Comment: </strong>{selectedRow.original.employeeComment || "N/A"}</p>
                            <label htmlFor=""><strong>Comment</strong></label>
                            <TextField
                              className='text-black'
                              fullWidth
                              value={comments[selectedRow?.id]}
                              onChange={(event) => setComment(event.target.value)}
                              inputProps={{
                                className: 'text-black fs-4'
                              }}
                              InputLabelProps={{
                                className: 'text-black'
                              }}
                              disabled={true}
                              sx={{
                                color: 'black',
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: 'black',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'black',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'black',
                                  },
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {/* <div className="modal-footer" style={{ borderTop: '1px solid #e5e5e5', paddingTop: '15px' }}>
                        <IconButton className='px-2' onClick={() => { }}>
                          <KTSVG path="/media/icons/duotune/arrows/arr016.svg" className="svg-icon-muted text-success svg-icon-2hx" />
                        </IconButton>
                        <IconButton className='px-2' onClick={() => { }}>
                          <KTSVG path="/media/icons/duotune/arrows/arr015.svg" className="svg-icon-muted text-danger svg-icon-2hx" />
                        </IconButton>
                      </div> */}
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <div className='accordion accordion-borderless' id='kt_accordion_1' style={{ border: 'shadow-white' }}>
          <div className='accordion-item bg-transparent pb-3'>
            <div
              id='FW_history'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_2'
              data-bs-parent='#kt_accordion_2'
            >
              <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 pb-2'>
                  <h3 className='card-title align-items-start flex-column'>
                    <strong>
                      Flexible Work History Table
                    </strong>
                  </h3>
                </div>
                {/* end::Header */}
                <div className='card-body'>
                  <div className='row mt-md-2 mt-xl-4 mb-md-4 mb-md-8 mb-5'>
                    <div className='col-12 d-flex justify-content-between align-items-center'>
                      <div className='col-md-3'>
                        <Autocomplete
                          disablePortal
                          id='yearField'
                          color="primary"
                          options={MonthOptions}
                          onInputChange={(event, value) => {
                            setMonth(MonthOptions.indexOf(value) !== -1 ? MonthOptions.indexOf(value) : nowDate.getMonth())
                          }}
                          onChange={(event, value) => {
                            setMonth(MonthOptions.indexOf(value ?? MonthOptions[nowDate.getMonth()]) !== -1 ? MonthOptions.indexOf(value ?? MonthOptions[nowDate.getMonth()]) : nowDate.getMonth())
                          }}
                          classes={{
                            input: 'text-dark'
                          }}
                          defaultValue={MonthOptions[nowDate.getMonth()]}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              required
                              focused
                              label="Month"
                            />
                          }
                        />
                      </div>
                      <div>
                        <a onClick={exportToExcel} className='btn btn-sm btn-primary'>
                          <KTIcon iconName='file-down' className='fs-2' />
                          Export to Excel
                        </a>
                      </div>
                    </div>
                  </div>

                  <MaterialReactTable
                    columns={columns}
                    data={historyDetails}
                    enableDensityToggle={false}
                    enableColumnFilters
                    enableColumnOrdering
                    enablePinning
                    initialState={{
                      showColumnFilters: false,
                      sorting: [
                        { id: "employeeName", desc: false },
                      ],
                      expanded: true,
                      columnPinning: { left: ["employeeName"] }
                    }}
                    positionToolbarAlertBanner="bottom"

                    sortingFns={{
                      status: (rowA: { getValue: (arg0: any) => any; }, rowB: { getValue: (arg0: any) => any; }, columnID: any) => {
                        const valA = rowA.getValue(columnID)
                        const valB = rowB.getValue(columnID)
                        if (valA === 'Pending' && valB !== 'Pending') {
                          return 1
                        }
                        else if (valB === 'Pending' && valA !== 'Pending') {
                          return -1
                        }
                        else {
                          return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                        }

                      }

                    }}
                    filterFns={{
                      dateFilter: (row: { getValue: (arg0: any) => any; }, columnId: any, filterValue: string) => {
                        const filterArray = filterValue.replaceAll(',', ';').split(';')
                        const cellVal = row.getValue(columnId)
                        const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
                        for (let item of filterArray) {
                          const itemArray = item.replaceAll(/\s+/g, '#').split('#')
                      
                          if (cellVal.getFullYear().toString() === itemArray[itemArray.length - 1])
                            for (let i = 0; i < itemArray.length - 1; i++) {
                              if (itemArray[i].includes(monthName[cellVal.getMonth()]))
                                return true
                            }
                        }
                        return false
                      },
                      multipleFilter: (row: { getValue: (arg0: any) => any; }, columnId: any, filterValue: string) => {
                        const filterArray = filterValue.replaceAll(',', ';').split(';')
                        const cellVal = row.getValue(columnId)
                        for (let item of filterArray) {
                          if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                            return true
                        }
                        return false
                      }
                    }}

                    muiTablePaperProps={{
                      sx: {
                        backgroundColor: 'var(--bs-card-bg)',
                        border: 'none',
                        boxShadow: 'none'
                      }
                    }}
                    muiTableProps={{
                      className: 'table',
                      sx: {
                        borderCollapse: 'separate',
                        borderSpacing: '0px 4px',
                      }
                    }}
                    muiTableContainerProps={{
                      className: 'mb-2',
                      sx: {
                        backgroundColor: 'var(--bs-light)',
                        borderRadius: 'var(--bs-card-border-radius)',
                      }
                    }}
                    muiTableHeadRowProps={{
                      className: 'fw-bold bg-light',
                      sx: {
                        boxShadow: 'none'
                      }
                    }}
                    renderEmptyRowsFallback={() => (
                      <Box sx={{ textAlign: 'center', fontSize: '1.2rem', color: '#999', padding: '1rem' }}>
                        No records found
                      </Box>
                    )}
                    muiTableHeadCellProps={{
                      className: 'px-7 py-3 text-black',
                      sx: {
                        verticalAlign: 'middle',
                        backgroundColor: '#EBEBD3 !important',
                        outline: '10px',
                        outlineStyle: 'solid',
                        outlineColor: 'var(--bs-light)',
                        outlineOffset: '-5px',
                        borderRadius: '20px',
                      }
                    }}
                    muiTableBodyRowProps={{
                      hover: false,
                      sx: {
                        backgroundColor: 'transparent'
                      }
                    }}
                    muiTableBodyCellProps={{
                      className: 'bg-light bg-hover-secondary'
                    }}
                    muiTopToolbarProps={{
                      className: 'card text-light mb-2',
                      sx: {
                        boxShadow: 'none',
                        backgroundColor: '#143983'
                      },
                    }}
                    muiBottomToolbarProps={{
                      className: 'text-light mb-5 d-flex',
                      sx: {
                        boxShadow: 'none',
                        backgroundColor: '#143983',
                        borderRadius: 'var(--bs-card-border-radius)',
                        minHeight: '50px'
                      },
                    }}
                    muiSearchTextFieldProps={{
                      className: 'bg-light text-light text-muted'
                    }}
                    // muiTableHeadCellFilterTextFieldProps={{
                    //   helperText: false,
                    //   focused: true,
                    //   inputProps: {
                    //     className: "text-black",
                    //   },
                    //   sx: {
                    //     color: 'black'
                    //   }
                    // }}
                    // muiTableHeadCellColumnActionsButtonProps={{
                    //   sx: {
                    //     color: 'black'
                    //   }
                    // }}
                    // muiTableHeadCellDragHandleProps={{
                    //   sx: {
                    //     color: 'black'
                    //   }
                    // }}
                    icons={{
                      FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                      FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                      ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                      FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                      FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                      SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                      CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                    }}
                    // muiTablePaginationProps={{
                    //   className: 'text-white',
                    // }}
                    enableGlobalFilter={false}
                    muiToolbarAlertBannerProps={{
                      className: 'text-white',
                      sx: {
                        backgroundColor: '#143983',
                      }
                    }}
                    muiToolbarAlertBannerChipProps={{
                      className: 'text-white'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='accordion accordion-borderless' id='kt_accordion_1' style={{ border: 'shadow-white' }}>
          <div
            id='UpdateTC'
            className='accordion-collapse collapse'
            aria-labelledby='kt_accordion_1_header_3'
            data-bs-parent='#kt_accordion_3'
          >
            <h3 className='text-primary'><strong><FaRegEdit /> Update Terms and Conditions for flexible work</strong></h3><br />

            <div className='accordion-item bg-transparent pb-3'>

              <div className='accordion-body text-body-secondary rounded-4 p-15 container bg-primary-subtle text-primary'>
                <p className='text-justify'>
                  <h4 style={{ color: '#34A8CB' }}><strong><FaRegEdit /> Terms and Conditions for taking remote work: </strong></h4><br />
                  <RichTextEditor
                    className="datafield p-5 d-flex flex-column"
                    onChange={(value: any) => setEditedText1(value)}
                    suppressContentEditableWarning={true}
                    value={editedText1 !==''?editedText1:termsAndConditions.filter(item => item?.FlexWorkType === "Remote Work")?.[0]?.contentText}
                  />
                  {/* <span className="datafield p-5" contentEditable="true" ref={remoteWorkRef}>
                    <strong>
                    {termsAndConditions.filter(item=>item?.FlexWorkType === "Remote Work")?.[0]?.contentText}
                    </strong>

                  </span> */}
                </p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="btn btn-sm btn-primary btn-active-light-primary" type="button" onClick={() => updateTermsAndConditions("Remote Work")}><i className="bi bi-check-circle fs-2 px-3 svg-icon-muted"></i>Save Changes</button>
                </div>
              </div>

            </div>

            <div className='accordion-item bg-transparent pb-3'>

              <div className='accordion-body text-body-secondary rounded-4 p-15 container bg-primary-subtle text-primary'>

                <p className='text-justify'>

                  <h4 style={{ color: '#34A8CB' }}><strong><FaRegEdit /> Terms and Conditions for taking half day: </strong></h4><br />

                  <RichTextEditor
                    className="datafield p-5 d-flex flex-column "
                    onChange={(value: any) => setEditedText2(value)}
                    suppressContentEditableWarning={true}
                    value={editedText2 !==''?editedText2:termsAndConditions.filter(item => item?.FlexWorkType === "Half Day")?.[0]?.contentText}
                  />
                </p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="btn btn-sm btn-primary btn-active-light-primary" type="button" onClick={() => updateTermsAndConditions("Half Day")}><i className="bi bi-check-circle fs-2 px-3 svg-icon-muted"></i>Save Changes</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EmployeeFlexWorkTracker;