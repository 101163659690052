import { useEffect, useMemo, useState } from 'react';

//MRT Imports
//import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'; //default import deprecated
import { MaterialReactTable } from 'material-react-table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

//Mock Data

import { message } from "antd";

import Cookies from 'js-cookie';

import axios from 'axios';

import useFetchCommon from '../../../hooks/getCommonData';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { KTIcon } from '../../../../_metronic/helpers';
import PopUp from '../../../components/Modal/Modal';
import AddCompany from './AddCompany';
import { Grid, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditCompany from './EditCompany';
import { ClipLoader } from 'react-spinners';

export const Employee = {
    companyName: '',
    address: '',
};

const CompanyList = () => {

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [selectedCompany,setSelectedCompany] =  useState()
    const [show ,setShow]= useState(false);
    const [actionStatus,setActionStatus] = useState(false)
    const [deleteId,setDeleteId] =  useState()
    const [loading,setLoading ]= useState(true);
    const [visible,setVisible] = useState(false);
    const [editVisible,setEditVisible] = useState(false)

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const { commonDataFetch } = useFetchCommon(`getCompanies`, {}, [shouldRefetch]);

    const tableData = commonDataFetch || [];


    const showMessage = (msg) => {
        message.destroy();
        message.success({
            content: msg,
            duration: 4 // Duration in seconds
        });
    };

    const handleDelete = async()=>{
        try {
            await axios.post(`${BASE_URL_TWO}`, {
                sp: 'delCompany',
                companyId: deleteId,
            });
            message.destroy();
            setActionStatus(false);
            message.success('Company deleted successfully.');
            setShouldRefetch(!shouldRefetch);
        } catch (error) {
            setActionStatus(false);
            message.destroy();
            message.error("Unable to delete this company");
        }
    }

    useEffect(()=>{
        if(actionStatus && deleteId){
            handleDelete()
        }
    },[actionStatus,deleteId])

    useEffect(() => {
        if (commonDataFetch) {
            setLoading(false); // Stop loading when data is fetched
        }
    }, [commonDataFetch]);

    return (
        <>
            <EditCompany visible={editVisible} setSelectedCompany={setSelectedCompany} onClose={()=>setEditVisible(false)} shouldRefetch={shouldRefetch}  selectedCompany={selectedCompany} setShouldRefetch={setShouldRefetch}/>
             <PopUp
                title="Delete Company"
                message="Are you sure you want to Delete this Company?"
                btnName="Delete"
                setActionStatus={setActionStatus}
                show={show}
                setShow={setShow}
            />

            <div className='d-flex justify-content-between align-items-center'>
                <AddCompany visible={visible} onClose={()=>setVisible(false)}  shouldRefetch={shouldRefetch} setShouldRefetch={setShouldRefetch} />
                <h5 className="text-black-50 mb-10">
                    <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Company List
                </h5>

                <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <span
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        onClick={()=>setVisible(true)}
                        >
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </span>
                </div>
            </div>

            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid container>
                    {!loading && <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                        <thead>
                            <tr className="fw-bold fs-5 bg-primary rounded text-white">
                                <th>#</th>
                                <th>Company Name</th>
                                <th>Address</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                commonDataFetch?.map((data, index) =>
                                    <tr style={{ borderBottom: "" }} key={index}>
                                        <td className="text-primary fw-bold"><span className="badge">{index + 1}</span></td>
                                        <td className="text-primary fw-bold">{data?.companyName}</td>
                                        <td className="text-primary fw-bold">{data?.companyAddress}</td>
                                        <td>
                                            <Tooltip title="Delete" arrow>
                                                <DeleteIcon
                                                    onClick={() => { setDeleteId(data?.companyId); setShow(true) }}
                                                    style={{ cursor: "pointer", color: 'rgb(18 54 125)' }}
                                                    title="Delete"
                                                />
                                            </Tooltip>
                                            <span
                                                onClick={() =>{ setSelectedCompany(data); setEditVisible(true)}} // Pass selected position
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                                title="Edit"

                                                >
                                                <Tooltip title="Edit" arrow>
                                                    <ModeEditIcon
                                                        sx={{
                                                            cursor: 'pointer',
                                                            ml: 2,
                                                        }}
                                                        className='text-primary'
                                                    />
                                                </Tooltip>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }
                            { }
                            {commonDataFetch?.length <= 0 && <tr><td className='text-center' colSpan={3}>No Records Found</td></tr>}
                        </tbody>
                    </table>}
                    {loading && <div className='text-center d-flex justify-content-center align-items-center' style={{height:"500px",width:"100%"}}><ClipLoader color='#143983' size={45}/></div>}
                </Grid>
            </Grid>

        </>
    );
};

export default CompanyList;