import React, { useEffect, useMemo, useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
//MRT Imports
//import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'; //default import deprecated
import { MaterialReactTable } from 'material-react-table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
//Material UI Imports
import { Autocomplete, Box, Grid, IconButton, TextField } from '@mui/material';

//Icons Imports
import { Delete, Edit } from '@mui/icons-material';

//Mock Data
// import { data } from './makeData';

import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import makeData from './makeData'; // Importing makeData
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Button, message } from "antd";
import EmployeeInsuranceForm from './EmployeeInsuranceForm';
import PopUp from '../Modal/Modal';
import PayRollEditForm from '../PayrollTable/PayRollEditForm';
import EditInsuranceTable from './EditInsuranceTable';
import CreateDepartment from '../../pages/SuperAdminPages/Department/CreateDepartment';
import { FadeLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';


const InsuredStaffTable = () => {

  const id = Cookies.get('userId');
  const token = Cookies.get("token")

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [rowData, setRowData] = useState({});
  const [employeName, setEmployeName] = useState('')
  const navigate = useNavigate()

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const [recievedData, setData] = useState(makeData);


  const { fetchedData, isLoading } = useGetDataWithFullUrl(`${BASE_URL}getInsuredStaffList&userId=${id}&DisplayLength=50&DisplayStart=0&SortCol=0&SortDir=asc&Search=&employeeName=${employeName}`, [shouldRefetch]);

  const [insuredStaffs, setInsuredStaffs] = useState([]);
  const fetchInsuredStaff = () => {
    axios.get(`${BASE_URL}getEmployeeDropdownForInsured&userId=${id}`, { headers }).then((response) => {
      setInsuredStaffs(response?.data?.Data)
    })
  }

  useEffect(() => {
    fetchInsuredStaff()
  }, [])

  const tableData = fetchedData || [];

  const a = (msg) => {
    message.info({
      content: msg,
      duration: 2
    });
  }

  const handleChange = (e, field) => {
    setRowData({ ...rowData, [field]: e.target.value });
  };

  const [show, setShow] = useState()
  const [actionStatus, setActionStatus] = useState()
  const [familyMemberId, setFamilyMemberId] = useState()
  const [corporateDetailId, setCorporateDetailId] = useState()

  useEffect(() => {
    if (actionStatus && familyMemberId) {
      axios.post(`${BASE_URL_TWO}`, {
        "sp": "delInsuredStaff",
        "userId": id,
        "corporateDetailId": corporateDetailId,
        "familyMemberId": familyMemberId
      }, { headers })
        .then(() => {
          showMessage("Deleted successfully");
          setShouldRefetch(prevState => !prevState)
          setActionStatus(false)
        })
        .catch(error => {
          if (error.response?.status === 401) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName, { path: '/' });
            });
            navigate('/error/login')
          } else {
            message.destroy();
            message.error("Something went wrong")
          }
        });
    }
  }, [familyMemberId, actionStatus]);

  const columns = useMemo(
    () => [
      {
        header: 'No',
        size: 100,
        enableGrouping: false,
        enablePinning: false,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
        size: 150,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        size: 150,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'policyNumber',
        header: 'Policy Number',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
        Cell: ({ row }) => {
          return editingRow === row.id ? (
            <input
              type="text"
              value={rowData.policyNumber || ''}
              onChange={(e) => handleChange(e, 'policyNumber')}
            />
          ) : (
            row.original.policyNumber || "N/A"
          );
        },
      },
      {
        accessorKey: 'gender',
        header: 'Gender',
        size: 150,
        enableGrouping: true,
        enablePinning: false,
      },
      {
        accessorKey: 'dateOfBirth',
        id: 'dob',
        header: 'Date of Birth',
        enableGrouping: false,
        enablePinning: false,
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const dateOfBirth = new Date(cell.getValue());
          const formattedDateOfBirth = dateOfBirth.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
          return formattedDateOfBirth;
        },
      },
      {
        accessorKey: 'nationality',
        header: 'Nationality',
        size: 200,
        enableGrouping: true,
        enablePinning: false,
      },
      {
        accessorKey: 'passportNumber',
        header: 'Passport Number',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'passportExpiryDate',
        header: 'Passport Expiry Date',
        enableGrouping: false,
        enablePinning: false,
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const passportExpiryDate = new Date(cell.getValue());
          const formattedPassportExpiryDate = passportExpiryDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
          return formattedPassportExpiryDate;
        },
      },
      {
        accessorKey: 'iDRCNumber',
        header: 'ID/RC Number',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'idRcExpiryDate',
        header: 'ID/RC Expiry Date',
        enableGrouping: false,
        enablePinning: false,
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          const idExpiryDate = new Date(cell.getValue());
          const formattedIdExpiryDate = idExpiryDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
          return formattedIdExpiryDate;
        },
      },
      {
        accessorKey: 'internalJobTitle',
        header: 'Internal Job Title',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
        Cell: ({ cell }) => {
          return cell.getValue() ? cell.getValue() : 'N/A';
        }
      },
      {
        accessorKey: 'FamORemployee',
        header: 'Fam/Employee',
        size: 150,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'relationship',
        header: 'Relationship',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'email',
        header: 'Email ID',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
        Cell: ({ cell }) => {
          return cell.getValue() ? cell.getValue() : 'N/A';
        }
      },
      {
        accessorKey: 'insuranceName',
        header: 'Insurance Name',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
        Cell: ({ cell }) => {
          return cell.getValue() ? cell.getValue() : 'N/A';
        }
      },
      {
        accessorKey: 'insuranceAmount',
        header: 'Insurance Amount',
        size: 200,
        enableGrouping: false,
        enablePinning: false,
        Cell: ({ cell }) => {
          return cell.getValue() ? cell.getValue() : 0;
        }
      },
    ],
    []
  );


  const showMessage = () => {
    message.success({
      content: 'Deleted insurance staff',
      duration: 1 // Duration in seconds
    });
  };

  const [insureId, setInsureId] = useState()

  // corporateDetailId

  const filterdData = fetchedData?.filter((data, index) => data?.familyMemberId == insureId)

  const selectedData = filterdData ? filterdData[0] : ''


  const exportToExcel = () => {
    // Remove 'familyMemberId' and 'employeeName' columns from the data
    const cleanedData = fetchedData.map(({
      familyMemberId,
      corporateDetailId,
      employeeName,
      firstName,
      lastName,
      policyNumber,
      gender,
      dateOfBirth,
      nationality,
      passportNumber,
      passportExpiryDate,
      iDRCNumber,
      idRcExpiryDate,
      internalJobTitle,
      FamORemployee,
      relationship,
      email,
      phoneNumber,
      insuranceName,
      insuranceAmount,
      contractExpiryDate,
      contractStatus,
    }) => ({
      'First Name': firstName,
      'Last Name': lastName,
      'Policy Number': policyNumber,
      'Gender': gender,
      "Date of birth": dateOfBirth,
      'Nationality': nationality,
      'Passport Number': passportNumber,
      'Passport Expiry Date': passportExpiryDate,
      'ID/RC Number': iDRCNumber,
      'ID/RC Expiry Date': idRcExpiryDate,
      'Internal Job Title': internalJobTitle,
      'Family Or Employee': FamORemployee,
      'Relationship': relationship,
      'Email': email,
      'Phone Number': phoneNumber,
      'Insurance Name': insuranceName,
      'Insurance Amount': insuranceAmount,
    }));

    // Convert the cleaned data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate the Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `Insured Staff.xlsx`);
  };

  return (
    <>

      <EditInsuranceTable selectedData={selectedData} />

      <PopUp
        title="Delet Employee Insurance Details"
        message="Are you sure you want to Delete"
        btnName="Delete"
        setActionStatus={setActionStatus}
        show={show}
        setShow={setShow}
      />

      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */} Employee Insurance Details
      </h5>
      {(isLoading) && <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "400px", width: "100%" }}><FadeLoader color='#0d6efd' /></div>}

      {!isLoading && <div className={`card`}>
        <div className="card-header border-0 pt-5 pb-2">
          <div className='col-md-3'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>[{tableData?.length} Staffs and Family Members]</span>
            </h3>
            <h2 className='mb-5' style={{ fontSize: "18px" }}>Enter the employee name</h2>
            <Autocomplete
              id='name'
              options={insuredStaffs}
              getOptionLabel={(option) => option?.Name}
              getOptionSelected={(option, value) => option?.employeeName === value?.employeeName}
              onChange={(event, value) => {
                if (value === null) {
                  // Perform action when selection is removed
                  console.log("Selection cleared");
                  setEmployeName('');
                } else {
                  // Handle selected value
                  setEmployeName(value?.employeeName);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  focused
                  value={employeName}
                  onChange={(event) => setEmployeName(event.target.value)}
                  label="employeeName"
                />
              )}
            />

          </div>
          <div className="card-toolbar">
            <a onClick={exportToExcel} className="btn btn-sm btn-primary">
              <KTIcon iconName="file-down" className="fs-2" />
              Export to Excel
            </a>
          </div>
        </div>
        <div className="card-body">
          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableDensityToggle={false}
            enablePinning
            enableColumnFilters
            enableColumnOrdering
            enableRowActions
            enableGrouping
            initialState={{ showColumnFilters: false, columnPinning: { left: ['name'] } }}
            positionToolbarAlertBanner="bottom"
            editingMode="row"
            enableEditing
            positionActionsColumn="last"
            renderRowActions={({ row, table }) => [
              <Box sx={{ justifyContent: 'center' }}>

                <a href="/"
                  onClick={() => setInsureId(fetchedData[row.index].familyMemberId)}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  title="New Entry"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_3">
                  <IconButton
                  >
                    <Edit className='text-success' />
                  </IconButton>
                </a>

                <IconButton

                  onClick={() => {
                    setShow(true)
                    setFamilyMemberId(fetchedData[row.index]?.familyMemberId)
                    setCorporateDetailId(fetchedData[row.index]?.corporateDetailId)
                  }}
                >
                  <Delete className="text-danger" />
                </IconButton>
              </Box>,
            ]}
            filterFns={{
              dateFilter: (row, columnId, filterValue) => {
                const filterArray = filterValue.replaceAll(',', ';').split(';');
                const cellVal = new Date(row.original[columnId]);
                const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec'];
                for (let item of filterArray) {
                  const itemArray = item.replaceAll(/\s+/g, '#').split('#');
                  if (cellVal.getFullYear().toString() === itemArray[itemArray.length - 1]) {
                    for (let i = 0; i < itemArray.length - 1; i++) {
                      if (itemArray[i].includes(monthName[cellVal.getMonth()])) return true;
                    }
                  }
                }
                return false;
              },
              multipleFilter: (row, columnId, filterValue) => {
                const filterArray = filterValue.replaceAll(',', ';').split(';');
                const cellVal = row.original[columnId];
                for (let item of filterArray) {
                  if (cellVal.toLowerCase().includes(item.toLowerCase())) return true;
                }
                return false;
              },
            }}
            muiTablePaperProps={{
              sx: {
                backgroundColor: 'var(--bs-card-bg)',
                border: 'none',
                boxShadow: 'none',
              },
            }}
            muiTableProps={{
              className: 'table',
              sx: {
                borderCollapse: 'separate',
                borderSpacing: '0px 2px',
              },
            }}
            muiTableContainerProps={{
              className: 'mb-2',
              sx: {
                backgroundColor: 'var(--bs-light)',
                borderRadius: 'var(--bs-card-border-radius)',
              },
            }}
            muiTableHeadRowProps={{
              className: 'fw-bold bg-light',
              sx: {
                boxShadow: 'none',
              },
            }}
            muiTableHeadCellProps={{
              className: 'px-7 py-4 text-black',
              sx: {
                verticalAlign: 'middle',
                backgroundColor: '#E6BBB2 !important',
                outline: '10px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            }}
            muiTableBodyRowProps={{
              hover: false,
              sx: {
                backgroundColor: 'transparent',
              },
            }}
            muiTableBodyCellProps={{
              className: 'bg-light bg-hover-secondary text-center',
            }}
            muiTopToolbarProps={{
              className: 'card text-light mb-2',
              sx: {
                boxShadow: 'none',
                backgroundColor: '#143983',
              },
            }}
            muiBottomToolbarProps={{
              className: 'text-light mb-5 d-flex',
              sx: {
                boxShadow: 'none',
                backgroundColor: '#143983',
                borderRadius: 'var(--bs-card-border-radius)',
                minHeight: '50px',
              },
            }}
            muiSearchTextFieldProps={{
              className: 'bg-light text-light text-muted',
            }}
            muiTableHeadCellFilterTextFieldProps={{
              helperText: false,
              focused: true,
              inputProps: {
                className: 'text-black',
              },
              sx: {
                color: 'black',
              },
            }}
            muiTableHeadCellColumnActionsButtonProps={{
              sx: {
                color: 'black',
              },
            }}
            muiTableHeadCellDragHandleProps={{
              sx: {
                color: 'black',
              },
            }}
            icons={{
              FullscreenIcon: () => <KTIcon iconName="maximize" className="fs-2 text-white" />,
              FullscreenExitIcon: () => <KTIcon iconName="cross-circle" className="fs-2 text-white" />,
              ViewColumnIcon: () => <KTIcon iconName="status" className="fs-2 text-white" />,
              FilterListIcon: () => <KTIcon iconName="filter-search" className="fs-2 text-white" />,
              FilterListOffIcon: () => <KTIcon iconName="filter-search" className="fs-2 text-white" />,
              SearchIcon: () => <KTIcon iconName="search-list" className="fs-2 text-white" />,
              CloseIcon: () => <KTIcon iconName="cross" className="fs-2 text-black" />,
            }}
            muiTablePaginationProps={{
              className: 'text-white',
            }}
            enableGlobalFilter={false}
            muiToolbarAlertBannerProps={{
              className: 'text-white',
              sx: {
                backgroundColor: '#143983',
              },
            }}
            muiToolbarAlertBannerChipProps={{
              className: 'text-white',
            }}
          />
        </div>
      </div >}
    </>
  );
};

export default InsuredStaffTable;

