/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { ErrorMessage, Field } from 'formik'
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { IProfileDetails, profileDetailsInitValues as initialValues } from '../../../../../modules/accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { IconButton } from '@mui/material'
import axios from 'axios';
// import * as Yup from 'yup';
import { Grid } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { BASE_URL, BASE_URL_TWO } from '../../../../../../utils'
import Cookies from 'js-cookie'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const id = Cookies.get('userId');
const token = Cookies.get("token");

const headers = {
  'token': token,
  'Content-Type': 'application/json'
};

const validatePhoneNumber = async (phoneNumber) => {
  if (!phoneNumber) return true; // No validation if the field is empty

  try {
    const response = await axios.get(`${BASE_URL}spCheckPhoneExists&userId=${id}&phone=${phoneNumber}&employeeId=`, { headers });
    // If the response indicates the phone number is taken, return a custom error message
    if (response.data.Message === 'Success') {
      return true;
    }
    return false;
  } catch (error) {
    return false; // Return false if there is an error (e.g., network issues)
  }
};

const validateOmanNumber = async (phoneNumber) => {
  if (!phoneNumber) return true; // No validation if the field is empty

  try {
    const response = await axios.get(`${BASE_URL}spCheckOmanPhoneExists&userId=${id}&phone=${phoneNumber}&employeeId=`, { headers });
    // If the response indicates the phone number is taken, return a custom error message
    if (response.data.Message === 'Success') {
      return true;
    }
    return false;
  } catch (error) {
    return false; // Return false if there is an error (e.g., network issues)
  }
};

const validationSchema = Yup.object().shape({

  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('LastName name required'),
  bankName: Yup.string().required('Bank name required'),
  accountNumber: Yup.string().required('AccountNumber required'),
  address: Yup.string().required('Address is required'),
  nationality: Yup.string().required('Country name required'),
  gender: Yup.string().required('Gender is required'),
  meritalStatus: Yup.string().required('Maritalstatus is required'),
  idRcNumber: Yup.string().required('ID/RC Number is required'),
  age: Yup.string(),
  passportNumber: Yup.string().required('PassportNumber is required'),
  idRcIssueDate: Yup.date().required('ID/RC issue date is required'),
  passportIssueDate: Yup.date().required('Passport issue date is required'),
  idRcExpDate: Yup.date().required('ID/RC exp date is required'),
  internationalNumber: Yup.string()
    .test(
      'check-duplicate-phone-number',
      'Phone number is already in use.',
      async (value) => await validatePhoneNumber(value)
    ),
  passportExpDate: Yup.date().required('Passport exp date is required'),
  dob: Yup.date()
    .max(new Date(), 'Date of birth cannot be in the future')
    .required('Date of birth is required'),
  contactNumber: Yup.string()
    .required("Mob is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(8, 'Enter valid mobile ').max(8, 'Enter valid mobile').test(
      'check-duplicate-phone-number',
      'Phone number is already in use.',
      async (value) => await validateOmanNumber(value)
    ),
  emergencyNumber: Yup.string().required('Emergrny number required').matches(/^[0-9]+$/, "Must be only digits").min(8, 'Enter valid mobile ').max(10, 'Enter valid mobile'),
})

const Step1 = (props) => {

  const [internationalPhone, setInternationalPhone] = useState();

  const { personalDetails, setPersonalDetails, setSteps } = props

  const [meritalStatus, setMritalStatus] = useState('');
  const [nationality, setNationality] = useState('');
  const [gender, setGender] = useState('')
  const [Insurance, setInsurance] = useState('');
  const [age, setAge] = useState('');

  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false)
  const personalData = JSON.parse(localStorage.getItem('personalDetails'))

  const [fileBase64, setFileBase64] = useState();
  const [image, setImage] = useState(personalData?.image ? personalData?.image : null)

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const fileSizeInKB = file.size / 1024; // convert bytes to kilobytes

      if (fileSizeInKB > 500) {
        alert("File size exceeds 500KB");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setFileBase64(reader.result);
        formik.setFieldValue('image', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const initialValues = {
    image: personalData?.image ? personalData?.image : '',
    firstName: personalData?.firstName ? personalData?.firstName : '',
    lastName: personalData?.lastName ? personalData?.lastName : '',
    nationality: personalData?.nationality ? personalData?.nationality : '',
    gender: personalData?.gender ? personalData?.gender : '',
    insurance: personalData?.insurance ? personalData?.insurance : '',
    meritalStatus: personalData?.meritalStatus ? personalData?.meritalStatus : '',
    bankName: personalData?.bankName ? personalData?.bankName : '',
    accountNumber: personalData?.accountNumber ? personalData?.accountNumber : '',
    address: personalData?.address ? personalData?.address : '',
    famOrEmp: "employee",
    idRcNumber: personalData?.idRcNumber ? personalData?.idRcNumber : '',
    passportNumber: personalData?.passportNumber ? personalData?.passportNumber : '',
    idRcIssueDate: personalData?.idRcIssueDate ? personalData?.idRcIssueDate : '',
    passportIssueDate: personalData?.passportIssueDate ? personalData?.passportIssueDate : '',
    idRcExpDate: personalData?.idRcExpDate ? personalData?.idRcExpDate : '',
    age: personalData?.age ? personalData?.age : '',
    passportExpDate: personalData?.passportExpDate ? personalData?.passportExpDate : '',
    dob: personalData?.dob ? personalData?.dob : '',
    internationalNumber: personalData?.internationalNumber ? personalData?.internationalNumber : '',
    contactNumber: personalData?.contactNumber ? personalData?.contactNumber : '',
    emergencyNumber: personalData?.emergencyNumber ? personalData?.emergencyNumber : '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      localStorage.setItem('personalDetails', JSON.stringify({ ...values, image: fileBase64 }))
      setPersonalDetails({ ...values})
      setSteps("step2")
    },
    enableReinitialize: true
  });

  useEffect(() => {
    setInternationalPhone(formik.values.internationalNumber)
    setFileBase64(formik.values.image)
    setAge(formik.values.age)
  }, [personalDetails])

  useEffect(() => {
    formik.setFieldValue('internationalNumber', internationalPhone);
  }, [internationalPhone]);

  useEffect(() => {
    formik.setFieldValue('age', age);
  }, [age])



  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  const currentDate = `${year}-${month}-${day}`;

  const calculateAge = (dob) => {

    setAge(new Date(currentDate).getFullYear() - new Date(dob).getFullYear());
  }


  return (
    <div className='w-100'>

      <div className='pb-10 pb-lg-15'>
        <h4 className='fw-bolder text-danger'>Employee Personal Details</h4>
      </div>

      <form action="" onSubmit={formik.handleSubmit}>

        <div className='row mb-5'>
          <div className='col-lg-40' >
            <span>
              <div
                className='image-input image-input-outline col-lg-2'
                data-kt-image-input='true'
              >
                <img
                  className='image-input-wrapper w-90px h-90px'
                  src={image ? image : formik.values.image ? `${BASE_URL_TWO}${formik.values.image}` : `/media/avatars/blank.png`}
                  id="blah"
                />
                <label
                  style={{ color: ' #0000FF' }}
                  className="fw-bold fs-8 cursor-pointer"
                  htmlFor="image">Select photo!</label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    handleFileChange(event);
                    formik.setFieldValue('image', event.currentTarget.files[0]);
                  }}

                >
                </input>
              </div>
            </span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>First Name <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary required'
              placeholder=''
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.firstName}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Last Name <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary required'
              placeholder=''
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.lastName}</span>
          </div>
        </div>


        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Date Of Birth <span className='text-danger'>*</span></label>

          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='dob'
              value={formik.values.dob}
              onChange={(e) => {
                calculateAge(e.target.value)
                formik.handleChange(e)
              }}
              onBlur={formik.handleBlur}
              max={`${currentYear}-01-01`}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.dob}</span>
          </div>

          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Age<span className='text-danger'>*</span></label>

          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='age'
              value={formik.values.age || age}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.age}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Nationality <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select
              name="nationality"
              onChange={(e) => {
                setNationality(e.target.value)
                formik.handleChange(e);
              }}
              value={formik.values?.nationality || nationality}
              className='form-select form-select-solid border fs-7 bg-body-secondary'
            >
              <option value=''>Select nationality</option>
              <option value='Afghanistan'>Afghanistan</option>
              <option value='Aland Islands'>Aland Islands</option>
              <option value='Albania'>Albania</option>
              <option value='Algeria'>Algeria</option>
              <option value='American Samoa'>American Samoa</option>
              <option value='Andorra'>Andorra</option>
              <option value='Angola'>Angola</option>
              <option value='Anguilla'>Anguilla</option>
              <option value='Antarctica'>Antarctica</option>
              <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
              <option value='Argentina'>Argentina</option>
              <option value='Armenia'>Armenia</option>
              <option value='Aruba'>Aruba</option>
              <option value='Australia'>Australia</option>
              <option value='Austria'>Austria</option>
              <option value='Azerbaijan'>Azerbaijan</option>
              <option value='Bahamas'>Bahamas</option>
              <option value='Bahrain'>Bahrain</option>
              <option value='Bangladesh'>Bangladesh</option>
              <option value='Barbados'>Barbados</option>
              <option value='Belarus'>Belarus</option>
              <option value='Belgium'>Belgium</option>
              <option value='Belize'>Belize</option>
              <option value='Benin'>Benin</option>
              <option value='Bermuda'>Bermuda</option>
              <option value='Bhutan'>Bhutan</option>
              <option value='Bolivia, Plurinational State of'>Bolivia, Plurinational State of</option>
              <option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</option>
              <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
              <option value='Botswana'>Botswana</option>
              <option value='Bouvet Island'>Bouvet Island</option>
              <option value='Brazil'>Brazil</option>
              <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
              <option value='Brunei Darussalam'>Brunei Darussalam</option>
              <option value='Bulgaria'>Bulgaria</option>
              <option value='Burkina Faso'>Burkina Faso</option>
              <option value='Burundi'>Burundi</option>
              <option value='Cambodia'>Cambodia</option>
              <option value='Cameroon'>Cameroon</option>
              <option value='Canada'>Canada</option>
              <option value='Cape Verde'>Cape Verde</option>
              <option value='Cayman Islands'>Cayman Islands</option>
              <option value='Central African Republic'>Central African Republic</option>
              <option value='Chad'>Chad</option>
              <option value='Chile'>Chile</option>
              <option value='China'>China</option>
              <option value='Christmas Island'>Christmas Island</option>
              <option value='Cocos (Keeling) Islands'>Cocos (Keeling) Islands</option>
              <option value='Colombia'>Colombia</option>
              <option value='Comoros'>Comoros</option>
              <option value='Congo'>Congo</option>
              <option value='Congo, the Democratic Republic of the'>Congo, the Democratic Republic of the</option>
              <option value='Cook Islands'>Cook Islands</option>
              <option value='Costa Rica'>Costa Rica</option>
              <option value='Croatia'>Croatia</option>
              <option value='Cuba'>Cuba</option>
              <option value='Curaçao'>Curaçao</option>
              <option value='Cyprus'>Cyprus</option>
              <option value='Czech Republic'>Czech Republic</option>
              <option value='Denmark'>Denmark</option>
              <option value='Djibouti'>Djibouti</option>
              <option value='Dominica'>Dominica</option>
              <option value='Dominican Republic'>Dominican Republic</option>
              <option value='Ecuador'>Ecuador</option>
              <option value='Egypt'>Egypt</option>
              <option value='El Salvador'>El Salvador</option>
              <option value='Equatorial Guinea'>Equatorial Guinea</option>
              <option value='Eritrea'>Eritrea</option>
              <option value='Estonia'>Estonia</option>
              <option value='Ethiopia'>Ethiopia</option>
              <option value='Falkland Islands (Malvinas)'>Falkland Islands (Malvinas)</option>
              <option value='Faroe Islands'>Faroe Islands</option>
              <option value='Fiji'>Fiji</option>
              <option value='Finland'>Finland</option>
              <option value='France'>France</option>
              <option value='French Guiana'>French Guiana</option>
              <option value='French Polynesia'>French Polynesia</option>
              <option value='French Southern Territories'>French Southern Territories</option>
              <option value='Gabon'>Gabon</option>
              <option value='Gambia'>Gambia</option>
              <option value='Georgia'>Georgia</option>
              <option value='Germany'>Germany</option>
              <option value='Ghana'>Ghana</option>
              <option value='Gibraltar'>Gibraltar</option>
              <option value='Greece'>Greece</option>
              <option value='Greenland'>Greenland</option>
              <option value='Grenada'>Grenada</option>
              <option value='Guadeloupe'>Guadeloupe</option>
              <option value='Guam'>Guam</option>
              <option value='Guatemala'>Guatemala</option>
              <option value='Guernsey'>Guernsey</option>
              <option value='Guinea'>Guinea</option>
              <option value='Guinea-Bissau'>Guinea-Bissau</option>
              <option value='Guyana'>Guyana</option>
              <option value='Haiti'>Haiti</option>
              <option value='Heard Island and McDonald Islands'>Heard Island and McDonald Islands</option>
              <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
              <option value='Honduras'>Honduras</option>
              <option value='Hong Kong'>Hong Kong</option>
              <option value='Hungary'>Hungary</option>
              <option value='Iceland'>Iceland</option>
              <option value='India'>India</option>
              <option value='Indonesia'>Indonesia</option>
              <option value='Iran, Islamic Republic of'>Iran, Islamic Republic of</option>
              <option value='Iraq'>Iraq</option>
              <option value='Ireland'>Ireland</option>
              <option value='Isle of Man'>Isle of Man</option>
              <option value='Israel'>Israel</option>
              <option value='Italy'>Italy</option>
              <option value='Jamaica'>Jamaica</option>
              <option value='Japan'>Japan</option>
              <option value='Jersey'>Jersey</option>
              <option value='Jordan'>Jordan</option>
              <option value='Kazakhstan'>Kazakhstan</option>
              <option value='Kenya'>Kenya</option>
              <option value='Kiribati'>Kiribati</option>
              <option value='Korea'>Korea</option>
              <option value='Kuwait'>Kuwait</option>
              <option value='Kyrgyzstan'>Kyrgyzstan</option>
              <option value='Lao People'>Lao People's Democratic Republic</option>
              <option value='Latvia'>Latvia</option>
              <option value='Lebanon'>Lebanon</option>
              <option value='Lesotho'>Lesotho</option>
              <option value='Liberia'>Liberia</option>
              <option value='Libya'>Libya</option>
              <option value='Liechtenstein'>Liechtenstein</option>
              `<option value='Lithuania'>Lithuania</option>
              <option value='Luxembourg'>Luxembourg</option>
              <option value='Macao'>Macao</option>
              <option value='Macedonia, the former Yugoslav Republic of'>Macedonia, the former Yugoslav Republic of</option>
              <option value='Madagascar'>Madagascar</option>
              <option value='Malawi'>Malawi</option>
              <option value='Malaysia'>Malaysia</option>
              <option value='Maldives'>Maldives</option>
              <option value='Mali'>Mali</option>
              <option value='Malta'>Malta</option>
              <option value='Marshall Islands'>Marshall Islands</option>
              <option value='Martinique'>Martinique</option>
              <option value='Mauritania'>Mauritania</option>
              <option value='Mauritius'>Mauritius</option>
              <option value='Mayotte'>Mayotte</option>
              <option value='Mexico'>Mexico</option>
              <option value='Micronesia, Federated States of'>Micronesia, Federated States of</option>
              <option value='Moldova, Republic of'>Moldova, Republic of</option>
              <option value='Monaco'>Monaco</option>
              <option value='Mongolia'>Mongolia</option>
              <option value='Montenegro'>Montenegro</option>
              <option value='Montserrat'>Montserrat</option>
              <option value='Morocco'>Morocco</option>
              <option value='Mozambique'>Mozambique</option>
              <option value='Myanmar'>Myanmar</option>
              <option value='Namibia'>Namibia</option>
              <option value='Nauru'>Nauru</option>
              <option value='Nepal'>Nepal</option>
              <option value='Netherlands'>Netherlands</option>
              <option value='New Caledonia'>New Caledonia</option>
              <option value='New Zealand'>New Zealand</option>
              <option value='Nicaragua'>Nicaragua</option>
              <option value='Niger'>Niger</option>
              <option value='Nigeria'>Nigeria</option>
              <option value='Niue'>Niue</option>
              <option value='Norfolk Island'>Norfolk Island</option>
              <option value='Northern Mariana Islands'>Northern Mariana Islands</option>
              <option value='Norway'>Norway</option>
              <option value='Oman'>Oman</option>
              <option value='Pakistan'>Pakistan</option>
              <option value='Palau'>Palau</option>
              <option value='Palestinian Territory, Occupied'>Palestinian Territory, Occupied</option>
              <option value='Panama'>Panama</option>
              <option value='Papua New Guinea'>Papua New Guinea</option>
              <option value='Paraguay'>Paraguay</option>
              <option value='Peru'>Peru</option>
              <option value='Philippines'>Philippines</option>
              <option value='Pitcairn'>Pitcairn</option>
              <option value='Poland'>Poland</option>
              <option value='Portugal'>Portugal</option>
              <option value='Puerto Rico'>Puerto Rico</option>
              <option value='Qatar'>Qatar</option>
              <option value='Réunion'>Réunion</option>
              <option value='Romania'>Romania</option>
              <option value='Russian Federation'>Russian Federation</option>
              <option value='Rwanda'>Rwanda</option>
              <option value='Saint Barthélemy'>Saint Barthélemy</option>
              <option value='Saint Helena, Ascension and Tristan da Cunha'>Saint Helena, Ascension and Tristan da Cunha</option>
              <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
              <option value='Saint Lucia'>Saint Lucia</option>
              <option value='Saint Martin (French part)'>Saint Martin (French part)</option>
              <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
              <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
              <option value='Samoa'>Samoa</option>
              <option value='San Marino'>San Marino</option>
              <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
              <option value='Saudi Arabia'>Saudi Arabia</option>
              <option value='Senegal'>Senegal</option>
              <option value='Serbia'>Serbia</option>
              <option value='Seychelles'>Seychelles</option>
              <option value='Sierra Leone'>Sierra Leone</option>
              <option value='Singapore'>Singapore</option>
              <option value='Sint Maarten (Dutch part)'>Sint Maarten (Dutch part)</option>
              <option value='Slovakia'>Slovakia</option>
              <option value='Slovenia'>Slovenia</option>
              <option value='Solomon Islands'>Solomon Islands</option>
              <option value='Somalia'>Somalia</option>
              <option value='South Africa'>South Africa</option>
              <option value='South Georgia and the South Sandwich Islands'>South Georgia and the South Sandwich Islands</option>
              <option value='South Korea'>South Korea</option>
              <option value='South Sudan'>South Sudan</option>
              <option value='Spain'>Spain</option>
              <option value='Sri Lanka'>Sri Lanka</option>
              <option value='Sudan'>Sudan</option>
              <option value='Suriname'>Suriname</option>
              <option value='Svalbard and Jan Mayen'>Svalbard and Jan Mayen</option>
              <option value='Swaziland'>Swaziland</option>
              <option value='Sweden'>Sweden</option>
              <option value='Switzerland'>Switzerland</option>
              <option value='Syrian Arab Republic'>Syrian Arab Republic</option>
              <option value='Taiwan, Province of China'>Taiwan, Province of China</option>
              <option value='Tajikistan'>Tajikistan</option>
              <option value='Tanzania, United Republic of'>Tanzania, United Republic of</option>
              <option value='Thailand'>Thailand</option>
              <option value='Timor-Leste'>Timor-Leste</option>
              <option value='Togo'>Togo</option>
              <option value='Tokelau'>Tokelau</option>
              <option value='Tonga'>Tonga</option>
              <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
              <option value='Tunisia'>Tunisia</option>
              <option value='Turkey'>Turkey</option>
              <option value='Turkmenistan'>Turkmenistan</option>
              <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
              <option value='Tuvalu'>Tuvalu</option>
              <option value='Uganda'>Uganda</option>
              <option value='Ukraine'>Ukraine</option>
              <option value='United Arab Emirates'>United Arab Emirates</option>
              <option value='United Kingdom'>United Kingdom</option>
              <option value='United States'>United States</option>
              <option value='Uruguay'>Uruguay</option>
              <option value='Uzbekistan'>Uzbekistan</option>
              <option value='Vanuatu'>Vanuatu</option>
              <option value='Venezuela, Bolivarian Republic of'>Venezuela, Bolivarian Republic of</option>
              <option value='Vietnam'>Vietnam</option>
              <option value='Virgin Islands'>Virgin Islands</option>
              <option value='Wallis and Futuna'>Wallis and Futuna</option>
              <option value='Western Sahara'>Western Sahara</option>
              <option value='Yemen'>Yemen</option>
              <option value='Zambia'>Zambia</option>
              <option value='Zimbabwe'>Zimbabwe</option>
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.nationality}</span>

          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Gender<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select name="gender" onChange={(e) => {
              setGender(e.target.value)
              formik.handleChange(e);
            }} value={formik.values?.gender || gender} className="form-select form-select-solid border fs-7 bg-body-secondary" aria-label="Select example" >
              <option value=''>select an option</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.gender}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Marital Status<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <select name="meritalStatus" onChange={(e) => {
              setMritalStatus(e.target.value)
              formik.handleChange(e);
            }} value={formik.values.meritalStatus || meritalStatus} className="form-select form-select-solid border fs-7 bg-body-secondary" aria-label="Select example">
              <option value=''>select an option</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.meritalStatus}</span>

          </div>

          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Insurance </label>
          <div className='col-lg-3 fv-row'>
            <select name="insurance" onChange={(e) => {
              setInsurance(e.target.value)
              formik.handleChange(e);
            }} value={formik.values?.insurance || Insurance} className="form-select form-select-solid border fs-7 bg-body-secondary" aria-label="Select example" >
              <option value=''>select an option</option>
              <option value="insured">Insured</option>
              <option value="not insured">Not insured</option>
            </select>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.insurance}</span>
          </div>
        </div>

        <div className='row mb-1 '>

          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Fam/Employee<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
          <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary required'
              placeholder=''
              id="famOrEmp"
              name="famOrEmp"
              value="employee"
              disabled={true}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.famOrEmp}</span>
          </div>
          <label className='col-lg-1'></label>

          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Bank Name <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary required'
              placeholder=''
              id="bankName"
              name="bankName"
              value={formik.values.bankName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.bankName}</span>
          </div>
        </div>

        <div className='row mb-1'>


          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Bank account number<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary required'
              placeholder=''
              id="accountNumber"
              name="accountNumber"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.accountNumber}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Passport Number<span className='text-danger'>*</span></label>

          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
              placeholder=''
              autoComplete='off'
              name='passportNumber'
              value={formik.values.passportNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.passportNumber}</span>

          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Passport Issue Date <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
              placeholder=''
              name='passportIssueDate'
              value={formik.values.passportIssueDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.passportIssueDate}</span>

          </div>
          <label className='col-lg-1'></label>

          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Passport Exp Date <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
              placeholder=''
              name='passportExpDate'
              value={formik.values.passportExpDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              min={new Date().toLocaleDateString('en-CA')}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.passportExpDate}</span>

          </div>

        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>ID/RC Number <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='idRcNumber'
              autoComplete='off'
              value={formik.values.idRcNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.idRcNumber}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis whitespace-nowrap'>ID/RC Issue Date<span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
              placeholder=''
              name='idRcIssueDate'
              value={formik.values.idRcIssueDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.idRcIssueDate}</span>
          </div>
        </div>
        <div className='row mb-1'>

          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis '>ID/RC Exp Date <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
              placeholder=''
              name='idRcExpDate'
              value={formik.values.idRcExpDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              min={new Date().toLocaleDateString('en-CA')}
              onKeyDown={(e) => e.preventDefault()}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.idRcExpDate}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>International Number</label>

          <div className='col-lg-3 fv-row'>
            <PhoneInput
              value={formik.values.internationalNumber || internationalPhone}
              country={"om"}
              containerClass='col-lg-3'
              buttonClass="border-none"
              inputClass="form-control form-control-lg  fs-7 outline-none border-none bg-body-secondary"
              inputStyle={{ width: "100%", display: 'inline-block', border: "none" }}
              buttonStyle={{
                border: "none", // Remove border from flag button
                outline: "none", // Remove outline
                boxShadow: "none", // Remove shadow if necessary
              }}
              inputProps={{
                name: 'internationalNumber',
                required: true,
              }}
              onChange={(value, country) => {
                const countryCode = country.dialCode;
                const phoneNumber = value.replace(countryCode, '').trim();
                setInternationalPhone(`${countryCode}-${phoneNumber}`);
                formik.handleChange('internationalNumber', `${countryCode}+'-'+${phoneNumber}`)
              }}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.internationalNumber}</span>
          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Contact Number +968 <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='contactNumber'
              value={formik.values.contactNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.contactNumber}</span>
          </div>
          <label className='col-lg-1'></label>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Emergency Number <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'
              placeholder=''
              name='emergencyNumber'
              value={formik.values.emergencyNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.emergencyNumber}</span>

          </div>
        </div>

        <div className='row mb-1'>
          <label className='col-lg-2 col-form-label fw-bold fs-7 text-light-emphasis'>Home address <span className='text-danger'>*</span></label>
          <div className='col-lg-3 fv-row'>
            <textarea
              type='text'
              className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary required'
              placeholder=''
              id="address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            <span style={{ fontSize: '12px', color: 'red' }}>{formik.errors.address}</span>
          </div>
          <label className='col-lg-1'></label>
        </div>

        <Grid container justifyContent="end">
          <button type='submit' className='btn btn-lg btn-primary mt-10 my-5'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className='indicator-label'>
                Continue
              </span>
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0 mt-1' />
            </div>
          </button>
        </Grid>

      </form>

    </div>
  )
}

export { Step1 }
