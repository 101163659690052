import {useEffect} from 'react'
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
//import {AuthInit} from '../../app/modules/auth'
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import Cookies from 'js-cookie'
import axios from 'axios'
import { BASE_URL } from '../../utils'
import { useAuth } from '../../app/modules/auth'

const MasterLayout = () => {
  const {logout} = useAuth()
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  let isValid = false;
  const token = Cookies.get('token');
  const id = Cookies.get('userId');
  const checkToken = async()=>{
    const response = await axios.get(`${BASE_URL}getTokenValidity&userId=${id}&token=${token}`)
    isValid = response?.data?.Data?.[0]?.message === "valid" ? true :false;
    if(!isValid){
      logout()
    }
  }
  useEffect(()=>{
    checkToken();
  },[location.pathname])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  <Content>
                    {!isValid ? <Outlet /> :<Navigate to="/error/login"/>}
                  </Content>
                </div>
                {/* <FooterWrapper /> */}
              </div>
            </div>
          </div>
        </div>
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
