import { useEffect, useState } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { Card4 } from '../../../../_metronic/partials/content/cards/Card4';
import CompneyDocUpload from './CompneyDocUpload';
import axios from 'axios';
import { GET_TMTD_DOCUMENTS } from '../../../../apis';
import { useFolderPathContext } from '../../../hooks/findPathContext';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';
import { BASE_URL_TWO } from '../../../../utils';
import Cookies from 'js-cookie';

const CompanyDocs = ({ sp, type }) => {
    const [folderData, setFolderData] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const { folderPath, setFolderPath } = useFolderPathContext();
    const id = Cookies.get("userId")

    useEffect(() => {
        axios.get(`${GET_TMTD_DOCUMENTS}&userId=${id}`).then((response) => {
            if (response.data && response.data.Data) {
                setFolderPath([response.data.Data]);
                setFolderData(response.data.Data);
            }
        });
    }, [setFolderPath]);

    const handleFolder = (clickedFolder) => {
        const newFolderPath = [...folderPath, clickedFolder];
        setFolderPath(newFolderPath);
        setFolderData(clickedFolder);
        setSearchQuery("")
    };

    const navigateBack = () => {
        const newFolderPath = folderPath.slice(0, -1);
        setFolderPath(newFolderPath);
        setFolderData(newFolderPath[newFolderPath.length - 1] || {});
        setSearchQuery('')
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredFolders = folderData?.Folders?.filter((folder) =>
        folder.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredFiles = folderData?.Files?.filter((file) =>
        file?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const currentPath = folderPath.reduce((acc, folder) => `${acc}/${folder.Name}`, '').slice(1);

    return (
        <>
            <CompneyDocUpload saveEmployeesFolderForEmployer="saveTDTMDocuments" paths={currentPath} />

            <div className='d-flex flex-wrap flex-stack mb-6'>
                {folderData.Level > 1 && (
                    <div>
                        <button className='btn btn-primary btn-sm' onClick={navigateBack}>Back</button>
                    </div>
                )}

                <div className='d-flex my-2'>
                    <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                            type='text'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder='Search'
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </div>

                    <a
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_2"
                        className='btn btn-primary btn-sm'
                    >
                        Upload
                    </a>
                </div>
            </div>

            <div style={{ width: '100%' }}></div>

            {(filteredFolders?.length <= 0 && filteredFiles?.length <= 0) && <NoFiles />}

            <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                {filteredFolders?.map((folder, index) => (
                    <div className='col-12 col-sm-12 col-xl' key={index} onClick={() => handleFolder(folder)}>
                        <Card4
                            icon='/media/svg/files/download.svg'
                            title={folder?.Name}
                            description={`${folder?.Files?.length + folder?.Folders?.length} Files`}
                        />
                    </div>
                ))}
            </div>

            {filteredFiles?.length > 0 && <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                {filteredFiles?.map((file, index) => (
                    <div key={index} className='col-12 col-sm-12 col-xl'>
                        <Card4
                            icon='/media/svg/files/pdf.svg'
                            title={file.split('-')?.[0]}
                            link={`${BASE_URL_TWO}uploads/TDTMDocuments/${currentPath}/${file}`}
                            description={file.FileType}
                        />
                    </div>
                ))}
            </div>}
        </>
    );
};

export { CompanyDocs };
