import { Typography } from "@mui/material"

type Props = {
    className: string
    color: string
    img: string
    title: string
    description: string
    url?: string
    availablecount?: number
    totalcount?:number
    count?:number
    onClick?: (() => void),
}

const CardsWidget44 = ({ className, color, img, title, description, url, onClick ,count}: Props) => (

    <a href={url}
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end py-4 ${className}`}
        style={{
            overflow: 'hidden',
            backgroundColor: color,
            backgroundImage: `url('${img}')`,
            backgroundSize: 'cover',
            cursor: 'pointer', width: '90%',
            margin: '0 auto'
        }}
        onClick={onClick}
    >
        <div style={{ width: '100%' }} className='card-header pt-5'>

            <div className='card-title d-flex flex-column'>
                <span
                    style={{ fontSize: '3rem' }}
                    className=' fw-bold text-white me-2 lh-1 '
                >{count}</span>
                <span
                    className='text-white pt-5 fw-semibold '
                    style={{fontSize:"12px"}}
                >{title}</span>
            </div>
        </div>

    </a>
)
export { CardsWidget44 }


