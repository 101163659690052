/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { defaultAlerts, defaultLogs, KTIcon, toAbsoluteUrl } from '../../../helpers'
import useFetchData from '../../../../app/hooks/getData'
import axios from 'axios'
import Cookies from "js-cookie";
import { BASE_URL, BASE_URL_TWO } from "../../../../utils";
import { getUserDetails } from '../../../../app/modules/auth/core/_requests'

const HeaderNotificationsMenu = () => {

  const id = Cookies.get('userId');
  const token = Cookies.get("token");
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  const [fetchedData, setFetchedData] = useState()
  const navigate = useNavigate()
  
  const checkAdmin = async()=>{
    const res = await getUserDetails(token,id);
     return res?.data?.Data[0];
  }
 

  useEffect(() => {
    const checkAdmin = async()=>{
      const res = await getUserDetails(token,id);
      axios.get(`${BASE_URL + "getEmployeeNotifications"}&userId=${id}${res?.data?.Data[0]?.roleName==="Employer"?`&isEmployer=1`:''}`, { headers }).then(response => {
        setFetchedData(response.data.Data)
      }).catch((error)=>{
          if (error.response?.status === 401) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName, { path: '/' });
            });
          }
          navigate('/error/login')
      })
    }
  checkAdmin()
    
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px z-50'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'>{fetchedData?.length} Alerts</span>
        </h3>

      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px mt-5 '>
            {
              fetchedData?.length === 0 ?
                <h5 className='text-black fw-bold px-9 mt-10 mb-6'>
                  No Notifications
                </h5>
                :
                fetchedData?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))?.map((log, index) => (
                 <div className="w-100 pt-3  border-bottom border-3 border-gray rounded cursor-pointer" key={index} onClick={()=>navigate(`${log?.pageUrl}`)}>
                  <div className="card-body px-4 ">
                    <h5 className="card-title" style={{color:`${log?.notificationType}`}} >{log?.notificationTitle}</h5>
                    <p className="card-text">{log?.notificationText}</p>
                    <p className="card-text mb-2" ><small className="text-muted">{log?.createdAt}</small></p>
                  </div>
                </div>
                ))
            }
          </div>
        </div>

        <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {defaultLogs.map((log, index) => (
              <div key={`log${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center me-2'>
                  <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                    {log.endDate}
                  </span>
                  <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                    {log.ClientName}
                  </a>
                  {/* <span className='badge badge-light fs-8'>{log.time}</span> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { HeaderNotificationsMenu }
