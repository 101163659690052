import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, message } from "antd";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import * as Yup from "yup";
import axios from "axios";
import { KTIcon, toAbsoluteUrl } from "../../../../../src/_metronic/helpers";
import useGetDataWithFullUrl from "../../../hooks/getDataWithFullUrl";
import { BASE_URL, BASE_URL_TWO } from "../../../../utils";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegExp = /^[0-9]{8}$/;

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .required("Email Id is required")
        .email("Invalid email")
        .matches(emailRegex, "Invalid email"),
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    phone: Yup.string()
        .matches(phoneRegExp, "Phone number must be exactly 8 digits")
        .required("Phone number is required"),
});

export default function EditStaff({ visible, onClose, staffData, setShouldRefetch, shouldRefetch }) {

    const id = Cookies.get("userId");
    const token = Cookies.get("token");

    const headers = {
        token: token,
        "Content-Type": "application/json",
    };

    const compenies = useGetDataWithFullUrl(`${BASE_URL}getCompaniesList&userId=${id}`)?.fetchedData;
    const roles = useGetDataWithFullUrl(`${BASE_URL}getRoles&userId=${id}`)?.fetchedData;

    const [imageBase64, setImageBase64] = useState(staffData?.profileImage || null);

    const fileInputRef = useRef(null);

    const handlImageChange = (e) => {
        const file = e.target.files ? e.target.files[0] : "";
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageBase64(reader.result); // Convert to base64
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const handleClear = () => {
        setImageBase64("");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const initialValues = {
        fname: staffData?.firstName || "",
        lname: staffData?.lastName || "",
        compenyId: staffData?.compenyId || "",
        roleId: staffData?.roleId || "",
        email: staffData?.email || "",
        phone: staffData?.phoneNumber || "",
    };

    useEffect(() => {
        if (staffData) {
            formik.setFieldValue('fname', staffData?.firstName)
            formik.setFieldValue('lname', staffData?.lastName)
            formik.setFieldValue('email', staffData?.email)
            formik.setFieldValue('phone', staffData?.phoneNumber)
            setImageBase64(staffData?.profileImage)
        }
    }, [staffData])

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                await axios.post(
                    `${BASE_URL_TWO}`,
                    {
                        "sp": 'updEmployer',
                        staffId: staffData?.userId,
                        firstName: values?.fname,
                        lastName: values?.lname,
                        phone: values?.phone,
                        email: values?.email,
                        profileImage: imageBase64,
                    },
                );
                setShouldRefetch(!shouldRefetch)
                message.success("Staff updated successfully!");
                onClose();
            } catch (error) {
                message.error(error?.response?.data?.Message || "Failed to update staff");
            }
        },
    });

    return (
        <Modal
            title="Edit Staff"
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose
            centered
            width={800} // Adjust modal width
            maskClosable={false}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row mb-5" style={{ textAlign: "center" }}>
                    <div className="col-lg-12" onClick={handlImageChange}>
                        <div className="image-input image-input-outline" data-kt-image-input="true">
                            <div
                                className="image-input-wrapper w-150px h-150px mx-auto"
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl(imageBase64 ? imageBase64?.includes('assets/images/') ? `${BASE_URL_TWO}${imageBase64}` : imageBase64 : "/media/avatars/blank.png")})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            ></div>
                            <label
                                style={{ color: "#0000FF" }}
                                className="fw-bold fs-8 cursor-pointer"
                                htmlFor="photo"
                            >
                                Select photo!
                            </label>
                            <input
                                type="file"
                                id="photo"
                                name="photo"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handlImageChange}
                                ref={fileInputRef}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">First Name <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="fname"
                            value={formik.values.fname}
                            onChange={formik.handleChange}
                            placeholder="Enter first name"
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.fname}</span>
                    </div>
                </div>

                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Last Name <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="lname"
                            value={formik.values.lname}
                            onChange={formik.handleChange}
                            placeholder="Enter last name"
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.lname}</span>
                    </div>
                </div>

                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Role <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type='roleId'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 fs-7 bg-body-secondary'
                            placeholder=''
                            autoComplete='off'
                            name='roleId'
                            value={roles?.filter(item => item?.roleName == 'Employer')[0]?.roleName}
                            onChange={formik.handleChange}
                            readOnly
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.roleId}</span>
                    </div>
                </div>

                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Email <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type="email"
                            className="form-control form-control-lg form-control-solid"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder="Enter your email"
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.email}</span>
                    </div>
                </div>

                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Phone <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            placeholder="Enter mobile number"
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.phone}</span>
                    </div>
                </div>

                <Grid container justifyContent="end">
                    <button type="submit" className="btn btn-lg btn-primary mt-5">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="indicator-label">Update</span>
                            <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                        </div>
                    </button>
                </Grid>
            </form>
        </Modal>
    );
}
