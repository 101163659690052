import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useFetchData from '../../../hooks/getData';
import { KTSVG } from '../../../../_metronic/helpers';
import { message, Modal } from "antd";
import axios from 'axios';
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Department Name is  required'),
})


export default function EditDivsion({ selectedDivision, setSelectedDivision, companyList, companyId, visible, onClose, selectedDepartment, departments, setShouldRefetch }) {

    // const departments = useFetchCommon('getDepartmentList')?.commonDataFetch

    const currentDept = departments

    const Ar = [{
        deptName: '',
        deptId: ''
    }]

    const [dptId, setDeptId] = useState()

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Division updated',
            duration: 1
        });
    };

    const showError = () => {
        message.error({
            content: 'Something went wrong',
            duration: 1
        });
    }

    const initialValues = {
        companyName: '',
        name: selectedDivision?.divisionName,
        dptId: selectedDepartment,
    }

    useEffect(() => {
        if (selectedDivision) {
            formik.setFieldValue('name', selectedDivision?.divisionName)
        }
    }, [selectedDivision])
    // deptId
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            try {
                axios.post(`${BASE_URL_TWO}`, {
                    sp: 'updDivision',
                    userId: id,
                    divisionName: values?.name,
                    divisionId: selectedDivision?.divisionId,
                    deptId: selectedDepartment
                }, { headers }).then(() => {
                    showMessage();
                    onClose();
                    setShouldRefetch(prevState => !prevState)
                })
            } catch (error) {
                showError()
            }
        },
        enableReinitialize: true
    });

    // @userId int,
    // @token varchar(50),
    // @divisionName varchar(100),
    // @deptId INT,
    // @divisionId INT

    const handleClose = () => {
        formik.resetForm();
        setSelectedDivision('')
        onClose();
    }

    return (
        <Modal
            title="Edit Division"
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
            centered
            width={600} // Adjust modal width if needed
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="" role="document">
                    <div>
                        <div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="companyId"
                                        value={companyList?.filter(item => item?.companyId == companyId)[0]?.companyName}
                                        disabled={true}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.companyId}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Department </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name="dptId"
                                        value={departments?.filter(item => item?.deptId == selectedDepartment)[0]?.deptName}
                                        disabled={true}
                                    />
                                    {/* <select
                                        onChange={(e) => {
                                            setDeptId(e.target.value)
                                            formik.handleChange(e)
                                            setShouldRefetch(prevState => !prevState)
                                            formik.setFieldValue('dptId', e.target.value)

                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="dptId"
                                        autoComplete='off'
                                        value={formik.values.dptId}

                                    >
                                        <option>Select Department</option>
                                        {
                                            departments?.map((data, index) =>
                                                <option value={data?.deptId}>{data?.deptName}</option>
                                            )
                                        }
                                    </select> */}
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.dptId}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Division Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='name'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer mt-2">
                            <button type="button" className="btn btn-light" onClick={handleClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary ms-3" >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form >
        </Modal>
    )
}
