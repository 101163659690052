import React, { useEffect, useState } from "react";
import { Modal, Button, message } from "antd";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import useGetDataWithFullUrl from "../../../hooks/getDataWithFullUrl";
import { BASE_URL, BASE_URL_TWO } from "../../../../utils";

// Validation schema for form fields
const validationSchema = Yup.object().shape({
    companyId: Yup.string().required("Company selection is required"),
    benefitName: Yup.string().required("Benefit name is required"),
});

export default function AddBenefit({ visible, onClose, setShouldRefetch, shouldRefetch }) {
    const id = Cookies.get("userId");
    const token = Cookies.get("token");

    const headers = {
        token: token,
        "Content-Type": "application/json",
    };

    // Fetch companies list for dropdown
    const companies = useGetDataWithFullUrl(`${BASE_URL}getCompaniesList&userId=${id}`)?.fetchedData;

    // Initial form values
    const initialValues = {
        companyId: "",
        benefitName: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values,{resetForm}) => {
            try {
                // Submit the form data to add the benefit
                await axios.post(
                    `${BASE_URL_TWO}`,
                    {
                        sp: "insertBenefit",
                        userId:id,
                        companyId: Number(values.companyId),
                        benefitName: values.benefitName,
                    },
                    { headers }
                );
                setShouldRefetch(!shouldRefetch);
                resetForm();
                message.success("Benefit added successfully!");
                onClose();
            } catch (error) {
                message.error(error?.response?.data?.Message || "Failed to add benefit");
            }
        },
    });

    return (
        <Modal
            title="Add Benefit"
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose
            centered
            width={600}
            className="custom-modal-header p-0"
            maskClosable={false}
        // Adjust modal width
        >
            <form onSubmit={formik.handleSubmit}>
                {/* Company Dropdown */}
                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Company <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <select
                            className="form-control form-control-lg form-control-solid"
                            name="companyId"
                            value={formik.values.companyId}
                            onChange={formik.handleChange}
                        >
                            <option value="">Select a Company</option>
                            {companies?.map((company) => (
                                <option key={company?.companyId} value={company?.companyId}>
                                    {company?.companyName}
                                </option>
                            ))}

                        </select>
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.companyId}</span>
                    </div>
                </div>

                {/* Benefit Name Field */}
                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Benefit Name <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="benefitName"
                            value={formik.values.benefitName}
                            onChange={formik.handleChange}
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.benefitName}</span>
                    </div>
                </div>

                <Grid container justifyContent="end">
                    <button type="submit" className="btn btn-lg btn-primary mt-5">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="indicator-label">Add Benefit</span>
                        </div>
                    </button>
                </Grid>
            </form>
        </Modal>
    );
}
