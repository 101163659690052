import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Grid } from '@mui/material';
import { Button, message } from "antd";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { renderAsync } from 'docx-preview';
import PopUp from '../../../components/Modal/Modal';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';

function HRLetters() {
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const id = Cookies.get('userId');
    const token = Cookies.get("token");

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getHRLettersList&userId=${id}`, [shouldRefetch]);
    const [clickedItem, setClickedItem] = useState(null);
    const [docxUrl, setDocxUrl] = useState('');
    const [downloadUrl, setDownloadUrl] = useState();
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState();
    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState();

    const handleClick = (itemId, itemDocxUrl) => {
        setClickedItem(itemId);
        setDocxUrl(itemDocxUrl);
        displayDocx(itemDocxUrl);
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const displayDocx = (url) => {
        if (!url) return;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const reader = new FileReader();
                reader.onload = function (event) {
                    const arrayBuffer = event.target.result;
                    renderAsync(arrayBuffer, document.getElementById('docxViewer'))
                        .catch(error => {
                            console.error('Error rendering DOCX file:', error);
                        });
                };
                reader.readAsArrayBuffer(blob);
            })
            .catch(error => {
                console.error('Error loading DOCX file:', error);
            });
    };

    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 2
        });
    };

    const showError = (msg) => {
        message.error({
            content: msg,
            duration: 2
        })
    }

    const handleFileChange = (event, setFieldValue) => {
        if (event.currentTarget.files[0]) {
            const file = event.currentTarget.files[0];
            const filename = file.name;

            const allowedTypes = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]; // Only .docx

            if (!allowedTypes.includes(file.type)) {
                setFieldValue('docFile', '');
                setFilename("No Files choosen");
                setFile(null);
                showError("You can only upload .docx files.");
                return;
            } else {
                setFile(file);
                setFilename(filename);
                setFieldValue('docFile', event.currentTarget.files[0]);
            }
        }

    };

    const validationSchema = Yup.object().shape({
        docTitle: Yup.string().required('Document name is required'),
        docFile: Yup.mixed().required('Document file is required')
    });

    const showErrorMessage = (msg) => {
        message.info({
            content: msg,
            duration: 2
        });
    };

    const handleAddDocument = async (values, { resetForm }) => {
        axios.post(`${BASE_URL_TWO}`, {
            "sp": 'insHRLetters',
            "userId": id,
            "docTitle": values.docTitle,
            "docFile": file ? await convertFileToBase64(file) : ""
        }, { headers })
            .then((res) => {
                showMessage("Successfully created");
                resetForm();
                setFile('')
                setFilename('No Files Choosen')
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }).catch((err) => {
                showErrorMessage("Failed to create");
            });
    };

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                "sp": 'delHRLetters',
                "userId": id,
                "letterId": clickedItem
            }, { headers })
                .then((res) => {
                    showMessage("Deleted successfully");
                    setShouldRefetch(true);
                    setClickedItem(null);
                    setDocxUrl('');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }).catch((err) => {
                    showErrorMessage("Failed to delete");
                });
        }
    }, [actionStatus]);

    const handleDownload = async (url, filename) => {
        if (!filename || !url) {
            alert('No document found');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL_TWO}${url}`);
            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${filename}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);

        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    return (
        <>
            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete The Document"
                message="Are you sure you want to delete"
                btnName="Delete"
            />
            <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> HR Letters and Forms</h5>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button"
                    className="btn btn-primary btn-sm mb-5"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1"
                >
                    <i className="bi bi-plus-circle fs-4 svg-icon-muted text-white mb-1"></i>
                    Add
                </button>
            </div>

            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div style={{ justifyContent: "space-between" }} className="modal-header bg-primary-subtle">
                            <h5 className="modal-title text-primary"> Add New Document </h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={{ docTitle: '', docFile: '' }}
                                validationSchema={validationSchema}
                                onSubmit={handleAddDocument}
                            >
                                {({ setFieldValue }) => (
                                    <Form>
                                        <div className='row mb-1'>
                                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Document Name</label>
                                            <div className='col-lg-6 fv-row'>
                                                <Field
                                                    type='text'
                                                    name='docTitle'
                                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                                                />
                                                <ErrorMessage name="docTitle" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className='row mb-1'>
                                            <label className='col-lg-5 col-form-label fw-bold fs-6'>Upload Document</label>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type="file"
                                                    id="fileUpload"
                                                    name="docFile"
                                                    className='form-control-file form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                                                    onChange={(e) => {
                                                        handleFileChange(e, setFieldValue)
                                                    }}
                                                    
                                                />
                                                <ErrorMessage name="docFile" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                data-bs-dismiss="modal"
                                                onClick={()=>{setFile(''); setFieldValue('docTitle','');  setFieldValue('docFile',''); setFilename('')}}
                                            >
                                                Close
                                            </button>
                                            <button
                                                type="submit" className="btn btn-primary">
                                                Add
                                            </button>

                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            {fetchedData?.length > 0 && <div className="row">
                <div className="col-4">
                    <div className="list-group" id="list-tab" role="tablist">
                        {
                            fetchedData?.map((data, index) =>
                                <a
                                    key={index}
                                    onClick={() => {
                                        handleClick(data?.letterId, `${BASE_URL_TWO}${data?.documentPath}`)
                                        setFilename(data?.letterTitle)
                                        setDownloadUrl(data?.documentPath)
                                    }}
                                    className={`btn btn-outline btn-outline-light-primary btn-active-danger mx-3 mb-3 fs-7 fw-bold text-body-secondary ${clickedItem === data?.letterId ? 'active' : ''
                                        }`}
                                    id="Letter-1-list" data-bs-toggle="list" role="tab" aria-controls="Letter-1"
                                    style={{ background: "#CFE0FD" }}>
                                    {data?.letterTitle}
                                </a>
                            )
                        }
                    </div>
                </div>
                <div className="col-8">
                    <div className="row">
                        {
                            docxUrl &&
                            <div className="col-12">
                                <Grid container justifyContent="center" sx={{
                                    bgcolor: '', borderRadius: 3,
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    paddingTop: "50px",
                                }}>
                                    <div id="docxViewer" style={{ width: '100%', height: '500px', overflow: 'auto' }}></div>
                                    <Grid container lg={11} sx={{ my: 5 }}>
                                        <button onClick={() => handleDownload(downloadUrl, filename)} className="btn btn-sm mt-10 me-5" type="button" style={{ background: "#36454F", color: "#fff" }}>
                                            <i className="bi bi-file-earmark-word fs-2 px-3 svg-icon-muted text-white mt--1"></i>Download
                                        </button>

                                        <button
                                            onClick={() => {
                                                setShow(true);
                                                setDeletId(clickedItem)
                                            }}
                                            className="btn btn-sm mt-10 me-5 svg-icon-muted" type="button" style={{ background: "#36454F", color: "#fff" }}>
                                            <i className="bi bi-trash3 fs-2 px-3 mt--1 svg-icon-muted text-white"></i>
                                            Delete
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </div>
                </div>
            </div>}
            {fetchedData?.length <= 0 && <div>
                <NoFiles isData={true} />
            </div>}
        </>
    );
}

export { HRLetters };
