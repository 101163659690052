import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import useFetchCommon from '../../../hooks/getCommonData';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import CreateDepartment from './CreateDepartment';
import { KTIcon } from '../../../../_metronic/helpers';
import VehiclesForm from '../../../components/VehiclesTable/VehiclesForm';
import EditDepartment from './EditDepartment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
// import { KTIcon } from '../../../_metronic/helpers';
import { message } from "antd";
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import usegetDataWithFullUrlThree from '../../../hooks/getDataWithFullUrlThree';
import PopUp from '../../../components/Modal/Modal';

export default function Department() {

    const id = Cookies.get('userId');
    const token = Cookies.get('token')
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [companyList, setCompanyList] = useState([])
    const [visible,setIsVisible] =  useState(false)
    const [editVisible,setEditVisible] = useState(false);

    const fetchCompanies = async () => {
        const response = await axios.get(`${BASE_URL}getCompanies`)
        setCompanyList(response?.data?.Data);
    }
    useEffect(() => {
        fetchCompanies()
    }, [])

    const companyOptions = companyList?.map(item => ({
        label: item?.companyName,
        id: item?.companyId
    }))

    const [selectedCompany, setSelectedCompany] = useState()

    const { commonDataFetch } = useFetchCommon(`getDepartmentsByCompanyId&companyId=${selectedCompany?selectedCompany :''}`, {}, [shouldRefetch])

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const [dptId, setDptId] = useState();

    const showMessage = (msg) => {
        message.success({
            content: msg,
            duration: 1
        });
    };

    const a = () => {
        message.info({
            content: "This division can't delete",
            duration: 2
        });
    }

    const selectedDept = commonDataFetch?.filter((data, index) => dptId == data?.deptId)

    const [show, setShow] = useState();
    const [actionStatus, setActionStatus] = useState();
    const [deletId, setDeletId] = useState()

    useEffect(() => {
        if (actionStatus && deletId) {
            axios.post(`${BASE_URL_TWO}`, {
                sp: "delDepartment",
                userId: Number(id),
                deptId: deletId
            }, { headers })
                .then(() => {
                    showMessage("Deleted successfully");
                    setShouldRefetch(prevState => !prevState);
                    setActionStatus(false)
                    setDeletId(undefined)
                })
                .catch(error => {
                    if (error.response) {
                        console.error("Server Error:", error.response.data);
                        a("Server Error: Please try again later.");
                    } else if (error.request) {
                        console.error("Network Error:", error.request);
                        a("Network Error: Please check your internet connection.");
                    } else {
                        console.error("Error:", error.message);
                        a("An unexpected error occurred. Please try again later.");
                    }
                    setDeletId(undefined)
                    setActionStatus(false)
                });
        }
    }, [deletId, actionStatus]);

    return (
        <>
            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Department"
                message="Are you sure you want to delete"
                btnName="Delete"
            />

            <h5 className="text-black-50 mb-10">
                <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Departments
            </h5>

            {<div className=' d-flex justify-content-between align-items-center mb-3'>
                <div className='col-3 col-md-3'>
                    <Autocomplete
                        disablePortal
                        id='yearField'
                        color="primary"
                        options={companyOptions}
                        onInputChange={(event, value) => {
                            // Check for valid value before updating state
                            setSelectedCompany(value?.id || ''); // Set to null if no valid company is selected
                            setShouldRefetch(prevState => !prevState);
                        }}
                        onChange={(event, value) => {
                            // Handle the unselect case where value is null or undefined
                            setSelectedCompany(value?.id || ''); // Set to null if no valid company is selected
                            setShouldRefetch(prevState => !prevState);
                        }}
                        classes={{
                            input: 'text-dark'
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                required
                                focused
                                label="Company"
                                placeholder="Select a company"
                            />
                        }
                    />

                </div>
                <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <span
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        onClick={()=>setIsVisible(true)}
                        >
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </span>
                </div>

            </div >}

            <CreateDepartment visible={visible} onClose={()=>setIsVisible(false)} companyList={companyList} onAdd={() => console.log('')} setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch} />

            <EditDepartment visible={editVisible} onClose={()=>setEditVisible(false)} companyId={selectedCompany ? selectedCompany : companyOptions[0]?.id} companyList={companyList} selectedDept={selectedDept ? selectedDept[0] : ''} modalCls={false} shouldRefetch={shouldRefetch} setShouldRefetch={setShouldRefetch} />

            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid container>
                    <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                        <thead>
                            <tr className="fw-bold fs-5 bg-primary rounded text-white">
                                <th>#</th>
                                <th>Departments</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                commonDataFetch?.map((data, index) =>
                                    <tr style={{ borderBottom: "" }} key={index}>
                                        <td className="text-primary fw-bold"><span className="badge">{index + 1}</span></td>
                                        <td className="text-primary fw-bold">{data?.deptName}</td>
                                        <td className="text-primary fw-bold">
                                            <Tooltip title="Delete" arrow>
                                                <DeleteIcon
                                                    onClick={() => {
                                                        setShow(true);
                                                        setDeletId(data?.deptId);
                                                    }}
                                                    sx={{ cursor: 'pointer' }}
                                                />
                                            </Tooltip>

                                            <span
                                                onClick={() => {setDptId(data?.deptId); setEditVisible(true)}}
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                                >
                                                <Tooltip title="Edit" arrow>
                                                    <ModeEditIcon
                                                        sx={{
                                                            cursor: 'pointer',
                                                            ml: 2
                                                        }}
                                                    />
                                                </Tooltip>
                                            </span>

                                        </td>
                                    </tr>
                                )
                            }
                            { }
                            {commonDataFetch?.length <= 0 && <tr><td className='text-center' colSpan={3}>No Records Found</td></tr>}
                        </tbody>
                    </table>
                </Grid>
            </Grid>

        </>

    )
}
