import React, { useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import * as Yup from "yup";
import axios from "axios";
import { KTIcon, toAbsoluteUrl } from "../../../../../src/_metronic/helpers";
import useGetDataWithFullUrl from "../../../hooks/getDataWithFullUrl";
import { BASE_URL, BASE_URL_TWO } from "../../../../utils";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegExp = /^[0-9]{8}$/;


const validationSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .required("Email Id is required")
        .email("Invalid email")
        .matches(emailRegex, "Invalid email"),
    password: Yup.string()
        .min(8, "Minimum 8 characters")
        .max(50, "Maximum 50 characters")
        .required("Password is required"),
    vpassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    fname: Yup.string().required("First name is required"),
    lname: Yup.string().required("Last name is required"),
    compenyId: Yup.string().required("Company is required"),
    phone: Yup.string()
        .matches(phoneRegExp, "Phone number must be exactly 8 digits")
        .required("Phone number is required"),
});

export default function AddStaff({ visible, onClose, setShouldRefetch, shouldRefetch }) {
    const id = Cookies.get("userId");
    const token = Cookies.get("token");

    const headers = {
        token: token,
        "Content-Type": "application/json",
    };

    const [compenyId, setCompenyId] = useState()
    const [companyList, setCompanyList] = useState([])
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const fetchCompanies = async () => {
        const response = await axios.get(`${BASE_URL}getCompanies`)
        setCompanyList(response?.data?.Data);
    }
    useEffect(() => {
        fetchCompanies()
    }, [])


    const roles = useGetDataWithFullUrl(`${BASE_URL}getRoles&userId=${id}`)?.fetchedData;

    const [imageBase64, setImageBase64] = useState(null);
    const fileInputRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageBase64(reader.result); // Convert to base64
            };
            reader.readAsDataURL(file); // Read file as data URL
        }
    };

    const handleClear = () => {
        setImageBase64("");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const formik = useFormik({
        initialValues: {
            fname: "",
            lname: "",
            email: "",
            password: "",
            vpassword: "",
            compenyId: "",
            phone: "",
        },
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            try {
                await axios.post(
                    `${BASE_URL_TWO}`,
                    {
                        sp: "insUser",
                        userId: id,
                        companyId: values?.compenyId,
                        roleId: roles?.filter(item => item?.roleName == 'Employer')[0]?.roleId,
                        firstName: values.fname,
                        lastName: values.lname,
                        contactNo: values.phone,
                        userName: values.email,
                        password: values.password,
                        profileImage: imageBase64,
                    },
                    { headers }
                );
                setShouldRefetch(!shouldRefetch);
                message.success("Staff added successfully!");
                onClose();
                resetForm();
                setImageBase64("");
            } catch (error) {
                message.error(error?.response?.data?.Message || "Failed to add staff");
            }
        },
    });

    return (
        <Modal
            title="Add Staff"
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose
            centered
            width={800} // Adjust modal width
            maskClosable={false}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="row mb-5" style={{ textAlign: "center" }}>
                    <div className="col-lg-12" onClick={handleImageChange}>
                        <div className="image-input image-input-outline" data-kt-image-input="true">
                            <div
                                className="image-input-wrapper w-150px h-150px mx-auto"
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl(
                                        imageBase64 || "/media/avatars/blank.png"
                                    )})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                }}
                            ></div>
                            <label
                                style={{ color: "#0000FF" }}
                                className="fw-bold fs-8 cursor-pointer"
                                htmlFor="photo"
                            >
                                Select photo!
                            </label>
                            <input
                                type="file"
                                id="photo"
                                name="photo"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                                ref={fileInputRef}
                            />
                        </div>
                    </div>
                </div>

                {["fname", "lname", "email", "phone"].map(
                    (field, index) => (
                        <div className="row mb-3" key={index}>
                            <label className="col-lg-3 col-form-label fw-bold fs-7">
                                {field === "vpassword"
                                    ? "Confirm Password"
                                    : field === "fname" ? "First Name" : field === 'lname' ? "Last Name" : field.charAt(0).toUpperCase() + field.slice(1)} <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-9">
                                <input
                                    type={
                                        field.includes("password") ? "password" : "text"
                                    }
                                    placeholder={field === "fname" ? "Enter first name" : field === "lname" ? "Enter last name" : field === "email" ? "Enter your email" : field === "phone" ? "Enter mobile number" : ''}
                                    className="form-control form-control-lg form-control-solid"
                                    name={field}
                                    value={formik.values[field]}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ fontSize: "10px", color: "red" }}>
                                    {formik.errors[field]}
                                </span>
                            </div>
                        </div>
                    )
                )}
                <div className="row mb-3" >
                    <label className="col-lg-3 col-form-label fw-bold fs-7">
                        Role <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9 position-relative">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            name="roleId"
                            value={roles?.filter(item => item?.roleName == 'Employer')[0]?.roleName}
                            onChange={formik.handleChange}
                           
                            disabled
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>
                            {formik.errors.roleId}
                        </span>
                    </div>
                </div>
                <div className="row mb-3" >
                    <label className="col-lg-3 col-form-label fw-bold fs-7">
                        Password <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9 position-relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-lg form-control-solid"
                            name="password"
                             placeholder="Enter your password"
                            
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        <span
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '18px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }}
                        >
                            <i className={`bi ${showPassword ? 'bi-eye' : 'bi-eye-slash'}`}></i>
                        </span>
                        <span style={{ fontSize: "10px", color: "red" }}>
                            {formik.errors.password}
                        </span>
                    </div>
                </div>
                <div className="row mb-3" >
                    <label className="col-lg-3 col-form-label fw-bold fs-7">
                        Confirm Password <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9 position-relative">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control form-control-lg form-control-solid"
                            name="vpassword"
                            value={formik.values.vpassword}
                            onChange={formik.handleChange}
                            placeholder="Confirm your password"
                        />
                        <span
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            style={{
                                position: 'absolute',
                                top:"50%",
                                right: '18px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }}
                        >
                            <i className={`bi ${showConfirmPassword ? 'bi-eye' : 'bi-eye-slash'}`}></i>
                        </span>
                        <span style={{ fontSize: "10px", color: "red" }}>
                            {formik.errors.vpassword}
                        </span>
                    </div>
                </div>
                <div className='row mb-3'>
                    <label className="col-lg-3 col-form-label fw-bold fs-7">
                        Company <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-9">
                        <select
                            name="compenyId"
                            id=""
                            className='form-select form-select-solid border fs-7 form-control-lg form-control-solid'
                            onChange={(e) => {
                                setCompenyId(e.target.value)
                                formik.handleChange(e);
                            }}
                            value={compenyId}
                        >
                            <option value=''>Select Company</option>
                            {
                                companyList?.map((data, index) =>

                                    <option value={data?.companyId}>{data?.companyName}</option>

                                )
                            }

                        </select>
                        <span style={{ fontSize: '10px', color: 'red' }}>{formik.errors.compenyId}</span>
                    </div>
                </div>

                <Grid container justifyContent="end">
                    <button type="submit" className="btn btn-lg btn-primary mt-5">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="indicator-label">Add Staff</span>
                            <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                        </div>
                    </button>
                </Grid>
            </form>
        </Modal>
    );
}
