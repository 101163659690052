import React, { useState } from 'react';
import { MixedWidget16 } from '../../../../_metronic/partials/widgets';
import useFetchData from '../../../hooks/getData';

export function LeaveStatus() {
  const { fetchedData } = useFetchData('getLeaveStatus');
  const [activeIndex, setActiveIndex] = useState(null); // State to manage the active index

  const handleWidgetClick = (index) => {
    // If the clicked index is already active, close it; otherwise, set it as active
    setActiveIndex(activeIndex === index ? null : index);
  };

  let Data;
  const statusDetails = activeIndex !== null ? fetchedData[activeIndex]?.leaveDetails : '';

  if (statusDetails) {
    try {
      Data = JSON.parse(statusDetails);
    } catch (error) {
      console.log("Error parsing status details:", error);
    }
  }

  return (
    <div className='accordion-item bg-transparent pb-2'>
      <div
        id='collapseExample3'
        className='accordion-collapse collapse'
        aria-labelledby='kt_accordion_header_3'
        data-bs-parent='#kt_accordion'
      >
        <div className="card card-body mb-2" style={{ backgroundColor: "rgba(207,226,255,255)" }}>
          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div id="carouselExampleControlsNoTouching" className="carousel carousel-dark slide" data-bs-touch="false">
                <div className="carousel-inner pt-5">
                  <div className="carousel-item active bg-transparent">
                    <br />
                    <div className='row g-2 g-xl-4 justify-content-md-center'>
                      {[0, 1, 2].map((index) => (
                        <div key={index} className='col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                          {fetchedData && (
                            <a
                              onClick={() => handleWidgetClick(index)}
                              className="col-md-3 col-lg-3 col-xl-3 col-xxl-3"
                              role="button"
                              aria-expanded={activeIndex === index}
                            >
                              <MixedWidget16
                                className='h-md-20 mb-5 mb-xl-10'
                                chartProgressColor={['#143983', '#34a8cb', '#6255a3'][index]}
                                chartTrackColor={['#BFCAE2', '#C0DFE9', '#D1CCEC'][index]}
                                chartHeight='250px'
                                chartTitle={fetchedData[index]?.policytitle}
                                chartCompleted={fetchedData[index]?.totalcount || 0}
                                chartTotal={fetchedData[index]?.availablecount || 0}
                              />
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className='row g-2 g-xl-4 justify-content-md-center'>
                      {[3, 4, 5].map((index) => (
                        <div key={index} className='col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                          {fetchedData && (
                            <a
                              onClick={() => handleWidgetClick(index)}
                              className="col-md-3 col-lg-3 col-xl-3 col-xxl-3"
                              role="button"
                              aria-expanded={activeIndex === index}
                            >
                              <MixedWidget16
                                className='h-md-20 mb-5 mb-xl-10'
                                chartProgressColor={['#B24C37', '#ED62E2', '#355e3b'][index - 3]}
                                chartTrackColor={['#ECA191', '#FFA7F8', '#addfad'][index - 3]}
                                chartHeight='250px'
                                chartTitle={fetchedData[index]?.policytitle}
                                chartCompleted={fetchedData[index]?.totalcount || 0}
                                chartTotal={fetchedData[index]?.availablecount || 0}
                              />
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className='row g-2 g-xl-4 justify-content-md-center'>
                      {[6, 7, 8].map((index) => (
                        <div key={index} className='col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                          {fetchedData && (
                            <a
                              onClick={() => handleWidgetClick(index)}
                              className="col-md-3 col-lg-3 col-xl-3 col-xxl-3"
                              role="button"
                              aria-expanded={activeIndex === index}
                            >
                              <MixedWidget16
                                className='h-md-20 mb-5 mb-xl-10'
                                chartProgressColor={['#FFC300', '#5A7FFF', '#FC3C85'][index - 6]}
                                chartTrackColor={['#FFDF75', '#B7C7FF', '#FFC0D8'][index - 6]}
                                chartHeight='250px'
                                chartTitle={fetchedData[index]?.policytitle}
                                chartCompleted={fetchedData[index]?.totalcount || 0}
                                chartTotal={fetchedData[index]?.availablecount || 0}
                              />
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                  <div className='card p-3'>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </div>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                  <div className='card p-3'>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </div>
                </button>
              </div>
            </div>

            {Data && (
              <div className="d-md-flex justify-content-md-center">
                <div className={`collapse w-50 ${activeIndex !== null ? 'show' : ''}`} id="Sick_Leave_Dates_Table">
                  <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                    <thead>
                      <tr className="fw-bold fs-5 bg-primary rounded text-white">
                        <th><i className="bi bi-collection fs-3 me-2 svg-icon-muted text-white"></i>Leave Type</th>
                        <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>First day of Leave:</th>
                        <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>Return to work date:</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Data.map((data, index) => (
                        <tr key={index}>
                          <td className="text-primary fw-bold">{data.leaveType}</td>
                          <td className="text-primary fw-bold"><span className="badge badge-primary p-2">{data.startDate}</span></td>
                          <td className="text-primary fw-bold"><span className="badge badge-light-primary p-2">{data.ReturnDate}</span></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
