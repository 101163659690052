import clsx from 'clsx'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'
import { BASE_URL_TWO } from '../../../../utils';


const itemClass = 'ms-1 ms-lg-2'
const btnClass ='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'
// empName={empName}
const Navbar = ({ empName }) => {
  const { config } = useLayout()

  const { currentUser, logout } = useAuth()
 
  return (
    <div className='app-navbar flex-shrink-0 ' style={{ zIndex: 100 }}>
      <h5 style={{ display: empName ? '' : 'flex' }} className='menu-content pt-6 pb-2  text-white '> <span className="badge badge-light-primary mx-6 fs-8"><i className="bi bi-person-circle fs-3 me-2 text-primary"></i>
        {empName}
      </span></h5>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >

          <KTSVG path='/media/icons/duotune/general/gen007.svg' className={clsx('svg-icon-2x', btnIconClass)} />

        </div>
        <HeaderNotificationsMenu empName={empName} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {currentUser?.profileImage ? (
            <img alt='Logo' src={`${BASE_URL_TWO}${currentUser.profileImage}`} />
          ) : (
            <img alt='ProfileImage' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          )}
        </div>
        <HeaderUserMenu empName={empName} />
      </div>

      {
        config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div className='btn btn-icon btn-active-color-primary w-35px h-35px' id=''>
            </div>
          </div>
        )
      }
    </div>
  )
}

export { Navbar }