import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { BASE_URL_TWO } from '../../../../utils';
import { message } from "antd";

//import { useAuth } from '../../../modules/auth/core/Auth';
import { setAuth } from '../../../modules/auth/core/AuthHelpers';
import { useAuth } from '../../auth';
import { AuthModel } from '../../auth/core/_models';
import { getUserDetails } from '../../auth/core/_requests'
import { ClipLoader } from 'react-spinners';


const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const loginSchema = Yup.object().shape({
  user_name: Yup.string().trim().required("Email id is required").email("Invalid email").matches(emailRegex, 'Invalid email'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});

const initialValues = {
  // email: 'admin@demo.com',
  user_name: '',
  password: '',
};

const Login: FC = () => {

  const { saveAuth, setCurrentUser,currentUser } = useAuth()

  const navigate = useNavigate()

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading,setLoading]= useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnChange: true, // Enable validation on change
    validateOnBlur: true,   // Enable validation on blur
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      axios.post(`${BASE_URL_TWO}`, {
        "sp": "spAuthenticateUser",
        "username": values?.user_name,
        "password": values?.password
      }).then((res) => {
       
        const one = JSON.parse(res?.data?.Data[0]?.userPermissions)

        if (res?.data?.Message === "Success") {
          navigate(one ? one[0]?.pageUrl : '')
          Cookies.set('isLineManager1',res?.data?.Data[0]?.isLineManager1)
          Cookies.set('isLineManager2',res?.data?.Data[0]?.isLineManager2)
          Cookies.set('userId', res?.data?.Data[0]?.userId);
          Cookies.set('token', res?.data?.Data[0]?.Token);
          Cookies.set('pemissions', res?.data?.Data[0]?.userPermissions);
          Cookies.set('firstName', res?.data?.Data[0]?.firstName);
          Cookies.set('loggedUserName', res?.data?.Data[0]?.employeeName);

          //set auth
          // Create an instance of AuthModel
          const auth: AuthModel = {
            api_token: res?.data?.Data[0]?.Token,
            userId: res?.data?.Data[0]?.userId
            // Set other fields as required
          }
          // Call the setAuth function
          setAuth(auth);
         // setCurrentUser(res?.data?.Data[0]);
          showMessage();
          setLoading(false)
          updateUser(auth);

        }
      }).catch((error) => {
        setLoading(false);
        a(error.response.data.Message);
      }
      )
    },
  });

  const showMessage = () => {
    message.destroy();
    message.success({
      content: 'Login successful',
      duration: 4 // Duration in seconds
    });
  };

  const a = (msg: any) => {
    message.destroy();
    message.info({
      content: msg ? msg:'User name or password mismatching',
      duration: 3
    });
  }

  async function updateUser(auth:any) {
    try {
      // Await the promise and extract the response
      const response = await getUserDetails(auth.api_token,auth.userId);
      
      // Extract the user data from the response
      const user = response.data?.Data[0];
      
      // Set the current user
      setCurrentUser(user);



    } catch (error) {
      console.error('Error fetching user:', error);
      // Handle the error appropriately, e.g., setCurrentUser(undefined) or show an error message
    }
  }


  return (
    <>

      <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
        {/* {contextHolder} */}
        <div className='form-group fv-plugins-icon-container mb-5 text-start'>
          <div className='fv-row mb-3'>
            <label className='text-primary fw-bold fs-7 mb-3'>
              <i className='bi bi-person-fill fs-2 svg-icon-muted text-primary me-3'></i> Username{' '}
              <span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Email'
              {...formik.getFieldProps('user_name')}
              className={clsx(
                'form-control border-dark-subtle fs-8 custom-bg',
                { 'is-invalid': formik.touched.user_name && formik.errors.user_name },
                { 'is-valid': formik.touched.user_name && !formik.errors.user_name }
              )}
              type='text'
              name='user_name'
              autoComplete='off'
              value={formik.values.user_name}
              onChange={formik.handleChange}
            />
            {formik.touched.user_name && formik.errors.user_name && (
              <div className='fv-plugins-message-container'>
                <span style={{ color: 'red' }} role='alert'>
                  {formik.errors.user_name}
                </span>
              </div>
            )}
          </div>

          <div className='fv-row mb-3'>
            <label className='text-primary fw-bold fs-7 mb-3'>
              <i className='bi bi-lock-fill fs-2 svg-icon-muted text-primary me-3'></i> Password{' '}
              <span className='text-danger'>*</span>
            </label>
            <div className='input-group'>
              <input
                placeholder='Password'
                type={passwordVisible ? 'text' : 'password'} // Toggle input type based on passwordVisible state
                autoComplete='off'
                {...formik.getFieldProps('password')}
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                className={clsx(
                  'form-control border-dark-subtle fs-8 custom-bg',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <button
                type='button'
                className={clsx(
                  'btn border border-dark-subtle btn-sm', // Add the border class here
                  { 'is-invalid': formik.touched.password && formik.errors.password }
                )}
                onClick={() => setPasswordVisible(!passwordVisible)} // Toggle password visibility
              >
                <FontAwesomeIcon
                  icon={passwordVisible ? faEye : faEyeSlash}
                  style={{ fontSize: '12px' }} // Adjust the fontSize value as needed
                />
              </button>

            </div>
            {/* {formik.touched.password && formik.errors.password && ( */}
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>

        <div className='d-grid gap-2 mt-7'>
          <button className='btn btn-sm btn-primary ' style={{height:"35px"}} type='submit'>
            {loading ? <ClipLoader
                        color="#fff"
                        loading={loading}
                        size={16}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      /> : "Sign In"}</button>

        </div>
      </form>

    </>
  );
};

export { Login };
