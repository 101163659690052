import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const useFetchData = (url, dependencies = []) => {

    const [fetchedData, setFetchedData] = useState();
    const [isLoading,setIsloading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            const id = Cookies.get('userId');
            const token = Cookies.get("token");

            const headers = {
                'token': token,
                'Content-Type': 'application/json'
            };

            try {
                const response = await axios.get(`${BASE_URL + url}&userId=${id}`, { headers });
                setFetchedData(response?.data?.Data);
                setIsloading(false)
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  }
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, dependencies);

    return { fetchedData, refetch: fetchedData,isLoading };
};

export default useFetchData;
