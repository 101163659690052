import React, { useState, useEffect, useMemo, useRef } from 'react';
import { TextField, Autocomplete, Grid } from '@mui/material';
import { addDays } from 'date-fns';
import Calendar from '../../../components/Calendar/MonthView';
import Data from './data/data.json';
import useFetchData from '../../../hooks/getData';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import axios from 'axios';
import { message } from 'antd';
import { FadeLoader } from 'react-spinners';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useReactToPrint } from 'react-to-print';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';

const data = Data;

const LeaveTrackerPage = () => {
    const presentYear = new Date().getFullYear();
    const [employeeName, setEmployeeName] = useState(null);
    const [defaultName, setDefaultName] = useState('')
    const [year, setYear] = useState(presentYear);
    const [employeeLeaveDetails, setEmployeeLeaveDetails] = useState([]);
    const [ticketDetails, setTicketDetails] = useState({});
    const [type, setType] = useState('All');

    const col1 = useRef(null);
    const col2 = useRef(null);
    const col3 = useRef(null);
    const col4 = useRef(null);
    const [noTickets, setNoTIckets] = useState(0);
    const [ticketAmt, setAmt] = useState(0);
    const [days, setdays] = useState({
        Sick: 0,
        Marriage: 0,
        Maternity: 0,
        Annual: 0,
        Compassionate: 0,
        Unpaid: 0,
        Hajj: 0,
        Others: 0
    });

    const employeeOptions = useMemo(() => {
        return ["employee1", "employee2", "employee3", "employee4"];
    }, []);

    const downloadReport = async () => {
        const input = document.getElementById('calendarReport');
        const canvas = await html2canvas(input, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const pdf = new jsPDF({
            orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
            unit: 'pt',
            format: [imgWidth, imgHeight]
        });
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save(`Calendar_Report_${new Date().getFullYear()}.pdf`);
    };



    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    useEffect(() => {
        const listofLeaveDetails = [];
        let noOfDays = {
            Sick: 0,
            Marriage: 0,
            Maternity: 0,
            Annual: 0,
            Compassionate: 0,
            Unpaid: 0,
            Hajj: 0,
            Others: 0
        };
        for (let item of data) {
            if (item.name === employeeName) {
                const startDate = new Date(item.startDate);
                const endDate = new Date(item.endDate);
                let date = startDate;
                if (startDate.getFullYear() === year || endDate.getFullYear() === year) {
                    while (date < endDate) {
                        if (date.getFullYear() === year) {
                            const ld = { date: date, type: item.type };
                            listofLeaveDetails.push(ld);
                            noOfDays[item.type] = noOfDays[item.type] + 1;
                        }
                        date = addDays(date, 1);
                    }
                }
            }
        }
        setdays(noOfDays);
        setEmployeeLeaveDetails(listofLeaveDetails);
    }, [employeeName, year]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                col1.current && !col1.current.contains(event.target) &&
                col2.current && !col2.current.contains(event.target) &&
                col3.current && !col3.current.contains(event.target) &&
                col4.current && !col4.current.contains(event.target)
            ) {
                setType("All");
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const id = Cookies.get('userId');
    const token = Cookies.get("token");

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const colours = ["#34a8cb", "#6255a3", "#143983", "#6255a3", "#143983", "#6255a3", "#143983", "#6255a3", "#34a8cb", "#6255a3"];

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const employeNames = employeList?.map((data) => data?.employeeName) || [];

    let userId = employeList?.find(item => item.employeeName === employeeName)?.userId;
    let yearOfJoining = employeList?.find(item => item.employeeName === employeeName)?.joiningYear;

    if (!userId) {
        userId = employeList?.find(item => item.employeeName === employeNames[0])?.userId;
        yearOfJoining = employeList?.find(item => item.employeeName === employeNames[0])?.joiningYear;
    }

    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from(
            { length: currentYear - yearOfJoining + 1 }, // Calculate the range dynamically
            (_, index) => yearOfJoining + index // Start from yearOfJoining
        );
        return years;
    }, [yearOfJoining]);

    const [getSelectedItem, setGetSelectedItem] = useState();

    const leaveTrackingDetails = useGetDataWithFullUrl(`${BASE_URL}getEmployeeLeaveTrackerDetails&userId=${id}&employeeId=${userId}&year=${year}`, [shouldRefetch]).fetchedData;
    const filteredData = getSelectedItem
        ? leaveTrackingDetails?.filter((data) => getSelectedItem === data?.policytitle)
        : leaveTrackingDetails;

    const resultArray = filteredData?.map((data, index) =>
        JSON.parse(data?.leaveDetails)?.map((data, index) => data)
    );

    let obj = {};
    let arr = [];


    resultArray?.map((data) => {
        data?.map((data, index) => {
            const endDate = new Date(data?.endDate);
            endDate.setDate(endDate.getDate());
            const updatedObj = {
                ...obj, startedDate: data?.startDate,
                ReturnDate: endDate.toLocaleDateString('en-CA'),
                leaveType: data?.leaveType
            };
            arr.push(updatedObj);
            return updatedObj;
        });
    });

    const one = () => { setShouldRefetch(prevState => !prevState); };

    const showMessage = (content) => {
        message.success({
            content: content,
            duration: 2
        });
    };

    return (
        <>
            <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-1 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */} Employee Leave Tracker</h5>
            {(!employeNames[0] || leaveTrackingDetails?.length <= 0) && <div className="d-flex justify-content-center align-items-center " style={{ minHeight: "500px", width: "100%", fontWeight: "bold" }}><NoFiles isData={true} /></div>}
            {(employeNames[0] && leaveTrackingDetails) && <div className='row g-5 g-xl-8 mb-md-5 mb-xl-5 d-flex justify-content-evenly'>
                {<div className='col-md-5 col-xl-5 me-13'>
                    <div className='card h-md-auto mb-8'>
                        <div className='card-body'>
                            <h5 className='text-primary'><i className="bi bi-person-fill fs-2 me-3 svg-icon-muted text-primary"></i>Select Employee Name </h5>

                            <Autocomplete
                                disablePortal
                                fullWidth
                                color="primary"
                                id='employeeNameField'
                                options={employeNames}

                                getOptionLabel={(option) => option.toString()}
                                isOptionEqualToValue={(option, value) => option === value}
                                onInputChange={(event, value) => {
                                    setEmployeeName(value);
                                    one();
                                    setGetSelectedItem('');
                                }}
                                onChange={(event, value) => {
                                    setEmployeeName(value);
                                    one();
                                    setGetSelectedItem('');
                                }}
                                classes={{
                                    input: 'text-primary'
                                }}
                                defaultValue={employeNames[0]}
                                className='mt-md-5 mt-xl-4 mb-5'
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Employee Name"
                                    />
                                }
                            />

                            <h5 className='text-primary mb-3'><i className="bi bi-calendar-check fs-2 me-3 svg-icon-muted text-primary"></i>Select Year </h5>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id='yearField'
                                color="primary"
                                options={yearOptions}
                                getOptionLabel={(option) => option.toString()} // Ensuring the year is displayed as a string
                                isOptionEqualToValue={(option, value) => option === value} // Ensure the option matches the value
                                onInputChange={(event, value) => {
                                    setYear(parseInt(value ?? presentYear.toString()));
                                    one();
                                }}
                                onChange={(event, value) => {
                                    setYear(parseInt(value ?? presentYear.toString()));
                                    one();
                                }}
                                classes={{
                                    input: 'text-primary'
                                }}
                                defaultValue={presentYear}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required
                                        focused
                                        label="Year"
                                    />
                                }
                            />
                            <div className='mt-4'>
                                <span className="badge badge-primary fs-7 p-3 mb-2 me-2"><i className="bi bi-airplane-fill fs-2 svg-icon-muted text-white me-5"></i>Tickets Compensated: {leaveTrackingDetails[0]?.compensationTickets ? leaveTrackingDetails[0].compensationTickets : 0}</span>
                                <span className="badge badge-primary fs-7 p-3"><i className="bi bi-cash-coin fs-2 svg-icon-muted text-white me-5 "></i> Amount Compensation: {leaveTrackingDetails[0]?.compensationAmount ? leaveTrackingDetails[0].compensationAmount : 0} OMR</span>
                            </div>

                        </div>
                    </div>
                </div>}

                <Grid container lg={6} sx={{ bgcolor: '', justifyContent: 'space-around' }}>
                    {
                        leaveTrackingDetails?.map((data, index) => {
                            return (
                                <Grid item xs={11} sm={5.5} md={3.5} lg={3.5} sx={{ bgcolor: '', height: 'fit-content' }} key={index}>
                                    <Grid
                                        container className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-20 mb-5`}
                                        style={{
                                            overflow: 'hidden',
                                            backgroundColor: colours[index],
                                            backgroundImage: `url('/media/patterns/vector-1.png')`,
                                            backgroundSize: 'cover',
                                            cursor: 'pointer',
                                        }}
                                        key={data?.policytitle}
                                        onClick={() => setGetSelectedItem(data?.policytitle)}
                                    >
                                        <div className='card-header pt-2'>
                                            <div className='card-title d-flex flex-column'>
                                                <span className='fs-2x fw-bold text-white me-2 lh-1 ls-n3 text-nowrap'>{`${data?.totalcount ? data.totalcount : 0} / ${data?.availablecount}`}</span>
                                                <span className='text-white pt-5 fw-semibold fs-8 text-nowrap'>{data?.policytitle}</span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </div>}

            {(employeNames[0] && leaveTrackingDetails) && <div className='card'>
                <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed text-primary'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_1'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_1'
                            >
                                Ticket Compensation
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_1'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_1'
                            data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body'>
                                <div className='row g-5 g-xl-20 mb-2'>
                                    <div className='col-md-8 col-xl-3 me-10'>
                                        <h6 className='text-primary'><i className="bi bi-airplane-fill fs-2 svg-icon-muted text-primary me-5"></i>Number of Tickets Compensated</h6>
                                        <TextField
                                            fullWidth
                                            required
                                            defaultValue={0}
                                            id='noTickets'
                                            color='primary'
                                            focused
                                            onChange={(event) => setNoTIckets(parseInt(event.target.value))}
                                            inputProps={{
                                                className: 'text-dark fs-4'
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-8 col-xl-3'>
                                        <h6 className='text-primary'><i className="bi bi-cash-coin fs-2 svg-icon-muted text-primary me-5"></i>Amount of Tickets Compensated</h6>
                                        <TextField
                                            fullWidth
                                            required
                                            defaultValue={0}
                                            id='ticketAmt'
                                            color='primary'
                                            focused
                                            onChange={(event) => setAmt(parseInt(event.target.value))}
                                            inputProps={{
                                                className: 'text-dark fs-4'
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row g-5 g-xl-8 mb-2'>
                                    <div className='col-md-3'>
                                        <div onClick={() => {
                                            axios.post(`${BASE_URL_TWO}`, {
                                                "sp": "updEmployeeTicketCompensation",
                                                "userId": id,
                                                "employeeId": userId,
                                                "year ": year,
                                                "ticketCompensatedCount": noTickets,
                                                "ticketCompensationAmount": ticketAmt
                                            }, { headers }).then(() => {
                                                showMessage('Data updated successfully');
                                                setShouldRefetch(prevState => !prevState)
                                            }).catch(error => {
                                                console.error('Error occurred during POST request:', error);
                                                message.error(error?.response?.data?.Message ? error?.response?.data?.Message : 'Failed to update data');
                                            })
                                        }} className='btn btn-sm btn-primary mt-3' >
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='accordion-item'>
                        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                            <button
                                className='accordion-button fs-4 fw-bold collapsed text-primary'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_1_body_2'
                                aria-expanded='false'
                                aria-controls='kt_accordion_1_body_2'
                            >
                                Calendar
                            </button>
                        </h2>
                        <div
                            id='kt_accordion_1_body_2'
                            className='accordion-collapse collapse'
                            aria-labelledby='kt_accordion_1_header_2'
                            data-bs-parent='#kt_accordion_1'

                        >
                            <div className='accordion-body border-2 border-primary' id="calendarReport" >
                                <div className='row g-5 g-xl-8 mx-5 mx-xl-10' >
                                    {
                                        months.map((value) => {
                                            return (
                                                <div className='col-md-6 col-xl-4' key={value}>
                                                    <Calendar
                                                        className='m-3'
                                                        year={year > 2000 && year < 2100 ? year : presentYear}
                                                        month={value}
                                                        type={type}
                                                        employeeLeaveDetails={employeeLeaveDetails}
                                                        dateDetails={arr}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <button onClick={downloadReport} className="btn btn-primary mt-4 mb-4 ms-5">Download Calendar Report as PDF</button>
                        </div>

                    </div>
                </div>
            </div>}
        </>
    );
};

const LeaveTracker = () => {
    return (
        <>
            <LeaveTrackerPage />
        </>
    );
};

export { LeaveTracker };
