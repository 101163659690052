import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import axios from 'axios';
import { message } from "antd";
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import PopUp from '../../../components/Modal/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { KTIcon } from '../../../../_metronic/helpers';
import CreatePosition from './CreatePosition';
import EditPosition from './EditPosition';

export default function Position() {
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');

    // States
    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null); // NEW
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [show, setShow] = useState(false);
    const [actionStatus, setActionStatus] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [visible,setVisible] = useState(false);
    const [editVisible,setEditVisible] = useState(false);

    // Fetch Companies
    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get(`${BASE_URL}getCompanies`);
                setCompanyList(response?.data?.Data || []);
            } catch (error) {
                message.error("Failed to fetch companies");
            }
        };
        fetchCompanies();
    }, []);

    // Fetch Departments based on Selected Company
    useEffect(() => {
        const fetchDepartments = async () => {
            if (!selectedCompany) {
                setDepartmentList([]);
                setDivisionList([]);
                setPositionList([]);
                return;
            }
            try {
                const response = await axios.get(`${BASE_URL}getDepartmentsByCompanyId&companyId=${selectedCompany}`);
                setDepartmentList(response?.data?.Data || []);
            } catch (error) {
                message.error("Failed to fetch departments");
            }
        };
        fetchDepartments();
    }, [selectedCompany]);

    // Fetch Divisions based on Selected Department
    useEffect(() => {
        const fetchDivisions = async () => {
            if (!selectedDepartment) {
                setDivisionList([]);
                setPositionList([]);
                return;
            }
            try {
                const response = await axios.get(`${BASE_URL}getDivisionsByDeptId&deptId=${selectedDepartment}`);
                setDivisionList(response?.data?.Data || []);
            } catch (error) {
                message.error("Failed to fetch divisions");
            }
        };
        fetchDivisions();
    }, [selectedDepartment]);

    // Fetch Positions based on Selected Division
    useEffect(() => {
        const fetchPositions = async () => {
            if (!selectedDivision) {
                setPositionList([]);
                return;
            }
            try {
                const response = await axios.get(`${BASE_URL}getPositionsByDivisionId&divisionId=${selectedDivision}`);
                setPositionList(response?.data?.Data || []);
            } catch (error) {
                message.error("Failed to fetch positions");
            }
        };
        fetchPositions();
    }, [selectedDivision, shouldRefetch]);

    const companyOptions = companyList.map((item) => ({
        label: item.companyName,
        id: item.companyId,
    }));

    const departmentOptions = departmentList.map((item) => ({
        label: item.deptName,
        id: item.deptId,
    }));

    const divisionOptions = divisionList.map((item) => ({
        label: item.divisionName,
        id: item.divisionId,
    }));

    const handleDeletePosition = () => {
        axios
            .post(
                `${BASE_URL_TWO}`,
                {
                    sp: "delPosition",
                    userId,
                    positionId: deleteId,
                },
                {
                    headers: {
                        token,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(() => {
                setActionStatus(false);
                setShow(false)
                message.destroy();
                message.success("Successfully deleted");
                setShouldRefetch((prev) => !prev);
            })
            .catch(() => {
                setActionStatus(false);
                message.destroy();
                message.error("This Position Can't Delete");
            });
    };

    useEffect(() => {
        if (actionStatus && deleteId) {
            handleDeletePosition();
        }
    }, [actionStatus, deleteId])

    return (
        <>
            <PopUp
                show={show}
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Position"
                message="Are you sure you want to delete?"
                btnName="Delete"
            />

            <h5 className="text-black-50 mb-10">
                <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Positions
            </h5>

            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="col-md-8 row">
                    <Autocomplete
                        disablePortal
                        id="companyField"
                        color="primary"
                        options={companyOptions}
                        value={companyOptions.find((opt) => opt.id === selectedCompany) || null}
                        onChange={(event, value) => {
                            setSelectedCompany(value?.id || null);
                            setSelectedDepartment(null);
                            setSelectedDivision(null);
                            setPositionList([]);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Company" required focused placeholder='Select a Company'/>
                        )}
                        className="col-4"
                    />
                    <Autocomplete
                        disablePortal
                        id="departmentField"
                        color="primary"
                        options={departmentOptions}
                        value={departmentOptions.find((opt) => opt.id === selectedDepartment) || null}
                        onChange={(event, value) => {
                            setSelectedDepartment(value?.id || null);
                            setSelectedDivision(null);
                            setPositionList([]);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Department" required focused placeholder='Select a Department'/>
                        )}
                        className="col-4"
                    />
                    <Autocomplete
                        disablePortal
                        id="divisionField"
                        color="primary"
                        options={divisionOptions}
                        value={divisionOptions.find((opt) => opt.id === selectedDivision) || null}
                        onChange={(event, value) => {
                            setSelectedDivision(value?.id || null);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Division" required focused placeholder='Select a Division'/>
                        )}
                        className="col-4"
                    />
                </div>
                <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <span
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        onClick={()=>setVisible(true)}
                        >
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </span>
                </div>
            </div>

            <CreatePosition visible={visible} setShouldRefetch={setShouldRefetch} shouldRefetch={shouldRefetch} onClose={()=>setVisible(false)} companyList={companyList} />
            <EditPosition
                visible = {editVisible}
                onClose= {()=>setEditVisible(false)}
                setShouldRefetch={setShouldRefetch} 
                shouldRefetch={shouldRefetch}
                selectedPosition={selectedPosition}
                selectedCompany={companyList?.filter(item => item?.companyId == selectedCompany)[0]}
                selectedDepartment={departmentList?.filter(item => item?.deptId == selectedDepartment)[0]}
                selectedDivision={divisionList?.filter(item => item?.divisionId == selectedDivision)[0]}
            />

            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                <thead>
                    <tr className="fw-bold fs-5 bg-primary text-white">
                        <th>#</th>
                        <th>Position</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {positionList.length > 0 ? (
                        positionList.map((position, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{position.positionName}</td>
                                <td>
                                    <Tooltip title="Delete" arrow>
                                        <DeleteIcon
                                            onClick={() => { setDeleteId(position?.positionId); setShow(true) }}
                                            style={{ cursor: "pointer", color: 'rgb(18 54 125)' }}
                                            title="Delete"
                                        />
                                    </Tooltip>
                                    <span
                                        onClick={() =>{ setSelectedPosition(position);setEditVisible(true)}} // Pass selected position
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                        title="Edit"
                                        >
                                        <Tooltip title="Edit" arrow>
                                            <ModeEditIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                    ml: 2
                                                }}
                                                className='text-primary'
                                            />
                                        </Tooltip>
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={3} className="text-center">
                                No Records Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}
