import { useFormik } from 'formik';
import React, { useState ,useRef} from 'react';
import { message } from "antd";
import axios from 'axios';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useFetchData from '../../../hooks/getData';
import { BASE_URL_TWO } from '../../../../utils';
import { KTSVG } from '../../../../_metronic/helpers';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
   // fileName: Yup.string().required('File Name is  required'),
    //folderName: Yup.string().required('Folder Name is  required'),
     fileN: Yup.string().required('File Name is  required'),
     file: Yup.mixed().required("Document is required"),
})

export default function CreateFrom({ paths,onFormSubmit }) {

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const [folderName, setFolderName] = useState('/')

    const [newFolder, setNewFolder] = useState(false)
    
    const navigate = useNavigate()

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    // let final = paths + '/' + 

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'New File Added Successfully',
            duration: 1
        });
    };

    const initialValues = {
        //employeeName: '',
       // fileN: '',
       // file:null,
       // folderName: '',
        fileN: '',
        fileName : ''
    }

    const [base64String, setBase64String] = useState('');

    const fileInputRef = useRef(null);

    // console.log("folderName", folderName);

    const [one, setOne] = useState('')
    const [fileN, setFileN] = useState()

    //let final = paths + one

    let final = paths


    const handleClear = () => {
        setBase64String('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };

   

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {


            document.getElementById('kt_modal_2_close').click();

            axios.post(`${BASE_URL_TWO}Default.aspx`, {
                sp: 'saveEmployeeFileForEmployee',
                "pathToSave": final + one,
                "staffId": id,
                "fileContent": base64String,
                "Name": fileN
            }, { headers })
                .then((response) => {
                    showMessage();
                    setShouldRefetch(prevState => !prevState);
                    //console.log('response' + JSON.stringify(response));
                    onFormSubmit(response.data);
                    resetForm();
                    setBase64String('');
                    handleClear();
                })
                .catch(error => {
                    setStatus({ success: false });
                    if (error.response?.status === 401) {
                        Object.keys(Cookies.get()).forEach((cookieName) => {
                          Cookies.remove(cookieName, { path: '/' });
                        });
                        navigate('/error/login')
                      } else {
                        message.destroy();
                        message.error(error?.Message || "Something went Wrong");
                      }
                    console.error('Error occurred during POST request:', error);
                    // Handle error state, display error message, etc.
                    // For example:
                })
                .finally(() => {
                    // This block will be executed regardless of success or failure
                    // You can do cleanup tasks here if needed
                    setSubmitting(false);
                });
        },
    });


    const handleFileInputChange = (event) => {
        const file = event.target.files ? event.target.files[0] : '';
        formik.setFieldValue('file', file);
        
        const reader = new FileReader();

        reader.onload = () => {
            const base64 = reader.result;
            setBase64String(base64);
        };

        reader.readAsDataURL(file);
    };


   


    return (
        <div className="modal fade" id="kt_modal_2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

                <div className="modal-dialog modal-lg" role="document">
                    <form onSubmit={formik.handleSubmit} >

                        <div className="modal-content">
                            <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                                <h5 className="modal-title text-primary">Enter Details</h5>
                                <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                                </button>
                            </div>

                            <h4 className="modal-title text-primary" style={{ marginLeft: '20px', marginTop: "10px" }} >Current Folder : {final ? final : ''}</h4>

                            <div className="modal-body">
                                <div className="row g-3">



                                    <div style={{ display: newFolder ? "" : 'none' }} className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">folder Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='newFolderName'
                                            // value={formik.values.placeholder}
                                            onChange={(e) => setNewFolder(e.target.value)}

                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.newFolderName}</span>
                                    </div>


                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Folder Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='fileName'
                                            value={formik.values.fileName}
                                            onChange={(e) => {
                                                setOne(e.target.value);
                                                formik.setFieldValue('fileName', e.target.value);
                                            }}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.fileName}</span>
                                    </div>


                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Browse File</label>
                                        <input
                                            type="file"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='file'
                                            // value={formik.values.file}
                                            onChange={handleFileInputChange}
                                            ref={fileInputRef} 
                                            
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.file}</span>
                                    </div>


                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">File Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='fileN'
                                            // value={formik.values.file}
                                            // onChange={handleFileInputChange}
                                            value={formik.values.fileN}
                                           
                                            onChange={(e) => {
                                                setFileN(e.target.value)
                                                formik.setFieldValue('fileN', e.target.value);
                                            }}
                                           
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.fileN}</span>
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" id="kt_modal_2_close">
                                    Close
                                </button>
                                <button type="submit" onClick={formik.handleSubmit} className="btn btn-primary">
                                    Add
                                </button>
                            </div>


                        </div>

                    </form>
                </div>
        </div >
    )
}
