import { FC, useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material';
import { Archive, Cookie, Delete, Edit } from '@mui/icons-material';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import useFetchCommon from '../../../hooks/getCommonData';
import Cookies from 'js-cookie';
import { BASE_URL_TWO, BASE_URL } from '../../../../utils';
import axios from 'axios';
import CommonDataRefetch from '../../../hooks/CommonDataRefetch';
import { message } from "antd";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import RichTextEditor from '../../../../_metronic/helpers/components/RichTextEditor';
import PopUp from '../../../components/Modal/Modal';
import NoFiles from '../../../../_metronic/helpers/components/NoFiles';


const CompanyOverallPolicies = () => {

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [companyDocs, setCompanyDocs] = useState([]);
  const [show, setShow] = useState(false);
  const [actionStatus, setActionStatus] = useState(false);
  const [deleteId, setDeleteId] = useState()

  const fetchOnece = () => setShouldRefetch(prevState => !prevState)

  const id = Cookies.get('userId');
  const token = Cookies.get("token");
  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const { fetchedData } = CommonDataRefetch(`${BASE_URL}getleaveGeneralTermsConditions`, { id, token }, [shouldRefetch])
  const [editedText, setEditedText] = useState(fetchedData?.[0]?.contentText)

  useEffect(() => {
    axios.get(`${BASE_URL}getCompanyDocuments&userId=${id}`, { headers }).then((response) => {
      setCompanyDocs(response?.data?.Data)
    })
  }, [])

  useEffect(() => {
    setEditedText(fetchedData?.[0]?.contentText)
  }, [fetchedData])


  const [descr, setDescr] = useState();
  const validationSchema = Yup.object().shape({
    docTitle: Yup.string().required('Document Name is required'),
    docFile: Yup.mixed().required('Document file is required'),
    docImage: Yup.mixed().required('Document image is required'),
    expiryDate: Yup.date().required('Expiry date is required'),
  });

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        // Check and append the correct MIME type if needed
        if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          resolve(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64String.split(',')[1]}`);
        } else {
          resolve(base64String);
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file); // Convert file to base64 string
    });
  };

  const handleAddDocument = async (values, { resetForm }) => {

    try {
      const docFileBase64 = await convertFileToBase64(values.docFile);
      const docImageBase64 = await convertFileToBase64(values.docImage);

      await axios.post(`${BASE_URL_TWO}`, {
        "sp": 'insCompanyDocuments',
        "userId": id,
        "docTitle": values.docTitle,
        "documentUrl": docFileBase64,
        "filePath": docImageBase64,
        "expiryDate": values.expiryDate
      }, { headers });

      message.success("Successfully created");
      resetForm();
      fetchOnece()
      setTimeout(() => {
        window.location.reload();
      }, 1000)
      // Close modal programmatically if needed
      // document.getElementById('kt_modal_1').classList.remove('show');
      // document.querySelector('.modal-backdrop').remove();

    } catch (error) {
      message.error("Failed to create");
    }
  };

  useEffect(() => {
    if (actionStatus && deleteId) {
      axios.post(`${BASE_URL_TWO}`, {
        "sp": "delCompanyDocuments",
        "userId": parseInt(id),
        "documentId": deleteId
      }, { headers }).then(() => {
        message.success("Successfully Deleted");
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      })
      fetchOnece()
    }
  }, [actionStatus, deleteId])

  const handleDelete = async (docId) => {
    setShow(true);
    setDeleteId(docId);
  }

  const handleDocument = async (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const disallowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
      ];

      if (disallowedTypes.includes(file.type)) {
        alert("This file type is not allowed. Please select a Pdf file");
        setFieldValue("docFile", '');
        return;
      } else {
        setFieldValue("docFile", event.currentTarget.files[0]);
      }
    }
  }

  const handleImage = (event, setFieldValue) => {
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const file = event.currentTarget.files[0];
    if (file) {
      if (!allowedImageTypes.includes(file.type)) {
        alert('The selected file must be an image');
        setFieldValue("docImage", '');
        return;
      }
      setFieldValue("docImage", event.currentTarget.files[0]);
    }
  }

  const showMessage = () => {
    message.destroy();
    message.success({
      content: 'Added new Department',
      duration: 1
    });
  };

  return <>
    <PopUp
      show={show}
      setShow={setShow}
      setActionStatus={setActionStatus}
      title="Deleted Company Document"
      message="Are you sure you want to delete"
      btnName="Delete"
    />
    <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */}  View / Add / Delete Company Documents</h5>
    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
      <button type="button"
        className="btn btn-primary btn-sm mb-5"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_1"
      >
        <i className="bi bi-plus-circle fs-4 px-3 svg-icon-muted text-white"></i>
        Add
      </button>
    </div>

    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div style={{ justifyContent: 'space-between' }} className="modal-header bg-primary-subtle">
            <h5 className="modal-title text-primary">Add New Document</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>

          <div className="modal-body">
            <Formik
              initialValues={{ docTitle: '', docFile: null, docImage: null, expiryDate: '' }}
              validationSchema={validationSchema}
              onSubmit={handleAddDocument}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className='row mb-1'>
                    <label className='col-lg-5 col-form-label fw-bold fs-6'>Document Name</label>
                    <div className='col-lg-6 fv-row'>
                      <Field
                        type='text'
                        name='docTitle'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                      />
                      <ErrorMessage name="docTitle" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className='row mb-1'>
                    <label className='col-lg-5 col-form-label fw-bold fs-6'>Upload Document</label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type="file"
                        name="docFile"
                        className='form-control-file form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                        onChange={(event) => {
                          handleDocument(event, setFieldValue)
                        }}
                      />
                      <ErrorMessage name="docFile" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className='row mb-1'>
                    <label className='col-lg-5 col-form-label fw-bold fs-6'>Upload background image(200px x 200px)</label>
                    <div className='col-lg-6 fv-row'>
                      <input
                        type="file"
                        name="docImage"
                        className='form-control-file form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                        onChange={(event) => {
                          handleImage(event, setFieldValue)
                        }}
                      />
                      <ErrorMessage name="docImage" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className='row mb-1'>
                    <label className='col-lg-5 col-form-label fw-bold fs-6'>Expiry Date</label>
                    <div className='col-lg-6 fv-row'>
                      <Field
                        type="date"
                        name="expiryDate"
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                        min={new Date().toLocaleDateString('en-CA')}
                      />
                      <ErrorMessage name="expiryDate" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      Add
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>


    <div className="modal fade" tabIndex={-1} id="kt_modal_2">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header bg-primary-subtle">
            <h5 className="modal-title text-primary"> <i className="bi bi-plus-lg fs-2 px-3 svg-icon-muted text-primary"></i>   Add New Document </h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>

          </div>

          <div className="modal-body">

            <div className='row mb-1'>
              <label className='col-lg-5 col-form-label fw-bold fs-6'>Document Name</label>
              <div className='col-lg-6 fv-row'>
                <textarea
                  type='text'
                  rows={3}
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 border border-primary'
                  placeholder='Tearms and conditions'
                  onChange={(e) => setDescr(e.target.value)}
                />
              </div>
            </div>

          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() => {
                axios?.post(`${BASE_URL_TWO}`, {
                  sp: "insTermsAndConditions",
                  contentText: descr,
                  userId: id
                }, { headers }).then(() => {
                  message.success("Successfully Created")
                  fetchOnece()
                })
              }}
              type="button" className="btn btn-primary">
              Add
            </button>
          </div>
        </div>
      </div>
    </div >


    {companyDocs?.length > 0 && <div className="row mb-10">
      {/* Visa Application document */}

      {companyDocs.map((item, index) => (<div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl(`${BASE_URL_TWO}${item?.filePath}`)}
                alt=""
                className="w-100 rounded object-cover"
                height="245px"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href={`${BASE_URL_TWO}${item?.documentUrl}`}
                target="_blank"
                className="btn btn-icon btn-light-success btn-active-success btn-shadow ms-2 "><i className="bi bi-file-earmark-ruled fs-1"></i></a>
              <div onClick={() => handleDelete(item?.companyDocumentId)}
                className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'>{item?.docTitle}</p>
      </div>))}

      {/* Employee Hierarchy */}
      {/* <div className="col-lg-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl('/media/stock/600x400/Company_structure.jpg')}
                alt=""
                className="w-100 rounded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href="https://me-qr.com/gIlvcDfL" className="btn btn-icon btn-light-danger btn-active-danger btn-shadow ms-2 "><i className="bi bi-file-earmark-pdf fs-1"></i></a>
              <div className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'> Employee Hierarchy </p>
      </div> */}
      {/* Overall Policies */}
      {/* <div className="col-lg-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl('/media/stock/600x400/Company_Policy.jpg')}
                alt=""
                className="w-100 rounded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href="https://me-qr.com/gIlvcDfL" className="btn btn-icon btn-light-danger btn-active-danger btn-shadow ms-2 "><i className="bi bi-file-earmark-pdf fs-1"></i></a>
              <div className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'> Overall Policies </p>
      </div> */}
      {/* Car Insurance documents */}
      {/* <div className="col-lg-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl('/media/stock/600x400/Car_insurance.jpg')}
                alt=""
                className="w-100 rounded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href="https://me-qr.com/gIlvcDfL" className="btn btn-icon btn-light-danger btn-active-danger btn-shadow ms-2 "><i className="bi bi-file-earmark-pdf fs-1"></i></a>
              <div className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'> Car Insurance</p>
      </div> */}
      {/* Health Insurance Benefits documents */}
      {/* <div className="col-lg-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl('/media/stock/600x400/Health_insurance.jpg')}
                alt=""
                className="w-100 rounded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href="https://me-qr.com/gIlvcDfL" className="btn btn-icon btn-light-danger btn-active-danger btn-shadow ms-2 "><i className="bi bi-file-earmark-pdf fs-1"></i></a>
              <div className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'> Health Insurance Benefits </p>
      </div> */}
      {/* Health Insurance Providers documents */}
      {/* <div className="col-lg-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl('/media/stock/600x400/Health_Insurance_Providers.jpg')}
                alt=""
                className="w-100 rounded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href="https://me-qr.com/gIlvcDfL" className="btn btn-icon btn-light-success btn-active-success btn-shadow ms-2 "><i className="bi bi-file-earmark-ruled fs-1"></i></a>
              <div className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'> Health Insurance Providers </p>
      </div> */}

      {/* KPI documents */}
      {/* <div className="col-lg-3 mb-5">
        <div className="card card-custom overlay overflow-hidden">
          <div className="card-body p-0">
            <div className="overlay-wrapper">
              <img
                src={toAbsoluteUrl('/media/stock/600x400/KPI.jpg')}
                alt=""
                className="w-100 rounded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-50">
              <a href="https://me-qr.com/gIlvcDfL" className="btn btn-icon btn-light-danger btn-active-danger btn-shadow ms-2 "><i className="bi bi-file-earmark-pdf fs-1"></i></a>
              <div className="btn btn-icon btn-light-primary btn-active-light-danger ms-2 bg-white" data-bs-dismiss="modal" aria-label="Close">
                <KTSVG
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
          </div>
        </div>
        <p className='text-primary fw-bold px-5'> KPI Documents </p>
      </div> */}

    </div>}

    {companyDocs?.length <=0  && <div className=' d-flex justify-content-center align-items-center flex-grow-1'>
        <div className='d-flex justify-content-center align-items-center flex-column flex-grow-1'style={{ minHeight: '300px' }}>
            <img
            src='https://cdn-icons-png.flaticon.com/512/7466/7466140.png'
            alt="icon"
            style={{ width:"100%" ,maxWidth: '80px', height: 'auto' }}
            />
            
            { <h6 style={{ background: '' }} className='container mx-auto text-center my-3 text-2xl'>
                No Records Found
            </h6> }
        </div>
    </div>}



    {/* Edit Terms & Conditions */}
    <h1 className="text-primary mb-10"><i className="bi bi-shield-exclamation fs-2hx me-3 svg-icon-muted text-primary"></i>  {/* icon  */} Edit The Terms & Conditions</h1>
    {/* <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
      <i className="bi-shield-fill-exclamation fs-1 text-primary me-4">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    </div> */}

    {/* Terms and conditions Table */}
    <div className="" style={{ width: "100%" }}>
      <RichTextEditor
        name="editTerms"
        className="datafield p-5 w-100 d-flex flex-column"
        onChange={(value) => {
          setEditedText(value);
        }}
        suppressContentEditableWarning={true}
        value={editedText}
      />
      <button className="btn btn-primary mt-3" onClick={async () => {
        const response = axios.post(`${BASE_URL_TWO}`, {
          "sp": "updleaveGeneralTermsConditions",
          "userId": parseInt(id),
          "contentText": editedText
        }, { headers }).then(() => {
          message.success("Successfully Updated")
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }).catch((error) => {
          console.error("Error updating terms and conditions:", error);
          message.error("Failed to update.");
        })
      }}>Update</button>
    </div>

  </>

}
export { CompanyOverallPolicies }