import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const CommonDataRefetch = (url,bodyDetails={}, dependencies = []) => {

    const [fetchedData, setFetchedData] = useState();
    const navigate = useNavigate()
    let response = null;
    useEffect(() => {
        const fetchData = async () => {

            try {
                if(bodyDetails?.id){
                    const headers = {
                        'token': bodyDetails?.token,
                        'Content-Type': 'application/json'
                    };
                     response = await axios.get(`${url}&userId=${bodyDetails?.id}`,{headers});
                }else{
                    response = await axios.get(url);
                }
                setFetchedData(response?.data?.Data);
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  } else {
                    message.destroy();
                    message.error("Something went wrong")
                  }
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, dependencies);


    return { fetchedData, refetch: fetchedData };
};

export default CommonDataRefetch;