import React, { useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL_TWO } from '../../../utils';
import Cookies from 'js-cookie';
import { message, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

interface EmployeeExpenseFormProps {
    onAdd: () => void; // onAdd is a function that takes no parameters and returns void
}

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required('Customer name is required'),
    salary: Yup.string().required('Slary is required'),
    airTicket: Yup.string().required('Air ticket is required'),
    airTicketForFamily: Yup.string().required('Air ticket for family is required'),
    fuelBased: Yup.string().required('Fuel Base required'),
    CompanyVehcle: Yup.string().required('Compeny vehcle name is required'),
    plateNumber: Yup.string().required('vehcle plate number is required'),
    carInsurance: Yup.string().required('vehcle insurance is required'),
    carRenewalFee: Yup.string().required('vehcle renewal is required'),
    medicalInsurance: Yup.string().required('Medical insurance is required'),
    salesCommission: Yup.string().required('Sales commission is required'),
    visaExpence: Yup.string().required('Visa expence is required'),
    visaExpenceForFamily: Yup.string().required('Visa expence for family is required'),
    gratuity: Yup.string().required('Gratuity is required'),
    pasi: Yup.string().required('PASI is required'),
    tamimahExperience: Yup.string().required('Tamimah experience is required'),
})

function EmployeeExpenseForm({ visible, onClose,month,year,setShouldRefetch,editData, shouldRefetch }) {
    const navigate = useNavigate()
    const validationSchema = Yup.object().shape({
        employeeName: Yup.string(),
        salary: Yup.string(),
        airTicket: Yup.string().required('Air ticket is required'),
        airTicketForFamily: Yup.string().required('Air ticket for family is required'),
        fuelBased: Yup.string().required('Fuel Base required'),
        CompanyVehcle: Yup.string(),
        plateNumber: Yup.string(),
        carInsurance: Yup.string().required('vehcle insurance is required'),
        carRenewalFee: Yup.string().required('vehcle renewal is required'),
        medicalInsurance: Yup.string(),
        salesCommission: Yup.string().required('Sales commission is required'),
        visaExpence: Yup.string().required('Visa expence is required'),
        visaExpenceForFamily: Yup.string().required('Visa expence for family is required'),
        gratuity: Yup.string(),
        pasi: Yup.string().required('PASI is required'),
        tamimahExperience: Yup.string(),
    })


    let initialValues = {
        employeeName: editData?.employeeName ?  editData?.employeeName :'',
        salary: editData?.totalSalary ? editData?.totalSalary :'',
        airTicket: editData?.airTicket ? editData?.airTicket :'',
        airTicketForFamily: editData?.airTicketFamily ? editData?.airTicketFamily:'',
        fuelBased: editData?.fuelbasedReimbursement ? editData?.fuelbasedReimbursement :'',
        CompanyVehcle: editData?.vehicleName ? editData?.vehicleName :'',
        plateNumber: editData?.plate? editData?.plate :'',
        carInsurance: editData?.carInsurance ? editData?.carInsurance :'',
        carRenewalFee: editData?.carRenewalFee ? editData?.carRenewalFee :'',
        medicalInsurance: editData?.medicalInsurance ? editData?.medicalInsurance :'',
        salesCommission: editData?.commissionSales ? editData?.commissionSales :'',
        visaExpence: editData?.visaExpense ? editData?.visaExpense :'',
        visaExpenceForFamily:editData?.visaExpenseFamily ? editData?.visaExpenseFamily : '',
        gratuity: editData?.GratuityAmount ? editData?.GratuityAmount: '',
        pasi: editData?.pasi ? editData?.pasi :'',
        tamimahExperience: editData?.TamimahWorkExp ? editData?.TamimahWorkExp :''
    }

    useEffect(()=>{
        if(editData){
            formik.setFieldValue('employeeName',editData?.employeeName ?  editData?.employeeName :'')
            formik.setFieldValue('salary',editData?.totalSalary ? editData?.totalSalary :'')
            formik.setFieldValue('airTicket',editData?.airTicket ? editData?.airTicket :'')
            formik.setFieldValue('airTicketForFamily', editData?.airTicketFamily ? editData?.airTicketFamily:'')
            formik.setFieldValue('fuelBased',editData?.fuelbasedReimbursement ? editData?.fuelbasedReimbursement :'')
            formik.setFieldValue('CompanyVehcle',editData?.vehicleName ? editData?.vehicleName :'');
            formik.setFieldValue('plateNumber',editData?.plate? editData?.plate :'')
            formik.setFieldValue('carInsurance',editData?.carInsurance ? editData?.carInsurance :'');
            formik.setFieldValue('carRenewalFee',editData?.carRenewalFee ? editData?.carRenewalFee :'')
            formik.setFieldValue('medicalInsurance',editData?.medicalInsurance ? editData?.medicalInsurance :'');
            formik.setFieldValue('salesCommission',editData?.commissionSales ? editData?.commissionSales :'')
            formik.setFieldValue('visaExpence',editData?.visaExpense ? editData?.visaExpense :'')
            formik.setFieldValue('visaExpenceForFamily',editData?.visaExpenseFamily ? editData?.visaExpenseFamily : '')
            formik.setFieldValue('gratuity',editData?.GratuityAmount ? editData?.GratuityAmount: '');
            formik.setFieldValue('pasi',editData?.pasi ? editData?.pasi :'')
            formik.setFieldValue('tamimahExperience', editData?.TamimahWorkExp ? editData?.TamimahWorkExp :'')

        }
    },[editData])

    const id = Cookies.get('userId');
    const token = Cookies.get('token');

    const headers = {
        token: token,
        "Content-Type": "application/json",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnBlur:false,
        validateOnChange:false,
        validateOnMount:false,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                await axios.post(
                    `${BASE_URL_TWO}`,
                    {
                        sp: "insEmployeeExpense",
                        userId: id,
                        employeeId: editData?.employeeId,
                        airTicket: values?.airTicket,
                        airTicketFamily: values?.airTicketForFamily,
                        fuelbasedReimbursement: values?.fuelBased,
                        carInsurance: values?.carInsurance,
                        carRenewalFee: values?.carRenewalFee,
                        commissionSales: values?.salesCommission,
                        visaExpense: values?.visaExpence,
                        visaExpenseFamily: values?.visaExpenceForFamily,
                        pasi: values?.pasi,
                        month: month,
                        year: year,
                    },
                    { headers }
                )
                onClose();
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  } else {
                    message.destroy();
                    message.error("Something went wrong")
                  }
            }
        }
    });

    return (
        <Modal
            title="Employee Expense Details"
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose
            centered
            width={600} // Adjust modal width if needed
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form action="" onSubmit={formik.handleSubmit}>
                <div >
                    <div >
                        <div className="row g-3">
                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Employee Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='employeeName'
                                    autoComplete='off'
                                    value={formik.values.employeeName}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.employeeName}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Salary</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='salary'
                                    autoComplete='off'
                                    value={formik.values.salary}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.salary}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Air Ticket</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='airTicket'
                                    autoComplete='off'
                                    value={formik.values.airTicket}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.airTicket}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Air Ticket for family</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='airTicketForFamily'
                                    autoComplete='off'
                                    value={formik.values.airTicketForFamily}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.airTicketForFamily}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Fuel based on reimbursement</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='fuelBased'
                                    autoComplete='off'
                                    value={formik.values.fuelBased}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.fuelBased}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Company vehicle</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='CompanyVehcle'
                                    autoComplete='off'
                                    value={formik.values.CompanyVehcle}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.CompanyVehcle}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Plate number</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='plateNumber'
                                    autoComplete='off'
                                    value={formik.values.plateNumber}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.plateNumber}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Car insurance</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='carInsurance'
                                    autoComplete='off'
                                    value={formik.values.carInsurance}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.carInsurance}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Car renewal fee</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='carRenewalFee'
                                    autoComplete='off'
                                    value={formik.values.carRenewalFee}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.carRenewalFee}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Medical insurance</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='medicalInsurance'
                                    autoComplete='off'
                                    value={formik.values.medicalInsurance}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.medicalInsurance}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Commission for sales</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='salesCommission'
                                    autoComplete='off'
                                    value={formik.values.salesCommission}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.salesCommission}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Visa expense</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='visaExpence'
                                    autoComplete='off'
                                    value={formik.values.visaExpence}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.visaExpence}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Visa expense for family</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='visaExpenceForFamily'
                                    autoComplete='off'
                                    value={formik.values.visaExpenceForFamily}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.visaExpenceForFamily}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Gratuity</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='gratuity'
                                    autoComplete='off'
                                    value={formik.values.gratuity}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.gratuity}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">PASI</label>
                                <input
                                    type="number"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='pasi'
                                    autoComplete='off'
                                    value={formik.values.pasi}
                                    onChange={formik.handleChange}
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.pasi}</span>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold fs-7 text-light-emphasi">Tamimah Experince</label>
                                <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                    placeholder=""
                                    name='tamimahExperience'
                                    autoComplete='off'
                                    value={formik.values.tamimahExperience}
                                    onChange={formik.handleChange}
                                    disabled
                                />
                                <span style={{ color: 'red' }} role='alert'>{formik.errors.tamimahExperience}</span>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer mt-2">
                        <button type="button" className="btn btn-light" onClick={onClose}>
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary ms-3">
                            Add
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default EmployeeExpenseForm;
