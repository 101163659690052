import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL_TWO } from '../../../utils';
import Cookies from 'js-cookie';
import { message } from "antd";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    otherAllowance: Yup.string().required('Allowance amount is required'),
    overTimeSalary: Yup.string().required('Overtime amount is required'),
    appraisal: Yup.string().required('Appraisal amount is required'),
    deductionAmount: Yup.string().required('Deduction amount is required'),
    incentiveAmount: Yup.string().required('Incentive amount is required'),
    comments: Yup.string().required('Comment is required'),
});

export default function PayRollEditForm({ selectedData, setEditData, month }) {
    selectedData = selectedData?.length > 0 ? selectedData[0] : '';

    const formattedDate = moment(selectedData?.manPowerEndDtae, "M/D/YYYY").format("YYYY-MM-DD");
    const navigate = useNavigate()

    const initialValues = {
        otherAllowance: selectedData?.otherAllowance || '',
        overTimeSalary: selectedData?.overtimeSalary || '',
        appraisal: selectedData?.appraisal || '',
        deductionAmount: selectedData?.deductionAmount || '',
        incentiveAmount: selectedData?.incentiveAmount || '',
        comments: selectedData?.comments || '',
    };

    const showMessage = () => {
        message.success({
            content: 'Successfully added new payroll',
            duration: 1,
        });
    };

    const token = Cookies.get("token");
    const id = Cookies.get("userId");

    const headers = {
        token,
        'Content-Type': 'application/json',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            console.log("Submitted values:", values); // Debugging values

            try {
                await axios.post(
                    `${BASE_URL_TWO}`,
                    {
                        sp: 'updEmployeePayRoll',
                        empPayRollId: selectedData?.payrollId,
                        userId: id,
                        month,
                        otherAllowance: values?.otherAllowance,
                        overTimeSalary: values?.overTimeSalary,
                        appraisal: values?.appraisal,
                        deductionAmount: values?.deductionAmount,
                        incentiveAmount: values?.incentiveAmount,
                        comments: values?.comments,
                        employeeId: selectedData?.employeeId,
                    },
                    { headers }
                );

                showMessage();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                        Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                } else {
                    message.destroy();
                    message.error("Something went wrong")
                }
            }
        },
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Employee Payroll Details</h5>
                            <button
                                type="button"
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setEditData({})}
                            >
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-3">
                                {[
                                    { name: 'otherAllowance', label: 'Other Allowance' },
                                    { name: 'overTimeSalary', label: 'Overtime Salary' },
                                    { name: 'appraisal', label: 'Appraisal' },
                                    { name: 'deductionAmount', label: 'Deduction Amount' },
                                    { name: 'incentiveAmount', label: 'Incentive Amount' },
                                    { name: 'comments', label: 'Comments', type: 'text' },
                                ].map((field) => (
                                    <div className="col-md-6" key={field.name}>
                                        <label className="form-label fw-bold fs-8 text-light-emphasi">{field.label}</label>
                                        <input
                                            type={field.type || 'number'}
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name={field.name}
                                            autoComplete="off"
                                            value={formik.values[field.name]}
                                            onChange={formik.handleChange}
                                        />
                                        <span style={{ color: 'red' }} role="alert">
                                            {formik.errors[field.name]}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={() => setEditData({})}
                            >
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
