import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { KTSVG } from '../../../../_metronic/helpers';
import { message, Modal } from "antd";
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    companyId: Yup.string().required('Company is required'),
    departmentId: Yup.string().required('Department is required'),
    divisionId: Yup.string().required('Division is required'),
    name: Yup.string().required('Position Name is required'),
});

export default function CreatePosition({ visible,onClose,companyList,setShouldRefetch,shouldRefetch }) {
    const [departments, setDepartments] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const userId = Cookies.get('userId');
    const token = Cookies.get("token");

    const headers = {
        'token': token,
        'Content-Type': 'application/json',
    };

    const showMessage = () => {
        message.success({
            content: 'Created new Position',
            duration: 1,
        });
    };

    const showError = (msg = "Failed to create the position") => {
        message.error({
            content: msg,
            duration: 2,
        });
    };

    const fetchDepartments = async (companyId) => {
        if (!companyId) {
            setDepartments([]);
            return;
        }
        try {
            const response = await axios.get(`${BASE_URL}getDepartmentsByCompanyId&companyId=${companyId}`);
            setDepartments(response.data.Data || []);
        } catch (error) {
            message.error("Failed to fetch departments");
        }
    };

    const fetchDivisions = async (departmentId) => {
        if (!departmentId) {
            setDivisions([]);
            return;
        }
        try {
            const response = await axios.get(`${BASE_URL}getDivisionsByDeptId&deptId=${departmentId}`);
            setDivisions(response.data.Data || []);
        } catch (error) {
            message.error("Failed to fetch divisions");
        }
    };

    const initialValues = {
        companyId: '',
        departmentId: '',
        divisionId: '',
        name: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setSubmitting,resetForm }) => {
            try {
                await axios.post(
                    `${BASE_URL_TWO}`,
                    {
                        sp: 'insPosition',
                        userId,
                        positionName: values.name,
                        divisionId: values.divisionId,
                    },
                    { headers }
                );
                showMessage();
                onClose();
                resetForm();
                setShouldRefetch(!shouldRefetch)
            } catch (error) {
                showError(error?.response?.data?.Message);
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        fetchDepartments(selectedCompany);
        setSelectedDepartment(null);
        setDivisions([]);
        formik.setFieldValue('departmentId', '');
        formik.setFieldValue('divisionId', '');
    }, [selectedCompany]);

    useEffect(() => {
        fetchDivisions(selectedDepartment);
        formik.setFieldValue('divisionId', '');
    }, [selectedDepartment]);

    const handleClose = ()=>{
        formik.resetForm();
        onClose();
    }

    return (
        <Modal
            title="Create Position"
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
            centered
            width={600} // Adjust modal width if needed
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form action="" onSubmit={formik.handleSubmit}>
                <div className="" role="document">
                    <div>
                        <div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company</label>
                                    <select
                                        onChange={(e) => {
                                            const companyId = e.target.value;
                                            setSelectedCompany(companyId);
                                            formik.setFieldValue('companyId', companyId);
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="companyId"
                                        value={formik.values.companyId}
                                    >
                                        <option value="">Select Company</option>
                                        {companyList?.map((company) => (
                                            <option key={company.companyId} value={company.companyId}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.companyId}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Department</label>
                                    <select
                                        onChange={(e) => {
                                            const departmentId = e.target.value;
                                            setSelectedDepartment(departmentId);
                                            formik.setFieldValue('departmentId', departmentId);
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="departmentId"
                                        value={formik.values.departmentId}
                                        disabled={!selectedCompany}
                                    >
                                        <option value="">Select Department</option>
                                        {departments?.map((dept) => (
                                            <option key={dept.deptId} value={dept.deptId}>
                                                {dept.deptName}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.departmentId}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Division</label>
                                    <select
                                        onChange={(e) => {
                                            const divisionId = e.target.value;
                                            formik.setFieldValue('divisionId', divisionId);
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="divisionId"
                                        value={formik.values.divisionId}
                                        disabled={!selectedDepartment}
                                    >
                                        <option value="">Select Division</option>
                                        {divisions?.map((division) => (
                                            <option key={division.divisionId} value={division.divisionId}>
                                                {division.divisionName}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.divisionId}</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Position Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder="Enter Position Name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer mt-3">
                            <button type="button" className="btn btn-light" onClick={handleClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary ms-3">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
