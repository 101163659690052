import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { KTSVG } from '../../../../_metronic/helpers';
import { message, Modal } from "antd";
import axios from 'axios';
import { BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Position Name is required'),
});

export default function EditPosition({ setShouldRefetch,visible,onClose, selectedPosition, selectedCompany, selectedDepartment, selectedDivision }) {

    const id = Cookies.get('userId');
    const token = Cookies.get("token");

    const headers = {
        'token': token,
        'Content-Type': 'application/json',
    };

    const showMessage = () => {
        message.success({
            content: 'Updated successfully',
            duration: 1,
        });
    };

    const initialValues = {
        companyName: selectedCompany?.companyName,
        departmentName: selectedDepartment?.deptName,
        divisionName: selectedDivision?.divisionName,
        name: selectedPosition?.positionName,
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                await axios.post(`${BASE_URL_TWO}`, {
                    sp: 'updPosition',
                    userId: id,
                    positionName: values?.name,
                    divisionId: selectedDivision?.divisionId,
                    positionId: selectedPosition?.positionId
                }, { headers });
                showMessage();
                onClose();
                resetForm();
                setShouldRefetch((prev) => !prev);
            } catch (error) {
                console.error(error);
                message.error({
                    content: 'Failed to update position.',
                    duration: 2,
                });
            }
        },
        enableReinitialize: true,
    });

    const handleClose =()=>{
        onClose();
    }

    return (
        <Modal
            title="Edit Position"
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
            centered
            width={600} // Adjust modal width if needed
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="" role="document">
                    <div >
                        <div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        value={formik.values.companyName}
                                        readOnly
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Department</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        value={formik.values.departmentName}
                                        readOnly
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Division</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        value={formik.values.divisionName}
                                        readOnly
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Position Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer mt-3">
                            <button type="button" className="btn btn-light" onClick={handleClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary ms-3">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
