import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { CardsWidget30 } from '../../../../_metronic/partials/widgets';
import useFetchData from '../../../hooks/getData';
import Cookies from 'js-cookie';
import axios from "axios";
import { BASE_URL_TWO } from '../../../../utils';
import { message } from "antd";
import PopUp from '../../../components/Modal/Modal';
import RichTextEditor from '../../../../_metronic/helpers/components/RichTextEditor';
import { height, minHeight } from '@mui/system';
import { FadeLoader } from 'react-spinners';

const Policy = () => {
  const [checkTitle, setCheckTitle] = useState('');
  const { fetchedData ,isLoading} = useFetchData('getCompanyPolicies');
  const [editedText, setEditedText] = useState('');
  const [clickedIndex, setClickedIndex] = useState(null);
  const [actionStatus, setActionStatus] = useState(false);
  const [policyId, setPolicyId] = useState(null);
  const [show, setShow] = useState(false);

  const id = Cookies.get('userId');
  const token = Cookies.get("token");

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  let leaveItems = [
    { title: 'Sick Leave', color: '#34a8cb' },
    { title: 'Maternity Leave', color: '#6255a3' },
    { title: 'Paternity Leave', color: '#143983' },
    { title: 'Marriage Leave', color: '#6255a3' },
    { title: 'Unpaid Leave', color: '#143983' },
    { title: 'Hajj Leave', color: '#6255a3' },
    { title: 'Emergency Leave', color: '#143983' },
    { title: 'Annual Leave', color: '#6255a3' },
    { title: 'Additional Leave', color: '#34a8cb' },
    {title:"Flex Work",color:'#143983'}
  ];
const titles  = leaveItems?.map(item=>item.title)
 const newLeaveItems= fetchedData?.filter((item)=>titles.includes(item.policyTitle))

  const [localFetchedData, setLocalFetchedData] = useState(fetchedData || []);

  useEffect(() => {
    setLocalFetchedData(fetchedData);
  }, [fetchedData]);

  const filteredData = localFetchedData?.filter((data) => data?.policyTitle === checkTitle) || [];

  const showMessage = (msg) => {
    message.success({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const showErrorMessage = (msg) => {
    message.error({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const updateFetchedData = (updatedPolicy) => {
    const updatedData = localFetchedData.map(item =>
      item.policyId === updatedPolicy.policyId ? { ...item, description: updatedPolicy.description } : item
    );
    setLocalFetchedData(updatedData);
  };

  useEffect(() => {
    if (actionStatus) {
      axios.post(`${BASE_URL_TWO}`, {
        sp: "updCompanyPolicies",
        userId: parseInt(id),
        description: editedText,
        policyId: policyId
      }, { headers })
        .then(() => {
          showMessage("Updated successfully");
          window.location.reload()
          updateFetchedData({ policyId, description: editedText });
        })
        .catch((error) => {
          showErrorMessage("Leave policy update failed");
        })
        .finally(() => {
          setActionStatus(false);
          setEditedText('');
        });
    }
  }, [actionStatus]);

  return (
    <>
      <PopUp
        title="Update Policy"
        message="Are you sure you want to update this leave policy"
        btnName="Update"
        setActionStatus={setActionStatus}
        show={show}
        setShow={setShow}
      />

      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-1 me-2 svg-icon-muted text-black-50"></i>
        Edit Leave Policy
      </h5>
      {(isLoading) &&<div  className="d-flex justify-content-center align-items-center" style={{minHeight:"400px",width:"100%"}}><FadeLoader color='#0d6efd'/></div> }

      {!isLoading && <div className='row g-6 g-xl-30 mb-10 mb-xl-4 justify-content-md-center'>
        <div className='row g-6 g-xl-30 mb-10 mb-xl-4 justify-content-md-center'>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}></div>
          <div className='row col-md-6 g-6 g-xl-30 mb-5 mb-xl-2 justify-content-md-center'>
            {newLeaveItems?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setEditedText(item.description)
                  setCheckTitle(item.policyTitle);
                  setClickedIndex(index);
                  
                }}
                className='col-md-4 col-sm-6 mb-md-2 cursor-pointer'>
                <a className="text-white" >
                  <CardsWidget30
                    className='h-md-45 mb-1'
                    color={item.policyTitle=="Sick Leave"?"#34a8cb":item.policyTitle=="Maternity Leave"?"#6255a3":item.policyTitle=="Paternity Leave"?"#143983":item.policyTitle=="Marriage Leave"?"#6255a3":item.policyTitle=="Unpaid Leave"?"#143983":item.policyTitle=="Hajj Leave"?"#6255a3":item.policyTitle=="Emergency Leave"?"#143983":item.policyTitle=="Annual Leave"?"#6255a3":"#34a8cb"}
                    img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                    title={item.policyTitle}
                    description=''
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>}

      <div className='accordion accordion-borderless' id='kt_accordion_1'>
        <div className='accordion-item bg-transparent pb-3'>
          <div aria-labelledby='kt_accordion_1_header_1' data-bs-parent='#kt_accordion_1'>
            {clickedIndex !== null && filteredData.length !== 0 && (
              <div className='accordion-body text-body-secondary rounded-4 p-15 container bg-primary-subtle text-primary'>
                {filteredData.map((data, index) => (
                  <div key={index} className='text-justify d-flex flex-column ' >
                    <div className='mb-2 h-auto py-3'>
                    <RichTextEditor
                      className="datafield p-5 d-flex flex-column"
                      onChange={(value) => setEditedText(value)}
                      suppressContentEditableWarning={true}
                      value={editedText}
                    />
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end " >
                      <button
                        onClick={() => {
                          setShow(true);
                          setPolicyId(data.policyId);
                        }}
                        className="btn btn-sm btn-primary btn-active-light-primary"
                        type="button"
                      >
                        <i className="bi bi-check-circle fs-2 px-3 svg-icon-muted"></i>Save Changes
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export { Policy };
