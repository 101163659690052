import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useFetchData from '../../../hooks/getData';
import { KTSVG } from '../../../../_metronic/helpers';
import { message, Modal } from "antd";
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Department Name is  required'),
})

export default function EditDepartment({ selectedDept, companyList,onClose,visible, companyId, modalCls, setShouldRefetch }) {

    const [employeId, setEmployeName] = useState()
    const [selectedCompany, setSelectedCompany] = useState(companyId)
    const [loader, setLoader] = useState(false)
    const [canDismiss, setCanDismiss] = useState(modalCls);

    useEffect(() => {
        if (selectedDept) {
            setCanDismiss(false)
        }
    }, [selectedDept])


    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Updated department',
            duration: 1
        });
    };

    const showError = () => {
        message.error({
            content: 'Something went wrong',
            duration: 1
        });
    }

    const initialValues = {
        name: selectedDept?.deptName
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoader(true)
            try {
                axios.post(`${BASE_URL_TWO}`, {
                    sp: 'updDepartment',
                    userId: id,
                    deptName: values?.name,
                    deptId: selectedDept?.deptId
                }, { headers }).then(() => {
                    showMessage()
                    setShouldRefetch(prevState => !prevState)
                    setLoader(false)
                    onClose()
                })
            } catch (error) {
                showError()
            }
        },
        enableReinitialize: true
    });

    return (
        <Modal
            title="Edit Department"
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose
            centered
            width={800} // Adjust modal width
            className="custom-modal-header p-0"
            maskClosable={false}
        >

            <form action="" onSubmit={formik.handleSubmit}>
                <div className="" role="document">
                    <div>
                        <div >
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='companyName'
                                        value={companyList?.filter(item => item?.companyId == companyId)[0]?.companyName}
                                        disabled={true}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.compnayName}</span>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Department Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='name'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer mt-4">
                            <button type="button" className="btn btn-light" onClick={onClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary update-btn ms-3">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
