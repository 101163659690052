import React, { useEffect, useState } from "react";
import { Modal, Button, message} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL_TWO } from "../../../../utils";
import { Grid } from "@mui/material";

// Validation schema for form fields
const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    address: Yup.string().required("Address is required"),
});

export default function AddCompany({ visible, onClose, setShouldRefetch, shouldRefetch }) {
    const id = Cookies.get("userId");
    const token = Cookies.get("token");

    const headers = {
        token: token,
        "Content-Type": "application/json",
    };

    const showMessage = () => {
        message.success({
            content: "Added New Company",
            duration: 1,
        });
    };

    const initialValues = {
        companyName: "",
        address: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { resetForm }) => {
            try {
                // Submit the form data to add the company
                await axios.post(`${BASE_URL_TWO}`, {
                    sp: "insCompany",
                    companyName: values?.companyName,
                    address: values?.address,
                });

                showMessage();
                setShouldRefetch(!shouldRefetch); // Trigger refetch
                resetForm(); // Reset the form to initial values
                onClose(); // Close the modal
            } catch (error) {
                message.error("Failed to add company");
                console.error(error);
            }
        },
    });

    const handleClose = ()=>{
        formik.resetForm();
        onClose();
    }

    return (
        <Modal
            title="Enter Company Details"
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
            centered
            width={600} // Adjust modal width if needed
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form onSubmit={formik.handleSubmit}>
                {/* Company Name Field */}
                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Company Name <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter company name"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.companyName}</span>
                    </div>
                </div>

                {/* Address Field */}
                <div className="row mb-3">
                    <label className="col-lg-3 col-form-label fw-bold fs-7">Address <span className="text-danger">*</span></label>
                    <div className="col-lg-9">
                        <textarea
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Enter company address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            rows="4"
                        />
                        <span style={{ fontSize: "10px", color: "red" }}>{formik.errors.address}</span>
                    </div>
                </div>

                {/* Submit Button */}
                <Grid container justifyContent="end">
                    <button type="submit" className="btn btn-lg btn-primary mt-5">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span className="indicator-label">Add Company</span>
                        </div>
                    </button>
                </Grid>
            </form>
        </Modal>
    );
}
