
// import { useState, useEffect } from "react";
// import axios from "axios";
// import { BASE_URL } from "../../utils";


// const useFetchCommon = (url) => {
//     const [commonDataFetch, setFetchedData] = useState();

//     useEffect(() => {
//         const fetchData = async () => {

//             const response = await axios.get(`${BASE_URL + url}`,
//             ).then((res) => {
//                 // console.log("response...............from hook;", res)
//                 setFetchedData(res?.data?.Data)
//             })

//         };

//         fetchData();
//     }, []);

//     return { commonDataFetch };
// };

// export default useFetchCommon;


import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { message } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const useFetchCommon = (url,bodyDetails={}, dependencies = []) => {
    const [commonDataFetch, setFetchedData] = useState();
    const navigate = useNavigate()
    let response =  null;
    const headers = {
        'token': bodyDetails?.token,
        'Content-Type': 'application/json',
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                if(bodyDetails?.id){
                    response = await axios.get(`${BASE_URL + url}&userId=${bodyDetails?.id}`,{headers});
                }else{
                    response = await axios.get(`${BASE_URL + url}`);
                }
               
                setFetchedData(response?.data?.Data);
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  } else {
                    message.destroy();
                    message.error("Something went wrong")
                  }
                console.error("Error fetching data:", error);
            }
        };

        if(!url?.includes('undefined')){
            fetchData();
        }
    }, [url, ...dependencies]); // Include dependencies in the useEffect dependency array

    return { commonDataFetch };
};

export default useFetchCommon;
