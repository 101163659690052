import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL_TWO } from '../../../utils';
import { KTSVG } from '../../../_metronic/helpers';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const DocumentDigitalModal = ({ companyId, onClose,setShouldRefetch }) => {
    const id = Cookies.get('userId');
    const token = Cookies.get('token');
    const navigate = useNavigate()
    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const validationSchema = Yup.object().shape({
        documentName: Yup.string().required('Document Name is required'),
        filePath: Yup.mixed().required('Document file is required'),
        expiryDate: Yup.date().required('Expiry date is required'),
    });

    const initialValues = {
        documentName: "",
        expiryDate: '',
        filePath: ''
    };

    const showMessage = () => {
        message.destroy();
        message.success({
            content: 'Document Created Successfully',
            duration: 1 // Duration in seconds
        });
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file); // Convert file to base64 string
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const docFileBase64 = await convertFileToBase64(values.filePath);
                await axios.post(`${BASE_URL_TWO}`, {
                    userId: parseInt(id),
                    sp: 'insRenewalCompanyDocuments',
                    companyId,
                    documentName: values?.documentName,
                    filePath: docFileBase64,
                    expiryDate: values?.expiryDate,
                }, { headers });
                setShouldRefetch(prevState => !prevState)
                showMessage();
                onClose(); // Close modal on success
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                        Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                } else {
                    message.destroy();
                    message.error("Failed to create document")
                }
            } finally {
                setSubmitting(false);
            }
        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade show" id="kt_modal_4" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                        <h5 className="modal-title text-primary">Enter Details</h5>
                        <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onClose} aria-label="Close">
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Document Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='documentName'
                                        autoComplete='off'
                                        value={formik.values.documentName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.documentName && formik.errors.documentName && (
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.documentName}</span>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Expiry Date</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='expiryDate'
                                        autoComplete='off'
                                        value={formik.values.expiryDate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        min={new Date().toLocaleDateString('en-CA')}
                                    />
                                    {formik.touched.expiryDate && formik.errors.expiryDate && (
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.expiryDate}</span>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Upload Document</label>
                                    <input
                                        type="file"
                                        name="filePath"
                                        className='mb-3 mb-lg-0 border border-primary'
                                        onChange={(event) => {
                                            formik.setFieldValue("filePath", event.currentTarget.files[0]);
                                        }}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.filePath && formik.errors.filePath && (
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.filePath}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" onClick={onClose}>
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DocumentDigitalModal;
