import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PopUp from '../../../components/Modal/Modal';
import CreateDivision from './CreateDivision';
import EditDivsion from './EditDivsion';

import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { KTIcon } from '../../../../_metronic/helpers';

export default function Division() {
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');

    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [actionStatus, setActionStatus] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [visible, setVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);

    const headers = {
        'token': token,
        'Content-Type': 'application/json',
    };

    // Fetch companies
    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${BASE_URL}getCompanies`);
            setCompanyList(response?.data?.Data || []);
        } catch (error) {
            message.error('Failed to fetch companies.');
        }
    };

    // Fetch departments by selected company
    const fetchDepartments = async (companyId) => {
        if (!companyId) return;
        try {
            const response = await axios.get(`${BASE_URL}getDepartmentsByCompanyId&companyId=${companyId}`);
            setDepartmentList(response?.data?.Data || []);
        } catch (error) {
            message.error('Failed to fetch departments.');
        }
    };

    // Fetch divisions by selected department
    const fetchDivisions = async (departmentId) => {
        if (!departmentId) return;
        try {
            const response = await axios.get(`${BASE_URL}getDivisionsByDeptId&deptId=${departmentId}`);
            setDivisionList(response?.data?.Data || []);
        } catch (error) {
            message.error('Failed to fetch divisions.');
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (selectedCompany) {
            fetchDepartments(selectedCompany);
            setSelectedDepartment(null); // Reset department selection when company changes
            setDivisionList([]); // Clear division list
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (selectedDepartment) {
            fetchDivisions(selectedDepartment);
        }
    }, [selectedDepartment, shouldRefetch]);

    const handleDelete = async () => {
        try {
            await axios.post(`${BASE_URL_TWO}`, {
                sp: 'delDivision',
                userId,
                divisionId: deleteId,
            }, { headers });
            message.destroy();
            setActionStatus(false);
            message.success('Division deleted successfully.');
            setShouldRefetch(!shouldRefetch);
        } catch (error) {
            setActionStatus(false);
            message.destroy();
            message.error("This division can't delete");
        }
    }

    useEffect(() => {
        if (actionStatus && deleteId) {
            handleDelete()
        }
    }, [actionStatus, deleteId]);

    const companyOptions = companyList.map((company) => ({
        label: company.companyName,
        id: company.companyId,
    }));

    const departmentOptions = departmentList.map((department) => ({
        label: department.deptName,
        id: department.deptId,
    }));

    return (
        <>
            <PopUp
                show={show} // Implement show state and logic as needed
                setShow={setShow}
                setActionStatus={setActionStatus}
                title="Delete Division"
                message="Are you sure you want to delete this division?"
                btnName="Delete"
            />

            <h5 className="text-black-50 mb-10">
                <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Divisions
            </h5>

            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="col-md-5 row">
                    {/* Company Selector */}
                    <Autocomplete
                        disablePortal
                        id="company-selector"
                        className='col-6'
                        options={companyOptions}
                        value={companyOptions.find((option) => option.id === selectedCompany) || null}
                        onChange={(event, value) => setSelectedCompany(value?.id || null)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Company"
                                placeholder="Select a company"
                                required
                                focused
                            />
                        )}
                    />

                    {/* Department Selector */}
                    <Autocomplete
                        disablePortal
                        id="department-selector"
                        className='col-6'
                        options={departmentOptions}
                        value={departmentOptions.find((option) => option.id === selectedDepartment) || null}
                        onChange={(event, value) => setSelectedDepartment(value?.id || null)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Department"
                                placeholder="Select a department"
                                required
                                focused
                            />
                        )}

                    />
                </div>
                <div className="card-toolbar me-5">
                    <span
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        title="New Entry"
                        onClick={() => setVisible(true)}
                    >
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </span>
                </div>
            </div>

            <CreateDivision
                onClose={() => setVisible(false)}
                visible={visible}
                companyList={companyList}
                setShouldRefetch={setShouldRefetch}
                shouldRefetch={shouldRefetch}
                setSelectedCompany={setSelectedCompany}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
            />

            <EditDivsion
                visible={editVisible}
                onClose={() => setEditVisible(false)}
                selectedDivision={selectedDivision}
                departments={departmentList}
                companyList={companyList}
                companyId={selectedCompany}
                selectedDepartment={selectedDepartment}
                setSelectedDivision={setSelectedDivision}
                setShouldRefetch={setShouldRefetch}
                shouldRefetch={shouldRefetch}
            />

            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                <thead>
                    <tr className="fw-bold fs-5 bg-primary rounded text-white">
                        <th>#</th>
                        <th>Division</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {divisionList.length > 0 ? (
                        divisionList.map((division, index) => (
                            <tr key={division.divisionId}>
                                <td className="text-primary fw-bold">
                                    <span className="badge">{index + 1}</span>
                                </td>
                                <td className="text-primary fw-bold">{division.divisionName}</td>
                                <td className="text-primary fw-bold">
                                    <Tooltip title="Delete" arrow>
                                        <DeleteIcon
                                            onClick={() => { setDeleteId(division.divisionId); setShow(true) }}
                                            sx={{ cursor: 'pointer' }}
                                            title="Delete"
                                        />
                                    </Tooltip>
                                    <span
                                        onClick={() =>{ setSelectedDivision(division); setEditVisible(true)}}
                                    >
                                        <Tooltip title="Edit" arrow>
                                            <ModeEditIcon
                                                sx={{ cursor: 'pointer', ml: 2 }}
                                            />
                                        </Tooltip>
                                    </span>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="text-center" colSpan={3}>No Records Found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}
