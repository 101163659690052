import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useFetchData from '../../../hooks/getData';
import { KTSVG } from '../../../../_metronic/helpers';
import { message } from "antd";
import axios from 'axios';
import { BASE_URL_TWO } from '../../../../utils';


const validationSchema = Yup.object().shape({
    name: Yup.string().required('Department Name is  required'),
})

export default function CreateDeductionType() {
    const [employeId, setEmployeName] = useState()

    const [shouldRefetch, setShouldRefetch] = useState(false);

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const employeList = useFetchData('getEmployeesList').fetchedData;

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Added new Department',
            duration: 1
        });
    };

    const initialValues = {
        employeeName: '',
        plateNumber: '',
        carModel: '',
        carYear: '',
        fromDate: '',
        toDate: '',
        insuranceAmunt: '',
        fee: ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            axios.post(`${BASE_URL_TWO}`, {
                sp: 'insDeductionTypes',
                userId: id,
                deductionTypeName: values?.name

            }, { headers }).then(() => {
                showMessage()
                setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 2000);
            })
        },
    });


    return (
        <div className="modal fade" id="kt_modal_2" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form action="" onSubmit={formik.handleSubmit}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Deduction Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">

                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Deduction Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder=""
                                        name='name'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role='alert'>{formik.errors.name}</span>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
