import React from 'react';
import './monthView.css';

export type EmployeeLeaveDetails = {
  date: Date;
  type: string;
};

interface CalendarProps {
  year: number;
  month: number;
  type: string;
  employeeLeaveDetails: EmployeeLeaveDetails[];
  className?: string;
  dateDetails?: { startedDate: string; ReturnDate: string; leaveType: string }[];
}

const Calendar: React.FC<CalendarProps> = ({ year, month, className, type, employeeLeaveDetails, dateDetails }) => {
  const today = new Date(); // Get today's date
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const totalDays = lastDay.getDate();
  const firstDayVal = -firstDay.getDay() + 1;
  const startDate = new Date(year, month, firstDayVal);
  const totalWeeks = 6;
  const weeks: JSX.Element[] = [];

  let start = '';
  let end = '';

  if (Array.isArray(dateDetails)) {
    // Iterate over dateDetails array to find start and end dates
    dateDetails.forEach(data => {
      if (!start || data.startedDate < start) {
        start = data.startedDate;
      }
      if (!end || data.ReturnDate > end) {
        end = data.ReturnDate;
      }
    });
  }

  let currentDate = startDate;

  const isInRange = (date: Date, start: string, end: string) => {
    return date >= new Date(start) && date <= new Date(end);
  };

  // Map leave types to background colors
  const leaveTypeToColor: { [key: string]: string } = {
    'Additional Leave': '#6255A38C', // Example: Gold color for Additional Leave
    'Sick Leave': '#34A8CB80', // Example: Tomato color for Sick Leave
    'Marriage Leave': '#6255A38C', // Example: Sky Blue for Vacation
    'Maternity Leave': '#6255A38C', // Example: Light Pink for Maternity Leave
    'Emergency Leave': '#14398373',
    'Annual Leave': '#14398373',
    'Unpaid Leave': '#34A8CB80', 
    'Hajj Leave': '#34A8CB80',
    'Paternity Leave': '#34A8CB80',
    'Flex work': 'dodgerblue',
  };

  for (let weekIndex = 0; weekIndex < totalWeeks; weekIndex++) {
    const week: JSX.Element[] = [];

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      if (
        weekIndex * 7 + dayIndex + firstDayVal < 1 ||
        weekIndex * 7 + dayIndex + firstDayVal > totalDays
      ) {
        week.push(
          <div
            className="col calendar-day border d-flex align-items-center justify-content-center"
            key={`${weekIndex}-${dayIndex}`}
          ></div>
        );
      } else {
        const object = employeeLeaveDetails.find(
          obj => obj.date.getTime() === currentDate.getTime()
        );

        let isInAnyRange = false;
        let backgroundColor = '';

        if (Array.isArray(dateDetails)) {
          dateDetails.forEach(({ startedDate, ReturnDate, leaveType }) => {
            if (isInRange(currentDate, startedDate, ReturnDate)) {
              isInAnyRange = true;
              backgroundColor = leaveTypeToColor[leaveType] || '#00000066'; // Default to black overlay if leaveType not mapped
            }
          });
        }

        if (object !== undefined ? (type === 'All' || object.type === type) : false) {
          week.push(
            <div
              className={`col calendar-day ${object ? object.type : ''} border d-flex align-items-center justify-content-center`}
              key={currentDate.toString()}
              style={{ backgroundColor: object ? leaveTypeToColor[object.type] || backgroundColor : backgroundColor }}
            >
              {currentDate.getDate()}
            </div>
          );
        } else {
          week.push(
            <div
              style={{ background: isInAnyRange ? backgroundColor : '' }}
              className={`col calendar-day border d-flex align-items-center justify-content-center`}
              key={currentDate.toString()}
            >
              {currentDate.getDate()}
            </div>
          );
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    weeks.push(
      <div className="row calendar-week" key={weekIndex}>
        {week}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="calendar-header d-flex align-items-center justify-content-center">
        <h2>{new Date(year, month).toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
      </div>
      <div className="calendar-body">
        <div className="row calendar-weekdays d-flex flex-row-fluid">
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary">S</div>
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary">M</div>
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary">T</div>
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary">W</div>
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary">T</div>
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary text-info">F</div>
          <div className="col h4 day-header border d-flex align-items-center justify-content-center bg-secondary text-info">S</div>
        </div>
        {weeks}
      </div>
    </div>
  );
};

export default Calendar;
