import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import useFetchCommon from '../../../hooks/getCommonData';
import { KTSVG } from '../../../../_metronic/helpers';
import { message, Modal } from "antd";
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Division Name is required'),
    companyId: Yup.string().required('Company is required'),
    dptId: Yup.string().required('Department is required'),
});

export default function CreateDivision({visible,onClose, companyList, setShouldRefetch,shouldRefetch}) {

    const [deptId, setDeptId] = useState(null);
    const [companyId, setCompanyId] = useState(companyList[0]?.companyId || ''); // Default to first company
    const [departmentList, setDepartmentList] = useState([]);
    const id = Cookies.get('userId');
    const token = Cookies.get("token");

    const headers = {
        'token': token, 
        'Content-Type': 'application/json',
    };

    // Show success message
    const showMessage = () => {
        message.destroy();
        message.success({
            content: 'New division added',
            duration: 1,
        });
    };

    // Show error message
    const showErrorMessage = () => {
        message.info({
            content: 'Failed to create',
            duration: 1,
        });
    };

    // Fetch departments based on the selected company
    const departments = useFetchCommon(
        `getDepartmentsByCompanyId&companyId=${companyId}`,
        {},
        [companyId] // Trigger fetch when companyId changes
    )?.commonDataFetch;

    useEffect(() => {
        if (departments) {
            setDepartmentList(departments);
        }
    }, [departments]);

    // Handle form submission
    const formik = useFormik({
        initialValues: {
            companyId: companyId,
            dptId: '',
            name: '',
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            try {
                await axios.post(`${BASE_URL_TWO}default.aspx`, {
                    sp: 'insDivision',
                    userId: id,
                    divisionName: values.name,
                    deptId: deptId,
                }, { headers });

                showMessage();
                // Reset form and clear state
                resetForm();
                onClose();
                setCompanyId(companyList[0]?.companyId); // Reset to the first company
                setShouldRefetch(!shouldRefetch)

            } catch (err) {
                message.destroy();
                message?.error(err?.response?.data?.Message)
            }
        },
    });

    // Generate company options
    const companyOptions = companyList?.map(item => ({
        label: item?.companyName,
        id: item?.companyId,
    }));

    // Generate department options based on the selected company
    const departmentOptions = departmentList?.map(item => ({
        label: item?.deptName,
        id: item?.deptId,
    }));

    const handleClose =()=>{
        formik.resetForm();
        setCompanyId(companyList[0]?.companyId); 
        onClose();
    }

    return (
        <Modal
            title="Create Division"
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
            centered
            width={600} // Adjust modal width if needed
            className="custom-modal-header p-0"
            maskClosable={false}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="" role="document">
                    <div className="">
                        <div >
                            <div className="row g-3">
                                {/* Company Select */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Company</label>
                                    <select
                                        onChange={(e) => {
                                            setCompanyId(e.target.value);
                                            formik.handleChange(e);
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="companyId"
                                        autoComplete="off"
                                        value={formik.values.companyId}
                                    >
                                        <option value="">Select Company</option>
                                        {companyOptions?.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.companyId}</span>
                                </div>

                                {/* Department Select */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Department</label>
                                    <select
                                        onChange={(e) => {
                                            setDeptId(e.target.value);
                                            formik.handleChange(e);
                                        }}
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        name="dptId"
                                        autoComplete="off"
                                        value={formik.values.dptId}
                                    >
                                        <option value="">Select Department</option>
                                        {departmentOptions?.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.dptId}</span>
                                </div>

                                {/* Division Name */}
                                <div className="col-md-6">
                                    <label className="form-label fw-bold fs-7 text-light-emphasi">Division Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                        placeholder="Enter division name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                    <span style={{ color: 'red' }} role="alert">{formik.errors.name}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer mt-2">
                            <button type="button" className="btn btn-light"  onClick={handleClose}>
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary ms-3"
                               
                            >
                                Add Division
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
