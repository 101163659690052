import { useEffect, useMemo, useState } from 'react';

//MRT Imports
//import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'; //default import deprecated

//Mock Data

import { message } from "antd";

import Cookies from 'js-cookie';

import axios from 'axios';

import useFetchCommon from '../../../hooks/getCommonData';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import { KTIcon } from '../../../../_metronic/helpers';
import PopUp from '../../../components/Modal/Modal';
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditBenefit from './EditBenefit';
import AddBenefit from './AddBenefit';
import { ClipLoader } from 'react-spinners';


export const Employee = {
    companyName: '',
    benefitName: '',
};

const Benefits = () => {

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [show, setShow] = useState(false);
    const [actionStatus, setActionStatus] = useState(false)
    const [deleteId, setDeleteId] = useState();
    const [benefitData, setBenefitData] = useState();
    const [visible, setVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false)
    const [loading, setLoading] = useState(true);
    const [selectedCompany,setSelectedCompany] = useState()
    const [companyList,setCompanyList] = useState()

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const fetchCompanies = async () => {
        const response = await axios.get(`${BASE_URL}getCompanies`)
        setCompanyList(response?.data?.Data);
    }
    useEffect(() => {
        fetchCompanies()
    }, [])

    const companyOptions = companyList?.map(item => ({
        label: item?.companyName,
        id: item?.companyId
    }))

    const { commonDataFetch } = useFetchCommon(`getBenefitsListByCompanyId&companyId=${selectedCompany ? selectedCompany : ''}`, { id, token }, [shouldRefetch]);

    const tableData = commonDataFetch || [];

    const showMessage = (msg) => {
        message.destroy();
        message.success({
            content: msg,
            duration: 4 // Duration in seconds
        });
    };

    const handleDelete = async () => {
        try {
            await axios.post(`${BASE_URL_TWO}`, {
                sp: 'delBenefit',
                userId: id,
                benefitId: deleteId,
            }, { headers });
            message.destroy();
            setActionStatus(false);
            message.success('Benefit deleted successfully.');
            setShouldRefetch(!shouldRefetch);
        } catch (error) {
            setActionStatus(false);
            message.destroy();
            message.error("Unable to delete this Benefit");
        }
    }

    useEffect(() => {
        if (actionStatus && deleteId) {
            handleDelete();
        }
    }, [actionStatus, deleteId])

    useEffect(() => {
        if (commonDataFetch) {
            setLoading(false); // Stop loading when data is fetched
        }
    }, [commonDataFetch]);

    return (
        <>
            <EditBenefit setBenefitData={setBenefitData} setShouldRefetch={setShouldRefetch} benefitData={benefitData} shouldRefetch={shouldRefetch} onClose={() => setEditVisible(false)} visible={editVisible} />
            <PopUp
                title="Delete Benefit"
                message="Are you sure you want to Delete this Benefit?"
                btnName="Delete"
                setActionStatus={setActionStatus}
                show={show}
                setShow={setShow}
            />

            <div className='d-flex justify-content-between align-items-center'>
                <AddBenefit onClose={() => setVisible(false)} visible={visible} shouldRefetch={shouldRefetch} setShouldRefetch={setShouldRefetch} />
                <h5 className="text-black-50 mb-10">
                    <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> {/* icon */}  Benefit List
                </h5>
            </div>
            {<div className=' d-flex justify-content-between align-items-center mb-3'>
                    <div className='col-3 col-md-3'>
                        <Autocomplete
                            disablePortal
                            id='yearField'
                            color="primary"
                            options={companyOptions}
                            onInputChange={(event, value) => {
                                // Check for valid value before updating state
                                setSelectedCompany(value?.id || ''); // Set to null if no valid company is selected
                                setShouldRefetch(prevState => !prevState);
                            }}
                            onChange={(event, value) => {
                                // Handle the unselect case where value is null or undefined
                                setSelectedCompany(value?.id || ''); // Set to null if no valid company is selected
                                setShouldRefetch(prevState => !prevState);
                            }}
                            classes={{
                                input: 'text-dark'
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required
                                    focused
                                    label="Company"
                                    placeholder="Select a company"
                                />
                            }
                        />

                    </div>
                    <div className="card-toolbar me-5">
                    {/* Button to open the modal */}
                    <span
                        className="btn btn-md btn-light-primary border"
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        title="New Entry"
                        onClick={() => setVisible(true)}
                    >
                        <KTIcon iconName="plus" className="fs-2" />
                        New Entry
                    </span>
                </div>

                </div >}

            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid container>
                    {!loading && <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 bg-white gy-5 gs-7 shadow">
                        <thead>
                            <tr className="fw-bold fs-5 bg-primary rounded text-white">
                                <th>#</th>
                                <th>Company Name</th>
                                <th>Benefit Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                commonDataFetch?.map((data, index) =>
                                    <tr style={{ borderBottom: "" }} key={index}>
                                        <td className="text-primary fw-bold"><span className="badge">{index + 1}</span></td>
                                        <td className="text-primary fw-bold">{data?.companyName}</td>
                                        <td className="text-primary fw-bold">{data?.benefitName}</td>
                                        <td>
                                            <Tooltip title="Delete" arrow>
                                                <DeleteIcon
                                                    onClick={() => { setDeleteId(data?.benefitId); setShow(true) }}
                                                    style={{ cursor: "pointer", color: 'rgb(18 54 125)' }}
                                                    title="Delete"
                                                />
                                            </Tooltip>
                                            <span
                                                onClick={() => { setBenefitData(data); setEditVisible(true) }} // Pass selected position
                                                data-kt-menu-trigger='click'
                                                data-kt-menu-placement='bottom-end'
                                                data-kt-menu-flip='top-end'
                                                title="Edit"

                                            >
                                                <Tooltip title="Edit" arrow>
                                                    <ModeEditIcon
                                                        sx={{
                                                            cursor: 'pointer',
                                                            ml: 2
                                                        }}
                                                        className='text-primary'
                                                    />
                                                </Tooltip>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            }
                            { }
                            {commonDataFetch?.length <= 0 && <tr><td className='text-center' colSpan={4}>No Records Found</td></tr>}
                        </tbody>
                    </table>}
                    {loading && <div className='text-center d-flex justify-content-center align-items-center' style={{ height: "500px", width: "100%" }}><ClipLoader color='#143983' size={45} /></div>}
                </Grid>
            </Grid>
        </>
    );
};

export default Benefits;