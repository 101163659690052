import React, { useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { BankGuarantee } from '../../pages/EmployerPages/Organisation/BankGaurentee';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL_TWO } from '../../../utils';
import { message } from "antd";
import axios from 'axios';
import Cookies from 'js-cookie';
import Select from 'react-select';

import moment from 'moment';
import { useNavigate, useNavigation } from 'react-router-dom';



const validationSchema = Yup.object().shape({
    clientName: Yup.string().required('Customer name required'),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string()
        .required('End date is required')
        .test('endDate', 'End date must be after the start date', function (value) {
            const { startDate } = this.parent;
            return !startDate || !value || new Date(value) > new Date(startDate);
        }),
    guaranteType: Yup.string().required('Select guarantee Type'),
    amount: Yup.string().required('Guarantee amount is required'),
    currency: Yup.string()
        .required('Currency is required')
        .length(3, 'Currency code must be exactly 3 characters')
        .matches(/^[A-Za-z]+$/, 'Currency code must only contain letters'),
})

export default function BankGuaranteeFormEdit({ selectedData, setShouldRefetch }) {

    const [guaranteeType, setGuaranteeType] = useState()
    const navigate = useNavigate()

    const start = moment(selectedData?.startDate, "M/D/YYYY").format("YYYY-MM-DD");
    const end = moment(selectedData?.endDate, "M/D/YYYY").format("YYYY-MM-DD");


    const initialValues = {
        clientName: selectedData ? selectedData?.customerName : '',
        startDate: start,
        endDate: end,
        guaranteType: selectedData ? selectedData?.guaranteeType : '',
        amount: selectedData ? selectedData?.guaranteeAmount : '',
        currency: selectedData ? selectedData?.currency : ""
    }

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    const showMessage = () => {
        message.success({
            content: 'Updated',
            duration: 1
        });
    };

    const currencies = [
        { label: "Afghan Afghani", value: "AFN" },
        { label: "Albanian Lek", value: "ALL" },
        { label: "Algerian Dinar", value: "DZD" },
        { label: "Angolan Kwanza", value: "AOA" },
        { label: "Eastern Caribbean Dollar", value: "XCD" },
        { label: "Argentine Peso", value: "ARS" },
        { label: "Armenian Dram", value: "AMD" },
        { label: "Australian Dollar", value: "AUD" },
        { label: "Austrian Euro", value: "EUR" },
        { label: "Azerbaijani Manat", value: "AZN" },
        { label: "Bahamian Dollar", value: "BSD" },
        { label: "Bahraini Dinar", value: "BHD" },
        { label: "Bangladeshi Taka", value: "BDT" },
        { label: "Barbadian Dollar", value: "BBD" },
        { label: "Belarusian Ruble", value: "BYN" },
        { label: "Belgian Euro", value: "EUR" },
        { label: "Belize Dollar", value: "BZD" },
        { label: "Bermudian Dollar", value: "BMD" },
        { label: "Bhutanese Ngultrum", value: "BTN" },
        { label: "Bolivian Boliviano", value: "BOB" },
        { label: "Bosnia and Herzegovina Convertible Mark", value: "BAM" },
        { label: "Botswana Pula", value: "BWP" },
        { label: "Brazilian Real", value: "BRL" },
        { label: "British Pound Sterling", value: "GBP" },
        { label: "Brunei Dollar", value: "BND" },
        { label: "Bulgarian Lev", value: "BGN" },
        { label: "Burundian Franc", value: "BIF" },
        { label: "Cabo Verdean Escudo", value: "CVE" },
        { label: "Cambodian Riel", value: "KHR" },
        { label: "Canadian Dollar", value: "CAD" },
        { label: "Central African CFA Franc", value: "XAF" },
        { label: "Chilean Peso", value: "CLP" },
        { label: "Chinese Yuan", value: "CNY" },
        { label: "Colombian Peso", value: "COP" },
        { label: "Comorian Franc", value: "KMF" },
        { label: "Congolese Franc", value: "CDF" },
        { label: "Costa Rican Colón", value: "CRC" },
        { label: "Croatian Kuna", value: "HRK" },
        { label: "Cuban Peso", value: "CUP" },
        { label: "Czech Koruna", value: "CZK" },
        { label: "Danish Krone", value: "DKK" },
        { label: "Djiboutian Franc", value: "DJF" },
        { label: "Dominican Peso", value: "DOP" },
        { label: "East Caribbean Dollar", value: "XCD" },
        { label: "Egyptian Pound", value: "EGP" },
        { label: "El Salvadoran Colón", value: "SVC" },
        { label: "Equatorial Guinean CFA Franc", value: "XAF" },
        { label: "Eritrean Nakfa", value: "ERN" },
        { label: "Estonian Euro", value: "EUR" },
        { label: "Eswatini Lilangeni", value: "SZL" },
        { label: "Ethiopian Birr", value: "ETB" },
        { label: "Fijian Dollar", value: "FJD" },
        { label: "Finnish Euro", value: "EUR" },
        { label: "French Euro", value: "EUR" },
        { label: "Gabonese CFA Franc", value: "XAF" },
        { label: "Gambian Dalasi", value: "GMD" },
        { label: "Georgian Lari", value: "GEL" },
        { label: "German Euro", value: "EUR" },
        { label: "Ghanaian Cedi", value: "GHS" },
        { label: "Gibraltar Pound", value: "GIP" },
        { label: "Greek Euro", value: "EUR" },
        { label: "Guatemalan Quetzal", value: "GTQ" },
        { label: "Guinean Franc", value: "GNF" },
        { label: "Guyanese Dollar", value: "GYD" },
        { label: "Haitian Gourde", value: "HTG" },
        { label: "Honduran Lempira", value: "HNL" },
        { label: "Hungarian Forint", value: "HUF" },
        { label: "Icelandic Króna", value: "ISK" },
        { label: "Indian Rupee", value: "INR" },
        { label: "Indonesian Rupiah", value: "IDR" },
        { label: "Iranian Rial", value: "IRR" },
        { label: "Iraqi Dinar", value: "IQD" },
        { label: "Israeli New Shekel", value: "ILS" },
        { label: "Italian Euro", value: "EUR" },
        { label: "Jamaican Dollar", value: "JMD" },
        { label: "Japanese Yen", value: "JPY" },
        { label: "Jordanian Dinar", value: "JOD" },
        { label: "Kazakhstani Tenge", value: "KZT" },
        { label: "Kenyan Shilling", value: "KES" },
        { label: "Kuwaiti Dinar", value: "KWD" },
        { label: "Kyrgyzstani Som", value: "KGS" },
        { label: "Lao Kip", value: "LAK" },
        { label: "Latvian Euro", value: "EUR" },
        { label: "Lebanese Pound", value: "LBP" },
        { label: "Lesotho Loti", value: "LSL" },
        { label: "Liberian Dollar", value: "LRD" },
        { label: "Libyan Dinar", value: "LYD" },
        { label: "Lithuanian Euro", value: "EUR" },
        { label: "Luxembourg Euro", value: "EUR" },
        { label: "Macanese Pataca", value: "MOP" },
        { label: "Macedonian Denar", value: "MKD" },
        { label: "Malagasy Ariary", value: "MGA" },
        { label: "Malawian Kwacha", value: "MWK" },
        { label: "Malaysian Ringgit", value: "MYR" },
        { label: "Maldivian Rufiyaa", value: "MVR" },
        { label: "Malian CFA Franc", value: "XOF" },
        { label: "Mauritian Rupee", value: "MUR" },
        { label: "Mexican Peso", value: "MXN" },
        { label: "Moldovan Leu", value: "MDL" },
        { label: "Mongolian Tögrög", value: "MNT" },
        { label: "Moroccan Dirham", value: "MAD" },
        { label: "Mozambican Metical", value: "MZN" },
        { label: "Myanmar Kyat", value: "MMK" },
        { label: "Namibian Dollar", value: "NAD" },
        { label: "Nepalese Rupee", value: "NPR" },
        { label: "Netherlands Euro", value: "EUR" },
        { label: "New Zealand Dollar", value: "NZD" },
        { label: "Nigerian Naira", value: "NGN" },
        { label: "North Korean Won", value: "KPW" },
        { label: "Norwegian Krone", value: "NOK" },
        { label: "Omani Rial", value: "OMR" },
        { label: "Pakistani Rupee", value: "PKR" },
        { label: "Panamanian Balboa", value: "PAB" },
        { label: "Papua New Guinean Kina", value: "PGK" },
        { label: "Paraguayan Guaraní", value: "PYG" },
        { label: "Peruvian Sol", value: "PEN" },
        { label: "Philippine Peso", value: "PHP" },
        { label: "Polish Zloty", value: "PLN" },
        { label: "Qatari Rial", value:"QAR" },
        { label: "Romanian Leu", value:"RON" },
        { label: "Russian Ruble", value:"RUB" },
        { label: "Rwandan Franc", value:"RWF" },
        { label: "Saint Helena Pound", value:"SHP" },
        { label: "Samoan Tala", value:"WST" },
        { label: "San Marino Euro", value:"EUR" },
        { label: "Sao Tome and Principe Dobra", value:"STN" },
        { label: "Saudi Riyal", value:"SAR" },
        { label: "Scottish Pound", value:"GBP" },
        { label: "Senegalese CFA Franc", value:"XOF" },
        { label: "Serbian Dinar", value:"RSD" },
        { label: "Seychellois Rupee", value: "SCR" },
        { label: "Sierra Leonean Leone", value: "SLL" },
        { label: "Singapore Dollar", value:"SGD" },
        { label: "Slovak Euro", value: "EUR" },
        { label: "Slovenian Euro", value: "EUR" },
        { label: "Solomon Islands Dollar", value:"AUD" },
        { label: "Somali Shilling", value: "SOS" },
        { label: "South African Rand", value: "ZAR" },
        { label: "South Korean Won", value:"KRW" },
        { label: "South Sudanese Pound", value: "SSP" },
        { label: "Spanish Euro", value: "EUR" },
        { label: "Sri Lankan Rupee", value:"LKR" },
        { label: "Sudanese Pound", value: "SDG" },
        { label: "Surinamese Dollar", value:"SRD" },
        { label: "Swedish Krona", value: "SEK" },
        { label: "Swiss Franc", value:"CHF" },
        { label: "Syrian Pound", value:"SYP" },
        { label: "Tajikistani Somoni", value:"TJS" },
        { label: "Tanzanian Shilling", value:"TZS" },
        { label: "Thai Baht", value: "THB" },
        { label: "Tunisian Dinar", value: "TND" },
        { label: "Turkish Lira", value: "TRY" },
        { label: "Turkmenistani Manat", value: "TMT" },
        { label: "Ugandan Shilling", value:"UGX" },
        { label: "Ukrainian Hryvnia", value:"UAH" },
        { label: "United Arab Emirates Dirham", value: "AED" },
        { label: "United States Dollar", value: "USD" },
        { label: "Uruguayan Peso", value: "UYU" },
        { label: "Uzbekistani Som", value: "UZS" },
        { label: "Vanuatu Vatu", value: "VUV" },
        { label: "Venezuelan Bolívar", value: "VES" },
        { label: "Vietnamese Dong", value: "VND" },
        { label: "Yemeni Rial", value: "YER" },
        { label: "Zambian Kwacha", value: "ZMW" },
        { label: "Zimbabwean Dollar", value: "ZWL" }
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none', // Custom border color
            boxShadow: 'none', // Remove default shadow
            '&:hover': {
                border: 'none', // Border color on hover
            },
            backgroundColor:"#f4f4f4",
            height:"42px",
            borderRadius:"8px"
        }),
        input: (provided) => ({
            ...provided,
            color: '#333', // Custom text color
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#fff', // Custom dropdown background
        }),
        // You can customize more components like option, singleValue, etc.
    };


    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            axios.post(`${BASE_URL_TWO}`, {
                sp: 'updBankGuarantee',
                userId: id,
                guaranteeID: selectedData?.guaranteeId,
                clientName: values?.clientName,
                startDate: values?.startDate,
                endDate: values?.endDate,
                guaranteeType: values?.guaranteType,
                guaranteeAmount: values?.amount,
                currency: values?.currency

            }, { headers }).then(() => {
                setShouldRefetch(prevState => !prevState)
                showMessage()
                // setShouldRefetch(prevState => !prevState)
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            }).catch((error) => {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  }else{
                    message.destroy();
                    message.error("Something went wrong")
                  }
            })

        },
        enableReinitialize: true
    });

    return (
        <div className="modal fade" id="kt_modal_3" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">

            <form action="" onSubmit={formik.handleSubmit}>

                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-primary-subtle d-flex justify-content-between align-items-center">
                            <h5 className="modal-title text-primary">Enter Details</h5>
                            <button type="button" className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-1x" />
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-3">

                                {/* Pair 1: Client Name and Start Date */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Client Name</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='clientName'
                                            autoComplete='off'
                                            value={formik.values.clientName}
                                            onChange={formik.handleChange}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.clientName}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Start Date</label>
                                        <input
                                            type="date"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='startDate'
                                            autoComplete='off'
                                            value={formik.values.startDate}
                                            onChange={formik.handleChange}

                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.startDate}</span>
                                    </div>
                                </div>

                                {/* Pair 2: End Date and Guarantee Type */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">End Date</label>
                                        <input
                                            type="date"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='endDate'
                                            autoComplete='off'
                                            value={formik.values.endDate}
                                            onChange={formik.handleChange}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.endDate}</span>
                                    </div>
                                    <div className='col-md-6'>
                                        <label className='form-label fw-bold fs-7 text-light-emphasi'>Type of Guarantee</label>
                                        <select
                                            name="guaranteType"
                                            value={formik.values.guaranteType}
                                            onChange={(e) => {
                                                setGuaranteeType(e.target.value)
                                                formik.handleChange(e);
                                                formik.setFieldValue('guaranteType', e.target.value)

                                            }}
                                            className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary'>
                                            <option value=''>Select Type</option>
                                            <option value='performanceBond'>Performance Bond</option>
                                            <option value='tenderBond'>Tender Bond</option>
                                            <option value='advanced payment'>Advanced Payment</option>
                                        </select>
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.guaranteType}</span>
                                    </div>
                                </div>


                                {/* Pair 3: Guarantee Amount */}
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Currency</label>
                                        <Select
                                            value={currencies.find(option => option.value === formik.values.currency) || null}
                                            onChange={selectedOption => {
                                                formik.setFieldValue('currency', selectedOption ? selectedOption.value : '')
                                            }}
                                            name='currency'
                                            options={currencies}
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.currency}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label fw-bold fs-7 text-light-emphasi">Guarantee Amount</label>
                                        <input
                                            type="number"
                                            className="form-control form-control-lg form-control-solid fs-7 bg-body-secondary"
                                            placeholder=""
                                            name='amount'
                                            autoComplete='off'
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                        />
                                        <span style={{ color: 'red' }} role='alert'>{formik.errors.amount}</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="sumbit" className="btn btn-primary" >
                                Update
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    )
}
