import { CardsWidget21, CardsWidget30 } from '../../../../_metronic/partials/widgets';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Autocomplete, Box, IconButton, TextField, Modal } from '@mui/material';
import { KTIcon } from '../../../../_metronic/helpers';
import { Delete, Edit } from '@mui/icons-material';
import { Formik, useFormik } from 'formik';
import axios from 'axios';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import Cookies from 'js-cookie';
import { message } from 'antd';
import * as Yup from 'yup';
import { useAuth } from '../../../modules/auth';
import PopUp from '../../../components/Modal/Modal';
import { VscEye } from 'react-icons/vsc';
import moment from 'moment';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';


export type FlexibleWorkDetails = {
  employeeName: string
  department: string
  flexibleWorkType: string
  reason: string
  date: string
  requestDate: string
  approvalDate: string
  employeeComment: string
  comment: string
  status: string
  FlexbleWorkId: Number
};

interface editDetails {
  original: {
    FlexbleWorkId: Number
    employeeName: string
    department: string
    flexibleWorkType: string
    reason: string
    date: string
    requestDate: string
    approvalDate: string
    employeeComment: string
    comment: string
    status: string
    StartDate: string
    EndDate: string
    StartTime: string
    EndTime: string
    FormattedDate: string
  }
}

interface counts {
  HalfDay: Number
  RemoteWork: Number
}

interface DepartmentInfo {
  deptId: Number;
  deptName: string
}

interface TermCondition {
  FlexWorkType: string;
  contentText: string;
}

interface NewFlexibleWorkRequestDetails {
  id: string;
  original: {
    employeeName: string;
    department: string;
    flexibleWorkType: string;
    reason: string;
    date: string;
    requestDate: string;
    approvalDate: string;
    employeeComment: string;
    comment: string
  };
}

const validationSchema = Yup.object().shape({
  FlexWorkType: Yup.string().required("Flex type is required"),
  reason: Yup.string()
    .trim()
    .required("Reason is required")
    .test('not-only-whitespace', 'Reason is required', (value) => {
      return value !== '' && value.trim() !== '';
    }),
  comments: Yup.string(),
  dates: Yup.string().required("Date is required"),
});

const validationSchema2 = Yup.object().shape({
  FlexWorkType: Yup.string().required("Flex type is required"),
  reason: Yup.string()
    .trim()
    .required("Reason is required")
    .test('not-only-whitespace', 'Reason is required', (value) => {
      return value !== '' && value.trim() !== '';
    }),
  comments: Yup.string(),
  StartDate: Yup.string()
    .required("StartDate is required"),

  EndDate: Yup.string()
    .required("EndDate is required")
    .oneOf([Yup.ref('StartDate')], 'EndDate must match StartDate'),
  StartTime: Yup.string().required("StartTime is required"),
  EndTime: Yup.string()
    .required("EndTime is required")
    .test("is-greater", "EndTime must be greater than StartTime", function (value) {
      const { StartTime } = this.parent;
      return value > StartTime;
    })
});


const FlexibleWorkPage: React.FC = () => {

  const [typeHalfDayData, setTypeHalfDayData] = useState<FlexibleWorkDetails[]>([]);
  const [typeRemoteWorkData, setTypeRemoteWorkData] = useState<FlexibleWorkDetails[]>([]);
  const [showInitialText, setShowInitialText] = useState(true);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('default');
  const { currentUser } = useAuth()
  const [departmentInfo, setDepartmentInfo] = useState<DepartmentInfo>();
  const [termsAndConditions, setTermsAndConditions] = useState<TermCondition[]>([])
  const [editData, setEditData] = useState<editDetails | null>(null)
  const [show, setShow] = useState(false);
  const [actionStatus, setActionStatus] = useState(false);
  const [deletId, setDeletId] = useState<number | undefined>(undefined)
  const [isChecked, setIsChecked] = useState(false);
  const [requestCounts, setRequestCounts] = useState<counts | null>(null);
  const [selectedRow, setSelectedRow] = useState<NewFlexibleWorkRequestDetails | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate()



  const fetchDepartment = async () => {
    try {
      const response = await axios.get(`${BASE_URL_TWO}`, {
        params: {
          sp: "getDepartmentByCustomer",  // Correct placement of sp in params
          userId: id  // Correct placement of userId in params
        }
      });

      const department = response.data;
      setDepartmentInfo(department?.Data[0])
      // Handle department data here
    } catch (error) {
      // Handle error here
      console.error('Error fetching department:', error);
    }
  };

  const fetchTermsAndConditions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}getFlexWorkTermsConditions`);
      setTermsAndConditions(response?.data?.Data)
    } catch (error) {
      console.error("error fetching terms and conditions", error)
    }
  }

  const fetchRemoteWorkRequests = async () => {
    try {
      const response = await axios.get(`${BASE_URL_TWO}`, {
        headers,
        params: {
          sp: "getEmployeeFlexWorkLeaveRemote",
          userId: Number(id),
        },
      });
      setTypeRemoteWorkData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
      console.log("fetch request history error", error)
    }
  }

  const fetchHalfWorkRequests = async () => {
    try {
      const response = await axios.get(`${BASE_URL_TWO}`, {
        headers,
        params: {
          sp: "getEmployeeFlexWorkLeaveHalf",
          userId: Number(id),
        },
      });
      setTypeHalfDayData(response?.data?.Data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
      console.log("fetch request history error", error)
    }
  }

  const fetchCount = async () => {
    try {
      const response = await axios.get(`${BASE_URL_TWO}`, {
        params: {
          sp: "getFlexWorkHalfRemoteCounts",
          userId: Number(id),
        },
      })
      setRequestCounts(response.data?.Data[0])

    } catch (error: any) {
      message.error(error?.Message || "Something went Wrong");
      console.log("fetch request history error", error)
    }
  }

  useEffect(() => {
    fetchTermsAndConditions();
    fetchDepartment()
    fetchRemoteWorkRequests();
    fetchHalfWorkRequests();
    fetchCount();
  }, [])


  const handleTermsConditionsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    formik.setFieldValue('FlexWorkType', event.target.value)
  };

  const [stDate, setStDate] = useState("");
  const [enDate, setEnDate] = useState("")

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const handleDateRangeChange = (event:any, picker:any) => {
    const startDate = picker.startDate.toDate();
    const endDate = picker.endDate.toDate();

    const formattedStartDate = startDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    });

    const formattedEndDate = endDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    });

    const startingDate = new Date(startDate);
    const day = startingDate.getDate().toString().padStart(2, '0');
    const month = (startingDate.getMonth() + 1).toString().padStart(2, '0');
    const year = startingDate.getFullYear();

    const formattedStartingDate = `${day}/${month}/${year}`;

    const endingDate = new Date(endDate);
    const day1 = endingDate.getDate().toString().padStart(2, '0');
    const month2 = (endingDate.getMonth() + 1).toString().padStart(2, '0');
    const year3 = endingDate.getFullYear();

    const formattedEndingngDate2 = `${day1}/${month2}/${year3}`;

    setStDate(formattedStartingDate);
    setEnDate(formattedEndingngDate2);
    setDateRange({
        startDate: startDate,
        endDate: endDate,
    });

    // Calculate the difference in days
    const startDateInMs = startDate.getTime();
    const endDateInMs = endDate.getTime();

    const differenceBtwDates = endDateInMs - startDateInMs;
    const aDayInMs = 24 * 60 * 60 * 1000;

    // Calculate days difference
    const daysDiff = Math.floor(differenceBtwDates / aDayInMs);

    // If the difference is 0, it means start and end dates are the same day.
    // If daysDiff is 1, it means they are consecutive days, so set it to 2.
    const A = daysDiff === 0 ? 1 : daysDiff + 1; // Adjust for inclusive counting

    formik.setFieldValue('dates', `${formattedStartingDate} - ${formattedEndingngDate2} (${A} days)`);
};

  function formatDate(dateString: any) {
    const parts = dateString.split('/');
    const date = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formatedStartingDate = formatDate(stDate)
  const formatedEndingDate = formatDate(enDate)

  const toggleContent = () => {
    setShowInitialText(false);
    setShowAdditionalInfo(true);
  };

  const handleOpenModal = (row: NewFlexibleWorkRequestDetails) => {
    setSelectedRow(row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedRow(null);
    setModalOpen(false);
  };


  const columns = useMemo<MRT_ColumnDef<FlexibleWorkDetails>[]>(
    () => [
      {
        accessorFn: (row) => row.flexibleWorkType,
        id: 'flexibleWorkType',
        header: 'Flexible Work Type',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.reason,
        id: 'reason',
        header: 'Reason',
        size: 200,
        enablePinning: false,
      },

      {
        accessorFn: (row) => row.date,
        id: 'Date',
        header: 'Date',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          return value ? value : '';
        },

      },
      {
        accessorFn: (row) => new Date(row.requestDate), //convert to Date for sorting and filtering
        id: 'requestDate',
        header: 'Request Date',
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
      },
      {
        accessorFn: (row) => row.approvalDate ? new Date(row.approvalDate) : null,
        id: 'approvalDate',
        header: 'Approval Date',
        filterFn: 'dateFilter',
        sortingFn: 'datetime',
        size: 200,
        enablePinning: false,
        Cell: ({ cell }) => cell.getValue<Date>() ? cell.getValue<Date>()?.toLocaleDateString() : 'Not Approved', //render Date as a string
      },
      {
        accessorFn: (row) => row.employeeComment ? row.employeeComment : "N/A",
        id: 'employeeComment',
        header: 'Employee Comment',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.comment ? row.comment : 'N/A',
        id: 'comment',
        header: 'Comment',
        size: 200,
        enablePinning: false,
      },
      {
        accessorFn: (row) => row.status,
        id: 'status',
        header: 'Status',
        size: 200,
        enablePinning: false,
        Cell: ({ row }) => {
          const value = row.original.status;
          const statusClass =
            value === 'Approved' ? 'badge badge-light-success fs-7 fw-semibold' :
              value === 'Rejected' ? 'badge badge-light-danger fs-7 fw-semibold' :
                value === 'Pending' ? 'badge badge-light-warning fs-7 fw-semibold' :
                  ''; // Default class if needed

          return <span className={statusClass}>{value}</span>;
        },
      }
    ],
    []
  );

  const [dateState, setDateState] = useState<{
    startDate: Date | null;
    endDate: Date | null;
    selectedDays: number;
  }>({
    startDate: null,
    endDate: null,
    selectedDays: 0
  });



  const inputRef = useRef<HTMLInputElement>(null);

  // const (handleDateRangeChange) = (
  //   event: any,
  //   picker: { startDate: { toDate: () => Date }; endDate: { toDate: () => Date } }
  // ) => {
  //   const startDate = picker.startDate.toDate();
  //   const endDate = picker.endDate.toDate();

  //   // Calculate the difference in milliseconds between the start and end dates
  //   const differenceInMs = endDate.getTime() - startDate.getTime();
  //   // Convert the difference to days
  //   const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

  //   setDateState({
  //     startDate,
  //     endDate,
  //     selectedDays: differenceInDays // Update the number of selected days
  //   });

  //   if (inputRef.current) {
  //     inputRef.current.value = formatDateRange(startDate, endDate);
  //   }
  // };

  const formatDateRange = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
      return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()} (${dateState.selectedDays} days)`;
    }
    return '';
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const Token = Cookies.get('token')
  const id = Cookies.get('userId')

  const headers = {
    'token': Token,
    'Content-Type': 'application/json'
  };

  const showMessage = () => {
    message?.destroy();
    message.success({
      content: 'flex work request submitted',
      duration: 1 // Duration in seconds
    });
  };

  const errorMessage = () => {
    message.destroy();
    message.info({
      content: 'flex work request not submitted',
      duration: 2
    });
  }

  const combineDateTime = (date: string, time: string) => {
    const [year, month, day] = date.split('-');
    const [hour, minute] = time.split(':');

    const combinedDateTime = new Date(
      Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute))
    );

    const formattedDateTime = `${combinedDateTime.getFullYear()}-${padZero(combinedDateTime.getMonth() + 1)}-${padZero(combinedDateTime.getDate())} ${padZero(combinedDateTime.getUTCHours())}:${padZero(combinedDateTime.getUTCMinutes())}:00.000`;

    return formattedDateTime;
  }

  const padZero = (value: number) => {
    return (value < 10 ? '0' : '') + value;
  }

  const isValidDateTimeString=(datenew:any) =>{
    // Regex to match the format YYYY-MM-DD HH:mm:ss.SSS
    const regex = /^\d{4}-\d{2}-\d{2}(?: \d{2}:\d{2}:\d{2}\.\d{3})?$/;
  
    // First, check if the string matches the date-time format
    if (!regex.test(datenew)) {
      return false;
    }
    return true;
  }

  const formik = useFormik({
    initialValues: {
      FlexWorkType: editData?.original.flexibleWorkType || '',
      reason: editData?.original.reason || '',
      comments: editData?.original.comment || '',
      dates: editData?.original.date || '',
      StartDate: editData?.original.flexibleWorkType === "Half Day" ? editData?.original.StartDate : '',
      EndDate: editData?.original.flexibleWorkType === "Half Day" ? editData?.original.EndDate : '',
      StartTime: editData?.original.flexibleWorkType === "Half Day" ? editData?.original.StartTime : '',
      EndTime: editData?.original.flexibleWorkType === "Half Day" ? editData?.original.EndTime : ''
    },
    validationSchema: selectedOption === "Remote Work" ? validationSchema : validationSchema2,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: async (values: { reason: any; StartDate: any; StartTime: any; EndDate: any; EndTime: any; comments: any; }, { resetForm }: any) => {

      if (isChecked) {
        try {
          const response = await axios.post(`${BASE_URL_TWO}`, {
            "sp": "insFlexWorkLeaveRequest",
            "FlexWorkType": selectedOption,
            "reason": values.reason,
            "StartDate": selectedOption === "Remote Work" ? isValidDateTimeString(formatedStartingDate)?formatedStartingDate: editData?.original.StartDate : combineDateTime(values.StartDate, values.StartTime),
            "EndDate": selectedOption === "Remote Work" ? isValidDateTimeString(formatedEndingDate)?formatedStartingDate: editData?.original.EndDate : combineDateTime(values.EndDate, values.EndTime),
            "comments": values.comments,
            "userId": id,
            "deptId": departmentInfo?.deptId,
            "FlexbleWorkId": editData?.original.FlexbleWorkId || null
          }, { headers });
          resetForm();
          showMessage();
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        } catch (error:any) {
          if (error.response?.status === 401) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName, { path: '/' });
            });
            navigate('/error/login')
          } else {
            errorMessage()
          }
        }
      } else {
        toggleContent();
        message.error("Please accept the terms and conditions");
      }
    }
  });

  useEffect(() => {
    if (editData) {
      formik.setValues({
        FlexWorkType: editData.original.flexibleWorkType,
        reason: editData.original.reason,
        comments: editData.original.employeeComment,
        dates: editData.original.FormattedDate,
        StartDate: editData.original.StartDate,
        EndDate: editData.original.EndDate,
        StartTime: editData.original.StartTime,
        EndTime: editData.original.EndTime,
      });
      setSelectedOption(editData.original.flexibleWorkType)
    }
  }, [editData]);

  const handleEdit = (row: any) => {
    setEditData(row)
  }

  const handleDelete = async () => {
    if (actionStatus && deletId) {
      try {
        const response = await axios.post(`${BASE_URL_TWO}`, {
          "sp": "delFlexWorkLeave",
          "userId": Number(id),
          "FlexbleWorkId": deletId
        }, { headers })
        message.success("deleted successfully")
        setDeletId(undefined)
        setActionStatus(false)
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      } catch (error: any) {
        if (error.response?.status === 401) {
          Object.keys(Cookies.get()).forEach((cookieName) => {
            Cookies.remove(cookieName, { path: '/' });
          });
          navigate('/error/login')
        } else {
          message.destroy();
          message.error(error?.Message || "Something went Wrong");
        }
        console.log("fetch pending details error", error)
      }
    }
  }

  useEffect(() => {
    handleDelete();
  }, [actionStatus, deletId])

  return (
    <div>
      <PopUp
        show={show}
        setShow={setShow}
        setActionStatus={setActionStatus}
        title="Delete flexwork request"
        message="Are you sure you want to delete"
        btnName="Delete"
        update=''
      />
      <h5 className="text-black-50 mb-10">
        <i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>
        flexible work
      </h5>

      <div className='row g-3 g-md-5 g-xl-10 d-flex justify-content-center'>
        {/* Half day */}
        <div className='col-12 col-sm-6 col-md-4 text-white' style={{ width: '32.5%' }}>
          <a
            className="text-white"
            data-bs-toggle="modal"
            href="#kt_modal_2"
            role="button"
            aria-expanded="false"
            aria-controls="kt_modal_2"
          >
            <CardsWidget30
              className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
              color='#6255a3'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Half Day'
              description={requestCounts?.HalfDay ? String(requestCounts?.HalfDay) : '0'}
            />
          </a>
        </div>

        <div className="modal fade" tabIndex={-1} id="kt_modal_2">
          <div className="modal-dialog modal-xl">
            <div className="modal-content shadow-none bg-white">
              <div className="modal-header">
                <h5 className="modal-title">Half Day</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">

                <div className='row mt-md-2 mt-xl-4 mb-md-4 mb-md-8 mb-5'>
                  <div className='col-12 d-flex justify-content-between align-items-center'>

                  </div>
                </div>

                <MaterialReactTable
                  columns={columns}
                  data={typeHalfDayData}
                  enableDensityToggle={false}
                  enableColumnFilters
                  enableColumnOrdering
                  enablePinning
                  enableRowActions
                  initialState={{
                    showColumnFilters: false,
                    sorting: [
                      { id: "employeeName", desc: false },
                    ],
                    expanded: true,
                    columnPinning: { left: ["employeeName"] }
                  }}
                  positionToolbarAlertBanner="bottom"
                  positionActionsColumn='last'
                  renderRowActions={({ row, table }) => [
                    <Box sx={{ width: "120px", display: "flex", justifyContent: 'center' }}>
                      {row.original.status === "Pending" && <IconButton
                        onClick={() => {
                          handleEdit(row)
                        }}
                        data-bs-dismiss="modal"
                        title="Edit"
                      >
                        <Edit className='text-success' />
                      </IconButton>}
                      {row.original.status === "Pending" && <IconButton onClick={() => {
                        setDeletId(row.original.FlexbleWorkId as number | undefined)
                        setShow(true)
                      }}
                      title="Delete"
                      >
                        <Delete className='text-danger' />
                      </IconButton>}
                      <IconButton className='px-2' onClick={() => handleOpenModal(row)}
                      title="View"
                        >
                        <VscEye />
                      </IconButton>
                    </Box>
                  ]}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Action',
                      size: 80,
                      muiTableHeadCellProps: {
                        className: 'px-7 py-4 text-black',
                        sx: {
                          alignItems: 'center',
                          verticalAlign: 'middle',
                          backgroundColor: '#EBEBD3 !important',
                          outline: '6px',
                          outlineStyle: 'solid',
                          outlineColor: 'var(--bs-light)',
                          outlineOffset: '-5px',
                          borderRadius: '20px',
                        }
                      }
                    },
                  }}
                  sortingFns={{
                    status: (rowA, rowB, columnID) => {
                      const valA = rowA.getValue<String>(columnID)
                      const valB = rowB.getValue<String>(columnID)
                      if (valA === 'Pending' && valB !== 'Pending') {
                        return 1
                      }
                      else if (valB === 'Pending' && valA !== 'Pending') {
                        return -1
                      }
                      else {
                        return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                      }
                    }
                  }}
                  filterFns={{
                    dateFilter: (row, columnId, filterValue) => {
                      const filterArray = filterValue.replaceAll(',', ';').split(';')
                      const cellVal = row.getValue<Date>(columnId)
                      const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
                      for (let item of filterArray) {
                        const itemArray = item.replaceAll(/\s+/g, '#').split('#')
                        if (cellVal.getFullYear().toString() === itemArray[itemArray.length - 1])
                          for (let i = 0; i < itemArray.length - 1; i++) {
                            if (itemArray[i].includes(monthName[cellVal.getMonth()]))
                              return true
                          }
                      }
                      return false
                    },
                    multipleFilter: (row, columnId, filterValue) => {
                      const filterArray = filterValue.replaceAll(',', ';').split(';')
                      const cellVal = row.getValue<String>(columnId)
                      for (let item of filterArray) {
                        if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                          return true
                      }
                      return false
                    }
                  }}

                  muiTablePaperProps={{
                    sx: {
                      backgroundColor: 'white !important',
                      border: 'none',
                      boxShadow: 'none'
                    }
                  }}
                  muiTableProps={{
                    className: 'table',
                    sx: {
                      borderCollapse: 'separate',
                      borderSpacing: '0px 4px',
                    }
                  }}
                  muiTableContainerProps={{
                    className: 'mb-2',
                    sx: {
                      backgroundColor: 'var(--bs-light)',
                      borderRadius: 'var(--bs-card-border-radius)',
                    }
                  }}
                  renderEmptyRowsFallback={() => (
                    <Box sx={{ textAlign: 'center', fontSize: '1.2rem', color: '#999', padding: '1rem' }}>
                      No records found
                    </Box>
                  )}
                  muiTableHeadRowProps={{
                    className: 'fw-bold bg-light',
                    sx: {
                      boxShadow: 'none'
                    }
                  }}
                  muiTableHeadCellProps={{
                    className: 'px-7 py-3 text-black',
                    sx: {
                      verticalAlign: 'middle',
                      backgroundColor: '#EBEBD3 !important',
                      outline: '10px',
                      outlineStyle: 'solid',
                      outlineColor: 'var(--bs-light)',
                      outlineOffset: '-5px',
                      borderRadius: '20px',
                    }
                  }}
                  muiTableBodyRowProps={{
                    hover: false,
                    sx: {
                      backgroundColor: 'transparent'
                    }
                  }}
                  muiTableBodyCellProps={{
                    className: 'bg-light bg-hover-secondary'
                  }}
                  muiTopToolbarProps={{
                    className: 'card text-light mb-2',
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: '#143983'
                    },
                  }}
                  muiBottomToolbarProps={{
                    className: 'text-light mb-5 d-flex',
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: '#143983',
                      borderRadius: 'var(--bs-card-border-radius)',
                      minHeight: '50px'
                    },
                  }}
                  muiSearchTextFieldProps={{
                    className: 'bg-light text-light text-muted'
                  }}
                  // muiTableBodyCellFilterTextFieldProps={{
                  //   helperText: false,
                  //   focused: true,
                  //   inputProps: {
                  //     className: "text-black",
                  //   },
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  // muiTableHeadCellColumnActionsButtonProps={{
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  // muiTableHeadCellDragHandleProps={{
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  icons={{
                    FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                    FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                    ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                    FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                    FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                    SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                    CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                  }}
                  // muiTablePaginationProps={{
                  //   className: 'text-white',
                  // }}
                  enableGlobalFilter={false}
                  muiToolbarAlertBannerProps={{
                    className: 'text-white',
                    sx: {
                      backgroundColor: '#143983',
                    }
                  }}
                  muiToolbarAlertBannerChipProps={{
                    className: 'text-white'
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div className="modal-dialog" style={{
            maxWidth: '800px', margin: '1.75rem auto', display: 'flex',           // Flexbox layout
            justifyContent: 'center',   // Center horizontally
            alignItems: 'center',       // Center vertically
            minHeight: '100vh',
          }}>
            <div className="modal-content" style={{ backgroundColor: 'white', borderRadius: '4px', padding: '20px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.3)' }}>
              <div className="d-flex justify-content-between" style={{ borderBottom: '1px solid #e5e5e5', paddingBottom: '15px' }}>
                <h5 className="modal-title  pt-2" style={{ marginBottom: '0' }}>Request Details</h5>
                <button
                  className="btn btn-icon btn-sm btn-active-light-primary"
                  aria-label="Close"
                  onClick={handleCloseModal}
                >
                  <MdClose />
                </button>
              </div>
              <div className="modal-body" style={{ paddingTop: '15px' }}>
                {selectedRow && (
                  <div>
                    <p><strong >Employee Name: </strong>{selectedRow.original.employeeName}</p>
                    <p><strong>Department: </strong>{selectedRow.original.department}</p>
                    <p><strong>Flexible Work Type: </strong>{selectedRow.original.flexibleWorkType}</p>
                    <p><strong>Reason: </strong>{selectedRow.original.reason}</p>
                    <p><strong>Date Range: </strong>{selectedRow.original.date}</p>
                    <p><strong>Request Date: </strong>{new Date(selectedRow.original.requestDate).toLocaleDateString()}</p>
                    <p><strong>Approval Date: </strong>{selectedRow.original.approvalDate ? new Date(selectedRow.original.approvalDate).toLocaleDateString() : "Not Approved"}</p>
                    <p><strong>Employee Comment: </strong>{selectedRow.original.employeeComment}</p>
                    <label htmlFor=""><strong>Employer Comment: </strong>{selectedRow.original.comment || 'N/A'}</label>
                  </div>
                )}
              </div>
              {/* <div className="modal-footer" style={{ borderTop: '1px solid #e5e5e5', paddingTop: '15px' }}>
                        <IconButton className='px-2' onClick={() => { }}>
                          <KTSVG path="/media/icons/duotune/arrows/arr016.svg" className="svg-icon-muted text-success svg-icon-2hx" />
                        </IconButton>
                        <IconButton className='px-2' onClick={() => { }}>
                          <KTSVG path="/media/icons/duotune/arrows/arr015.svg" className="svg-icon-muted text-danger svg-icon-2hx" />
                        </IconButton>
                      </div> */}
            </div>
          </div>
        </Modal>

        {/* Remote work */}
        <div className='col-12 col-sm-6 col-md-4 text-white' style={{ width: '32.5%' }}>
          <a
            className="text-white"
            data-bs-toggle="modal"
            href="#kt_modal_3"
            role="button"
            aria-expanded="false"
            aria-controls="kt_modal_3"
          >
            <CardsWidget30
              className='h-md-30 mb-1 mb-xl-1 p-2 h-100'
              color='#34a8cb'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              title='Remote Work'
              description={requestCounts?.RemoteWork ? String(requestCounts?.RemoteWork) : '0'}
            />
          </a>
        </div>

        <div className="modal fade" tabIndex={-1} id="kt_modal_3">
          <div className="modal-dialog modal-xl">
            <div className="modal-content shadow-none bg-white">
              <div className="modal-header">
                <h5 className="modal-title">Remote Work</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className='row mt-md-2 mt-xl-4 mb-md-4 mb-md-8 mb-5'>
                  <div className='col-12 d-flex justify-content-between align-items-center'>

                  </div>
                </div>
                <MaterialReactTable
                  columns={columns}
                  data={typeRemoteWorkData}
                  enableDensityToggle={false}
                  enableColumnFilters
                  enableColumnOrdering
                  enablePinning
                  enableRowActions
                  initialState={{
                    showColumnFilters: false,
                    sorting: [
                      { id: "employeeName", desc: false },
                    ],
                    expanded: true,
                    columnPinning: { left: ["employeeName"] }
                  }}
                  positionToolbarAlertBanner="bottom"
                  positionActionsColumn='last'
                  renderRowActions={({ row, table }) => [
                    <Box sx={{ width: "120px", display: "flex", justifyContent: 'center' }}>
                      {row.original.status === "Pending" && <IconButton
                        onClick={() => {
                          handleEdit(row)
                        }}
                        data-bs-dismiss="modal"
                        title="Edit"
                      >
                        <Edit className='text-success' />
                      </IconButton>}
                      {row.original.status === "Pending" && <IconButton onClick={() => {
                        setDeletId(row.original.FlexbleWorkId as number | undefined)
                        setShow(true)
                      }}
                      title='Delete'
                      >
                        <Delete className='text-danger' />
                      </IconButton>}
                      <IconButton className='px-2' onClick={() => handleOpenModal(row)}
                        title='View'
                        >
                        <VscEye />
                      </IconButton>
                    </Box>
                  ]}
                  displayColumnDefOptions={{
                    'mrt-row-actions': {
                      header: 'Action',
                      size: 80,
                      muiTableHeadCellProps: {
                        className: 'px-7 py-4 text-black',
                        sx: {
                          alignItems: 'center',
                          verticalAlign: 'middle',
                          backgroundColor: '#EBEBD3 !important',
                          outline: '6px',
                          outlineStyle: 'solid',
                          outlineColor: 'var(--bs-light)',
                          outlineOffset: '-5px',
                          borderRadius: '20px',
                        }
                      }
                    },
                  }}
                  sortingFns={{
                    status: (rowA, rowB, columnID) => {
                      const valA = rowA.getValue<String>(columnID)
                      const valB = rowB.getValue<String>(columnID)
                      if (valA === 'Pending' && valB !== 'Pending') {
                        return 1
                      }
                      else if (valB === 'Pending' && valA !== 'Pending') {
                        return -1
                      }
                      else {
                        return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                      }
                    }
                  }}
                  filterFns={{
                    dateFilter: (row, columnId, filterValue) => {
                      const filterArray = filterValue.replaceAll(',', ';').split(';')
                      const cellVal = row.getValue<Date>(columnId)
                      const monthName = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sept', 'oct', 'nov', 'dec']
                      for (let item of filterArray) {
                        const itemArray = item.replaceAll(/\s+/g, '#').split('#')
                        if (cellVal.getFullYear().toString() === itemArray[itemArray.length - 1])
                          for (let i = 0; i < itemArray.length - 1; i++) {
                            if (itemArray[i].includes(monthName[cellVal.getMonth()]))
                              return true
                          }
                      }
                      return false
                    },
                    multipleFilter: (row, columnId, filterValue) => {
                      const filterArray = filterValue.replaceAll(',', ';').split(';')
                      const cellVal = row.getValue<String>(columnId)
                      for (let item of filterArray) {
                        if (cellVal.toLocaleLowerCase().includes(item.toLocaleLowerCase()))
                          return true
                      }
                      return false
                    }
                  }}

                  muiTablePaperProps={{
                    sx: {
                      backgroundColor: 'white !important',
                      border: 'none',
                      boxShadow: 'none'
                    }
                  }}
                  muiTableProps={{
                    className: 'table',
                    sx: {
                      borderCollapse: 'separate',
                      borderSpacing: '0px 4px',
                    }
                  }}
                  renderEmptyRowsFallback={() => (
                    <Box sx={{ textAlign: 'center', fontSize: '1.2rem', color: '#999', padding: '1rem' }}>
                      No records found
                    </Box>
                  )}
                  muiTableContainerProps={{
                    className: 'mb-2',
                    sx: {
                      backgroundColor: 'var(--bs-light)',
                      borderRadius: 'var(--bs-card-border-radius)',
                    }
                  }}
                  muiTableHeadRowProps={{
                    className: 'fw-bold bg-light',
                    sx: {
                      boxShadow: 'none'
                    }
                  }}
                  muiTableHeadCellProps={{
                    className: 'px-7 py-3 text-black',
                    sx: {
                      verticalAlign: 'middle',
                      backgroundColor: '#EBEBD3 !important',
                      outline: '10px',
                      outlineStyle: 'solid',
                      outlineColor: 'var(--bs-light)',
                      outlineOffset: '-5px',
                      borderRadius: '20px',
                    }
                  }}
                  muiTableBodyRowProps={{
                    hover: false,
                    sx: {
                      backgroundColor: 'transparent'
                    }
                  }}
                  muiTableBodyCellProps={{
                    className: 'bg-light bg-hover-secondary'
                  }}
                  muiTopToolbarProps={{
                    className: 'card text-light mb-2',
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: '#143983'
                    },
                  }}
                  muiBottomToolbarProps={{
                    className: 'text-light mb-5 d-flex',
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: '#143983',
                      borderRadius: 'var(--bs-card-border-radius)',
                      minHeight: '50px'
                    },
                  }}
                  muiSearchTextFieldProps={{
                    className: 'bg-light text-light text-muted'
                  }}
                  // muiTableBodyCellFilterTextFieldProps={{
                  //   helperText: "", // If you want to provide some helper text or leave it as an empty string
                  //   focused: true, // This prop should be used only if applicable for your specific use case
                  //   InputProps: { // Correct capitalization for inputProps
                  //     className: "text-black", // Ensure this class is defined in your CSS
                  //   },
                  //   sx: { // Make sure `sx` is valid in the context you are using it
                  //     color: 'black',
                  //   },
                  // }}
                  // muiTableHeadCellColumnActionsButtonProps={{
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  // muiTableHeadCellDragHandleProps={{
                  //   sx: {
                  //     color: 'black'
                  //   }
                  // }}
                  icons={{
                    FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
                    FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
                    ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
                    FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                    FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
                    SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
                    CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
                  }}
                  // muiTablePaginationProps={{
                  //   className: 'text-white',
                  // }}
                  enableGlobalFilter={false}
                  muiToolbarAlertBannerProps={{
                    className: 'text-white',
                    sx: {
                      backgroundColor: '#143983',
                    }
                  }}
                  muiToolbarAlertBannerChipProps={{
                    className: 'text-white'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid mt-5">
        <div className="d-flex justify-content-center">
          <div className='card mb-5 mb-xl-10' style={{ width: '65%' }}>
            <div
              className='card-header border-0 cursor-pointer bg-primary'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target='#kt_account_profile_details'
              aria-expanded='true'
              aria-controls='kt_account_profile_details'

            >
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0 text-white'>Request Flexible Work</h3>
              </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
              <form className='form' onSubmit={formik.handleSubmit}>
                <div className='card-body border-top p-9 text-primary'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=' text-primary'>
                        <i className="bi-person text-primary me-3 fs-3"></i>Employee Name
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <input
                            id='employeeName'
                            name='employeeName'
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary text-black'
                            value={currentUser?.firstName + " " + currentUser?.lastName}
                            readOnly
                            style={{ fontSize: 13 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=' text-primary'>
                        <i className="bi bi-layers text-primary me-3 fs-3"></i>Department
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <input
                            id='department'
                            name='department'
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary text-black'
                            value={departmentInfo?.deptName}
                            readOnly
                            style={{ fontSize: 13 }}

                          />

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'> <i className="bi bi-collection text-primary me-3 fs-2"></i> Flexible Work Type</span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <select className="form-select form-select-solid bg-body-secondary border border-primary" name="FlexWorkType" aria-label="Select example" value={selectedOption} onChange={handleTermsConditionsChange} style={{ fontSize: 13 }}>
                            <option value="">Select an option</option>
                            <option value="Half Day">Half Day</option>
                            <option value="Remote Work">Remote Work</option>
                          </select>
                        </div>
                        {formik.touched.FlexWorkType && formik.errors.FlexWorkType && (
                          <div className="text-danger fw-bold fs-8">{formik.errors.FlexWorkType}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required text-primary'>
                        <i className="bi bi-text-left text-primary me-3 fs-3"></i>Reason For Taking Flexible Work
                      </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <input
                            id='reason'
                            name='reason'
                            type='text'
                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                            placeholder='Reason'
                            style={{ fontSize: 13 }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.reason}
                          />
                          {formik.touched.reason && formik.errors.reason && (
                            <div className="text-danger fw-bold fs-8">{formik.errors.reason}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedOption === 'Half Day' && (
                    <>
                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span className='required text-primary'>
                            <i className="bi bi-calendar-check text-primary me-3 fs-2"></i> Start Date / Time
                          </span>
                        </label>
                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-sm-6 fv-row'>
                              <input
                                type='date'
                                className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary border border-primary'
                                placeholder=''
                                name="StartDate"
                                onChange={formik.handleChange}
                                value={formik.values.StartDate}
                              />
                              {formik.touched.StartDate && formik.errors.StartDate && (
                                <div className="text-danger fw-bold fs-8">{formik.errors.StartDate}</div>
                              )}
                            </div>
                            <div className='col-sm-6 fv-row'>
                              <input
                                type='time'
                                className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary border border-primary'
                                placeholder=''
                                name="StartTime"
                                onChange={formik.handleChange}
                                value={formik.values.StartTime}
                              />
                              {formik.touched.StartTime && formik.errors.StartTime && (
                                <div className="text-danger fw-bold fs-8">{formik.errors.StartTime}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                          <span className='required text-primary'>
                            <i className="bi bi-calendar-check text-primary me-3 fs-2"></i> End Date / Time
                          </span>
                        </label>
                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-sm-6 fv-row'>
                              <input
                                type='date'
                                name="EndDate"
                                className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary border border-primary'
                                placeholder=''
                                onChange={formik.handleChange}
                                value={formik.values.EndDate}

                              />
                              {formik.touched.EndDate && formik.errors.EndDate && (
                                <div className="text-danger fw-bold fs-8">{formik.errors.EndDate}</div>
                              )}
                            </div>
                            <div className='col-sm-6 fv-row'>
                              <input
                                type='time'
                                className='form-control form-control-lg form-control-solid fs-7 bg-body-secondary border border-primary'
                                placeholder=''
                                name="EndTime"
                                onChange={formik.handleChange}
                                value={formik.values.EndTime}
                              />
                              {formik.touched.EndTime && formik.errors.EndTime && (
                                <div className="text-danger fw-bold fs-8">{formik.errors.EndTime}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {selectedOption === 'Remote Work' && (
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required text-primary'>
                          <i className="bi bi-calendar-check text-primary me-3 fs-2"></i> Date
                        </span>
                      </label>
                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col-lg-12 fv-row'>
                            <DateRangePicker
                              initialSettings={{
                                autoUpdateInput: false,
                                startDate: editData ? moment(editData.original.StartDate) : undefined,
                                endDate: editData ? moment(editData.original.EndDate) : undefined,
                              }}
                              onApply={(event, picker) => {
                                const startDate = picker.startDate;
                                const endDate = picker.endDate;
                                  handleDateRangeChange(event, picker);

                              }}
                            >
                              <div>
                                <input
                                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-body-secondary border border-primary'
                                  type="text"
                                  readOnly={true}
                                  name='dates'
                                  value={formik.values.dates}
                                />
                                {formik.touched.dates && formik.errors.dates && (
                                  <div className="text-danger fw-bold fs-8">{formik.errors.dates}</div>
                                )}
                              </div>
                            </DateRangePicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='text-primary'><i className="bi bi bi-text-left text-primary me-3 fs-2 "></i>Other / Comments </span>
                    </label>
                    <div className='col-lg-8'>
                      <div className='row'>
                        <div className='col-lg-13 fv-row'>
                          <textarea className="form-control bg-body-secondary border border-primary" style={{ fontSize: 12 }}
                            placeholder='Tasks/Projects to be completed - additional Comments'
                            name='comments'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.comments}
                          ></textarea>

                        </div>
                      </div>
                    </div>
                  </div>


                  {(selectedOption === 'Remote Work' || selectedOption === "Half Day") && <div className="container-fluid mt-5">
                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                      <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-bold">
                          <div className="fs-7 text-gray-600">
                            {showInitialText && (
                              <>
                                <div className='me-50'>
                                  {showInitialText && (
                                    <>
                                      <a
                                        className="btn btn-icon btn-active-light-primary pulse pulse-primary me-3" onClick={toggleContent}>
                                        <i className="bi-shield-fill-exclamation fs-1 text-primary"></i>
                                        <span className="pulse-ring"></span>
                                      </a>
                                      <span className='text-primary fs-6'>View Terms & Conditions</span>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                            {showAdditionalInfo && (
                              <>
                                <div className="d-flex flex-column me-10" id="additional-info">
                                  {termsAndConditions.filter(item => item.FlexWorkType === selectedOption)?.map((item)=>(<li className="d-flex align-items-center py-2">
                                    <span  dangerouslySetInnerHTML={{ __html: item?.contentText }}></span>
                                  </li>))}
                                </div>
                                <div className='col-lg-9 fv-row'>
                                  <span>
                                    <div className='d-flex align-items-center mt-3'>
                                      <label className='form-check form-check-inline form-check-solid'>
                                        <input
                                          className='form-check-input border border-primary-subtle'
                                          name='communication[]'
                                          id="flexCheckDefault"
                                          type='checkbox'
                                          checked={isChecked}
                                          onChange={(e) => setIsChecked(e.target.checked)}
                                        />
                                        <span className='fw-bold ps-2 fs-6 required text-primary'>I have read and accepted all the terms & conditions.
                                        </span>
                                      </label>
                                    </div>
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
                <div className='card-footer d-flex py-6 px-9 justify-content-end'>
                  <div className=''>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" aria-controls='confirmationSubmit' data-bs-target="#confirmationSubmit" aria-expanded="false">
                      Submit
                    </button>
                    <div className="modal fade" tabIndex={-1} id="confirmationSubmit">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header d-flex justify-content-between p-4">
                            <h5 className="modal-title pt-2">Confirmation</h5>
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-active-light-primary btn-close me-1"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <p>Are you sure you want to submit this request?</p>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default FlexibleWorkPage;
