
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils";
import Cookies from "js-cookie";
import { message } from "antd";
import { useNavigate } from "react-router-dom";


const useFetchDataByCheck = (url) => {
    const [fetchedData, setFetchedData] = useState();
    const navigate = useNavigate()

    const id = Cookies.get('userId');
    const token = Cookies.get("token")

    const headers = {
        'token': token,
        'Content-Type': 'application/json'
    };

    // console.log("A", A);

    useEffect(() => {
        const fetchData = async () => {

            const response = await axios.get(`${BASE_URL + url}&userId=${id}&multiple=true`,
                { headers }
            ).then((res) => {
                // console.log("response...............from hook;", res)
                setFetchedData(res?.data?.Data)
            }).catch((error)=>{
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  } else {
                    message.destroy();
                    message.error("Something went wrong")
                  }
            })

        };

        fetchData();
    }, []);

    return { fetchedData };
};

export default useFetchDataByCheck;