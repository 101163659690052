import React, { FC, useEffect, useState } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { BASE_URL, BASE_URL_TWO } from '../../../../../../utils';
import Cookies from 'js-cookie';
import { message } from 'antd';
import axios from 'axios';
import PopUp from '../../../../../components/Modal/Modal';

// interface StepProps {
//   setSteps: (step: string) => void;
//   // Define other props if needed
// }

const Step5 = (props) => {

  const { setSteps, employeDet, personalDetails, corporateDetails, jobDescriptionBenifits, familyDetails, status } = props
  const id = Cookies.get('userId');
  const token = Cookies.get("token")
  const [deactivateStatus, setDeactivateStatus] = useState(status);
  const [show, setShow] = useState()
  const [isActive, setIsActive] = useState(status)
  const [actionStatus, setActionStatus] = useState()

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };
  const postData = {
    personal: personalDetails,
    corporateDetails: corporateDetails,
    jobDescriptionBenifits: jobDescriptionBenifits,
    familyDetails: JSON.parse(localStorage.getItem('familyMembers2')),
    status: deactivateStatus
  };

  const showMessage = () => {
    message.success({
      content: 'Successfully updated user',
      duration: 4 // Duration in seconds
    });
  };

  const showError = (msg) => {
    message.error({
      content: msg,
      duration: 4 // Duration in seconds
    });
  }

  const handleSubmit = () => {
    try {
      axios.post(`${BASE_URL_TWO}`, {
        sp: "updEmployee",
        userId: id,
        jsonData: postData
      }, { headers }).then((response) => {
        localStorage.removeItem('jobDescription2')
        localStorage.removeItem('personalDetails2')
        localStorage.removeItem('corparateDetails2')
        localStorage.removeItem('familyMembers2')
        localStorage.removeItem('memberCount2')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
        showMessage()
      })
    } catch (error) {
      showError("Something went wrong!")
    }
  }

  const handleCheck = (e) => {
    setIsActive(e.target.checked)
  }

  useEffect(() => {
    if (actionStatus) {
      setDeactivateStatus(!isActive)
      setActionStatus(false)
    }
  }, [actionStatus])

  return (
    <div className='w-100'>
      <PopUp
        title={`${deactivateStatus ? 'De-Activate an employee' : 'Activate an employee'}`}
        message={`${deactivateStatus ? 'You Are Deactivating This Account' : 'You Are activating This Account'}`}
        btnName={`${deactivateStatus ? 'Deactivate' : 'Activate'}`}
        setActionStatus={setActionStatus}
        show={show}
        setShow={setShow}
      />
      <div className='mb-0'>
        {deactivateStatus ? (<div className='notice d-flex rounded  p-5' style={{ background: "#ddfbdd", border: "1px dashed green" }}>
          <div className='mb-6 bg-success rounded-circle  border-4 mt-6 me-3 border-white h-20px w-20px'></div>
          <div className='d-flex flex-stack flex-grow-1 mt-4' style={{ alignItems: "center" }}>
            <div className='fw-bold'>
              <p className='' style={{ fontWeight: "bold" }}>Status : <span>Active</span></p>
            </div>
          </div>
        </div>) : (<div className='notice d-flex bg-light-danger  rounded border-danger border border-dashed p-5' style={{ alignItems: "center" }}>
          <KTIcon iconName='information-5' className='fs-1 text-danger me-4' />
          <div className='d-flex flex-stack flex-grow-1 mt-4' style={{ alignItems: "center" }}>
            <div className='fw-bold'>
              <p className='' style={{ fontWeight: "bold" }}>Status : <span>DeActive</span></p>
            </div>
          </div>
        </div>)}
      </div>

      <div className="form-check form-switch form-check-custom form-check-solid me-10 mt-10">
        <input className="form-check-input h-30px w-50px cursor-pointer" type="checkbox" value="" id="flexSwitch40x60"
          checked={!deactivateStatus}
          onChange={(e) => { setShow(true); handleCheck(e); }} />
        <label className="form-check-label fw-bold text-primary" htmlFor="flexSwitch40x60">
          Deactivate Account
        </label>
      </div>

      <Grid container sx={{ bgcolor: '', justifyContent: 'space-between', mt: 4 }}>

        <div className='mr-2'>
          <button
            // onClick={prevStep}
            onClick={() => setSteps('step4')}
            type='button'
            className='btn btn-lg btn-light-primary me-3'
          // data-kt-stepper-action='previous'
          >
            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Back
          </button>
        </div>

        <div>
          <button type='submit' onClick={() => handleSubmit()} className='btn btn-lg btn-primary'>
            <span className='indicator-label'>
              Save All
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </span>
          </button>
        </div>

      </Grid>
    </div>
  )
}

export { Step5 }
