import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Card, Button } from 'react-bootstrap'; // Assuming you're using Bootstrap for cards
import { KTSVG } from '../../../../_metronic/helpers';
import { Autocomplete, AutocompleteRenderInputParams, Rating, TextField } from '@mui/material';
import useFetchCommon from '../../../hooks/getCommonData';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import { faBaseball } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../../../../utils';
import Cookies from 'js-cookie';
import { useAuth } from '../../../modules/auth';

function EmployeeKPI() {
  // const [currentTableIndex, setCurrentTableIndex] = useState(0);
  const [currentTableIndexSummary, setCurrentTableIndexSummary] = useState(0);
  const id = Cookies.get('userId');
  const token =Cookies.get('token');
  // const [showSubmit, setShowSubmit] = useState(false);
  const [isvisible, setIsvisible] = useState(true);
  const handleAlert = () => {
    setIsvisible(false);
  }
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const presentYear = new Date().getFullYear()
  const [currentYear, setCurrentYear] = useState(presentYear);
  const {currentUser} = useAuth();

  const Refetchh = () => {
    setShouldRefetch(prevState => !prevState)
  }

  const { fetchedData, refetch } = useGetDataWithFullUrl(`${BASE_URL}getEmployeeKpiSummary&userId=${id}&year=${currentYear}`, [currentYear]);

  const tablesDataSummary = fetchedData?.map((data, index) => {

      return {
        tableId: 1,
        title: 'Question 1',
        customHeadersSummary: [
          'Question',
          'Employee Rating',
          'Employee Comments',
          'Line Manager 1 Rating',
          'Line Manager 1 Comments',
          'Line Manager 2 Rating',
          'Line Manager 2 Comments',
        ],
        customRowsSummary: [
          {
            Question: data?.Question,
            'Employee Rating': (
              <div className="rating">
                <Rating name="read-only" value={data?.Employeerating} readOnly sx={{ fontSize: '2rem' }} />
              </div >
            ),
            'Employee Comments': data?.Employeecomments,
            'Line Manager 1 Rating': (
              <div className="rating">
                <Rating name="read-only" value={`${JSON.parse(data?.Rating)?.filter(rating => rating.Role === "LineManager1")[0]?.managerrating}`} readOnly sx={{ fontSize: '2rem' }} />
              </div >
            ),
            'Line Manager 1 Comments': `${JSON.parse(data?.Rating)?.filter(rating => rating.Role === "LineManager1")[0]?.managercomments || "NA"}`,
            'Line Manager 2 Rating': (
              <div className="rating">
                <Rating name="read-only" value={`${JSON.parse(data?.Rating)?.filter(rating => rating.Role === "LineManager2")[0]?.managerrating}`} readOnly sx={{ fontSize: '2rem' }} />
              </div >
            ),
            'Line Manager 2 Comments': `${JSON.parse(data?.Rating)?.filter(rating => rating.Role === "LineManager2")[0]?.managercomments || "NA"}`,
          },
        ],
      }
    })

  // const [textValuesSummary, settextValuesSummary] = useState(new Array(tablesDataSummary?.length).fill(''));

  const handleContinueClickSummary = () => {
    if (currentTableIndexSummary < tablesDataSummary?.length - 1) {
      setCurrentTableIndexSummary(currentTableIndexSummary + 1);
    }
  };

  const handlePreviousClickSummary = () => {
    if (currentTableIndexSummary > 0) {
      setCurrentTableIndexSummary(currentTableIndexSummary - 1);
    }
  };

  const currentTableDataSummary = fetchedData ? tablesDataSummary[currentTableIndexSummary] : '';

  const yearOfJoining = currentUser?.joiningYear;

  const yearOptions = useMemo(() => {
      const currentYear = new Date().getFullYear();
      const years = Array.from(
          { length: currentYear+1 - yearOfJoining + 1 }, // Calculate the range dynamically
          (_, index) => yearOfJoining + index // Start from yearOfJoining
      );
      return years;
  }, [yearOfJoining]);

  const { commonDataFetch } = useFetchCommon('getKpiReportMessage',{id,token})
  
  return (
    <>
      <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i>  {/* icon  */} KPI Summary</h5>

      {isvisible && (
        <div className="alert bg-warning-subtle d-flex flex-column flex-sm-row mb-15">
          <span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
            <i className="bi bi-exclamation-triangle-fill fs-2hx px-5 svg-icon-muted text-warning"></i>
          </span>
          {commonDataFetch?.map((data, index) => (
            <div key={index} className="d-flex flex-column pe-0 pe-sm-10">
              <h5 className="mb-1 text-primary">{data?.policyTitle}</h5>
              <span className="fw-bold fs-7 text-gray-600">{data?.description}</span>
            </div>
          ))}
          <button
            type="button"
            className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
            onClick={() => handleAlert()}
          >
            <span className="svg-icon svg-icon-1 svg-icon-primary">
              <i className="bi bi-x fs-2hx px-5 svg-icon-muted text-primary"></i>
            </span>
          </button>
        </div>
      )}

      <div className='row g-5 g-xl-8 mb-md-5 mb-xl-10'>
        <div className='col-md-12 col-xl-4'>
          <div className='card h-md-100'>
            <div className='card-body' style={{ background: "#f0eded" }}>
              {/* <h3 className='mb-5 text-primary'>Enter the  year </h3> */}
              <Autocomplete
                disablePortal
                fullWidth
                id='yearField'
                color="primary"
                options={yearOptions}
                defaultValue={currentYear}
                onInputChange={(event, value) => {
                  setCurrentYear(parseInt(value ?? presentYear.toString()))
                  // setShouldRefetch(prevState => !prevState)
                  Refetchh()

                }}
                onChange={(event, value) => {
                  setCurrentYear(parseInt(value ?? presentYear.toString()))
                  // setShouldRefetch(prevState => !prevState)
                  Refetchh()

                }}
                classes={{
                  input: 'text-primary'
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    required
                    focused
                    label="Year"
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>

      {currentTableDataSummary &&<Card style={{ background: "#f0eded" }}>

        <Card.Header className="d-flex justify-content-center">
          {/* Additional div */}
          <div className='d-flex align-items-center w-600px w-sm-600px flex-column mt-3'>
            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
              <span className='fw-bold fs-6 text-gray-600'>Question Completion</span>
              <span className='fw-bolder fs-6 text-primary'>{tablesDataSummary?.length === 0 ? "0" : currentTableIndexSummary + 1}/{tablesDataSummary?.length}</span>
            </div>
            <div className='h-8px mx-3 w-100 bg-body-secondary mb-3'>
              <div
                className='bg-primary rounded h-8px'
                role='progressbar'
                style={{ width: `${tablesDataSummary?.length === 0 ? tablesDataSummary?.length : ((currentTableIndexSummary + 1) / tablesDataSummary?.length) * 100}%` }}
              ></div>
            </div>
          </div>
          {/* End of additional div */}
          {/* <h1>{currentTableDataSummary.title}</h1> */}
        </Card.Header>

       <Card.Body className='mb-10 mt-5 p-20'>
          <div>
            <table className="table table-rounded border border-gray-100 table-row-bordered table-row-gray-100 gy-4 gs-7 shadow" style={{ background: "#f0eded" }}>
              <tbody>
                {currentTableDataSummary && currentTableDataSummary.customHeadersSummary && currentTableDataSummary.customRowsSummary ? (
                  currentTableDataSummary.customHeadersSummary.map((header, indexHeader) => (
                    <tr key={indexHeader}>
                      {header === 'Line Manager 1 Rating' || header === 'Line Manager 1 Comments' || header === 'Line Manager 2 Rating' || header === 'Line Manager 2 Comments' ? (
                        <th className="fw-bold text-white" style={{ background: "#143983", width: "150px" }}>
                          {header}
                        </th>
                      ) : (
                        <>
                          <th className="fw-bold text-white" style={{ background: "#6255a3", width: "150px" }}>
                            {header}
                          </th>
                        </>
                      )}
                      <td className="fw-bold text-primary" key={indexHeader} style={{ height: '60px' }}>
                        {currentTableDataSummary ? currentTableDataSummary.customRowsSummary[0][header] : 'NA'} {/* Assuming customRowsSummary[0] is always defined */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {tablesDataSummary?.length !== 0 && <div className="d-grid gap-2 d-md-flex justify-content-md-between mt-5">
            <Button className="btn btn-sm" variant="primary" onClick={handlePreviousClickSummary} disabled={currentTableIndexSummary === 0}>
              Previous
            </Button>
            <Button className="btn btn-sm" variant="primary" onClick={handleContinueClickSummary}>
              {currentTableIndexSummary === tablesDataSummary?.length - 1 ? 'Done' : 'Next'}
            </Button>
          </div>}
        </Card.Body>
          
      </Card>}
      {!currentTableDataSummary &&<Card className="d-flex justify-content-center align-items-center  " style={{ minHeight: "300px" }}>
          <p className='text-primary fw-bold fs-3'>Summary is empty</p>
      </Card>}
    </>
  );
}

export { EmployeeKPI }