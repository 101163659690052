import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Autocomplete, Box, IconButton, TextField } from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { KTIcon } from '../../../_metronic/helpers';
import { Finesdata } from './makeData';
import { MaterialReactTable } from 'material-react-table';
import VehiclesForm from './VehiclesForm';
import useGetDataWithFullUrl from '../../hooks/getDataWithFullUrl';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../utils';
import EditDepartment from '../../pages/SuperAdminPages/Department/EditDepartment';
import { message } from "antd";
import axios from 'axios';
import PopUp from '../Modal/Modal';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import VehicleFineEdit from './VehicleFineEdit';
import { useNavigate } from 'react-router-dom';

const VehicleFinesTable = () => {
  const [data, setData] = useState(Finesdata);
  const nowDate = new Date();
  const [year, setYear] = useState(nowDate.getFullYear());

  const id = Cookies.get('userId');
  const token = Cookies.get("token")

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const navigate = useNavigate()

  const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getVehicleFinesDetails&userId=${id}&year=${new Date().getFullYear()}`, [shouldRefetch])

  const tableData = fetchedData || [];

  const showMessage = (msg) => {
    message.destroy();
    message.success({
      content: msg,
      duration: 1
    });
  };

  const a = () => {
    message.info({
      content: "This division can't delete",
      duration: 2
    });
  }

  const exportToExcel = (data) => {
    const columns = [
      { header: 'Name', key: 'carModel' },
      { header: 'Car Plate Number', key: 'carPlateNo' },
      { header: 'Year', key: 'modelYear' },
      { header: 'Insurance Amt', key: 'insuranceAmount' },
      { header: 'Mulkiya Exp Date', key: 'mulkiyaExpDate' },
      { header: 'Registration Status', key: 'registrationStatus' },
      // Add monthly fine and status columns
      ...['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(month => [
        { header: `${month} Fine(OMR)`, key: `${month}_fineAmount` },
        { header: `${month} Status`, key: `${month}_status` }
      ]).flat()
    ];
  
    // Prepare the data
    const rows = data.map(item => {
      const row = {
        carModel: item.carModel,
        carPlateNo: item.carPlateNo,
        modelYear: item.modelYear,
        insuranceAmount: item.insuranceAmount,
        mulkiyaExpDate: item.mulkiyaExpDate,
        registrationStatus: item.registrationStatus,
      };
  
      const fineDetails = item.fineDetails ? JSON.parse(item.fineDetails) : [];
      fineDetails.forEach(detail => {
        row[`${detail.month}_fineAmount`] = detail.fineAmount;
        row[`${detail.month}_status`] = detail.status;
      });
  
      return row;
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows, { header: columns.map(col => col.key) });
    worksheet['!cols'] = columns.map(col => ({ wch: col.header.length + 10 }));
    XLSX.utils.sheet_add_aoa(worksheet, [columns.map(col => col.header)], { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Vehicle Details');
    XLSX.writeFile(workbook, 'Vehicle Fine Details.xlsx');
  };
  

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];


  const columns = useMemo(
    () => [
      {
        id: 'other Details',
        header: 'Car Details',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'carModel',
            header: 'Name',
            size: 150,
            enablePinning: false,
            muiTableHeadCellProps: {
              className: 'px-7 py-3 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
          },
          {
            accessorKey: 'carPlateNo',
            header: ' Car Plate Number',
            size: 150,
            enablePinning: false,
            muiTableHeadCellProps: {
              className: 'px-7 py-3 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
          },

          {
            accessorKey: 'modelYear',
            header: 'Year',
            size: 150,
            enablePinning: false,
            muiTableHeadCellProps: {
              className: 'px-7 py-3 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
          },
          {
            accessorKey: 'insuranceAmount',
            header: 'Insurance Amt',
            size: 150,
            enablePinning: false,
            muiTableHeadCellProps: {
              className: 'px-7 py-3 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
          },
        ],
      },
      {
        id: 'mulkiya registration',
        header: 'Mulkiya Registration',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'mulkiyaExpDate',
            header: 'Date',
            size: 150,
            enablePinning: false,
            muiTableHeadCellProps: {
              className: 'px-7 py-3 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
          },
          {
            accessorKey: 'registrationStatus',
            header: ' Status',
            size: 150,
            enablePinning: false,
            muiTableHeadCellProps: {
              className: 'px-7 py-3 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
          },
        ],
      },
      {
        id: 'jan',
        header: 'Jan',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Jan')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Jan')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'feb',
        header: 'Feb',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Feb')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Feb')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'mar',
        header: 'Mar',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Mar')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Mar')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'apr',
        header: 'Apr',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Apr')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Apr')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'may',
        header: 'May',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'May')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'May')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'jun',
        header: 'Jun',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Jun')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Jun')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'jul',
        header: 'Jul',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Jul')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Jul')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'aug',
        header: 'Aug',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Aug')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Aug')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'sep',
        header: 'Sep',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Sep')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Sep')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'oct',
        header: 'Oct',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Oct')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Oct')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'nov',
        header: 'Nov',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Nov')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Nov')?.status || 'N/A';
            },
          },
        ],
      },
      {
        id: 'dec',
        header: 'Dec',
        muiTableHeadCellProps: {
          className: 'px-7 py-4 text-black',
          sx: {
            backgroundColor: '#9EC3E5 !important',
            outline: '6px',
            outlineStyle: 'solid',
            outlineColor: 'var(--bs-light)',
            outlineOffset: '-5px',
            borderRadius: '20px',
          },
        },
        columns: [
          {
            accessorKey: 'FineAmount',
            header: 'Fine(OMR)',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Dec')?.fineAmount || 'N/A';
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            size: 150,
            muiTableHeadCellProps: {
              className: 'px-7 py-4 text-black',
              sx: {
                backgroundColor: '#9EC3E5 !important',
                outline: '6px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              },
            },
            Cell: ({ row }) => {
              const fineDetails = JSON.parse(row.original.fineDetails || '[]');
              return fineDetails.find(detail => detail.month === 'Dec')?.status || 'N/A';
            },
          },
        ],
      },

      // Repeat for each month...
    ],
    [year]
  );

  const headers = {
    'token': token,
    'Content-Type': 'application/json'
  };

  const [vId, setVid] = useState()

  const filterdData = fetchedData?.filter((data, index) => data?.ownershipId == vId)

  const [show, setShow] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [actionStatus, setActionStatus] = useState(false);
  const [deletId, setDeletId] = useState()
  const [editData, setEditData] = useState()



  const handleDelete = (id) => {
    setDeletId(id);
    setShow(true)
  };

  useEffect(()=>{
    if(actionStatus && deletId){
      axios.post(`${BASE_URL_TWO}`, {
        "sp":"delVehicleFines",
        "userId":parseInt(id),
        "vehicleId":deletId
       }, { headers})
        .then(response => {
          showMessage("Data deleted successfully!");
          setShouldRefetch(prev => !prev); // Trigger refetching
          setTimeout(()=>{
            window.location.reload();
          },1000)
        })
        .catch(error => {
          if (error.response?.status === 401) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName, { path: '/' });
            });
            navigate('/error/login')
          } else {
            message.destroy();
            message.error("Something went wrong")
          }
        });
    }
  },[actionStatus,deletId])

  return (

    <>
      <PopUp
        show={show}
        setShow={setShow}
        setActionStatus={setActionStatus}
        title="Delete vehicle fine"
        message="Are you sure you want to delete"
        btnName="Delete"
      />

      <VehicleFineEdit vehicleId={editData} />

      <div className={`card`}>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Vehicle Fines for the year {year}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>[{fetchedData?.length} staffs]</span>
          </h3>
          <div className='card-toolbar'>
            <div className='card-toolbar'>
              <a onClick={()=>exportToExcel(fetchedData)} className='btn btn-sm btn-primary'>
                <KTIcon iconName='file-down' className='fs-2' />
                Export to Excel
              </a>
            </div>
          </div>
        </div>

        <div className='card-body'>

          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableDensityToggle={false}
            enableColumnFilters
            enableColumnOrdering
            enablePinning
            enableRowActions
            initialState={{
              showColumnFilters: false,
              // grouping: ["approval"], 
              sorting: [
                { id: "name", desc: false },
              ],
              expanded: true,
              columnPinning: { left: ["name"] }
            }}
            positionToolbarAlertBanner="bottom"
            renderRowActions={({ row, table }) => [
              <div className='d-flex justify-content-center align-items-center'>
                <IconButton>
                <a href="/"
                  onClick={() => setEditData(row?.original.vehicleId)}
                  className="d-flex align-items-center justify-content-center"
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_7">
                  <Edit className='text-success' />
                </a>
                </IconButton>
                <IconButton onClick={() => handleDelete(row?.original.vehicleId)}>
                  <Delete className='text-danger' />
                </IconButton>
              </div>
            ]}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Action',
                size: 100,
                muiTableHeadCellProps: {
                  className: 'px-7 py-4 text-black',
                  sx: {
                    backgroundColor: '#9EC3E5 !important',
                    outline: '6px',
                    outlineStyle: 'solid',
                    outlineColor: 'var(--bs-light)',
                    outlineOffset: '-5px',
                    borderRadius: '20px',
                  },
                },
              },
            }}
            sortingFns={{
              status: (rowA, rowB, columnID) => {
                const valA = rowA.getValue < String > (columnID)
                const valB = rowB.getValue < String > (columnID)
                if (valA === 'Pending' && valB !== 'Pending') {
                  return 1
                }
                else if (valB === 'Pending' && valA !== 'Pending') {
                  return -1
                }
                else {
                  return valA.toLowerCase() < valB.toLowerCase() ? 1 : -1
                }
              }
            }}
            filterFns={{
              dateFilter: (row, columnId, filterValue) => {
                const filterArray = filterValue.replaceAll(',', ';').split(';');
                const cellVal = row.getValue(columnId);

                if (typeof cellVal === 'string' || cellVal instanceof String) {
                  // Convert the cell value to a Date object
                  const cellDate = new Date(cellVal);

                  if (!isNaN(cellDate.getTime())) { // Check if it's a valid date
                    for (let item of filterArray) {
                      const trimmedItem = item.trim();
                      const filterDate = new Date(trimmedItem);

                      if (!isNaN(filterDate.getTime())) { // Check if filter date is valid
                        // Compare the dates
                        if (
                          cellDate.getFullYear() === filterDate.getFullYear() &&
                          cellDate.getMonth() === filterDate.getMonth() &&
                          cellDate.getDate() === filterDate.getDate()
                        ) {
                          return true;
                        }
                      }
                    }
                  }
                }

                return false;
              }
              ,
              multipleFilter: (row, columnId, filterValue) => {
                const filterArray = filterValue.replaceAll(',', ';').split(';');
                const cellVal = row.getValue(columnId);
                const cellValStr = typeof cellVal === 'string' ? cellVal : String(cellVal);
                for (let item of filterArray) {
                  if (cellValStr.toLowerCase().includes(item.toLowerCase())) {
                    return true;
                  }
                }
                return false;
              }
            }}

            // enableEditing
            // editingMode='modal'
            muiTablePaperProps={{
              sx: {
                backgroundColor: 'var(--bs-card-bg)',
                border: 'none',
                boxShadow: 'none'
              }
            }}
            muiTableProps={{
              className: 'table',
              sx: {
                borderCollapse: 'separate',
                borderSpacing: '0px 4px',
              }
            }}
            muiTableContainerProps={{
              className: 'mb-2',
              sx: {
                backgroundColor: 'var(--bs-light)',
                borderRadius: 'var(--bs-card-border-radius)',
              }
            }}
            muiTableHeadRowProps={{
              className: 'fw-bold bg-light',
              sx: {
                boxShadow: 'none'
              }
            }}
            muiTableHeadCellProps={{
              className: 'px-7 py-3 text-black',
              sx: {
                verticalAlign: 'middle',
                backgroundColor: '#EBEBD3 !important',
                outline: '10px',
                outlineStyle: 'solid',
                outlineColor: 'var(--bs-light)',
                outlineOffset: '-5px',
                borderRadius: '20px',
              }
            }}
            muiTableBodyRowProps={{
              hover: false,
              sx: {
                backgroundColor: 'transparent'
              }
            }}
            muiTableBodyCellProps={{
              className: 'bg-light'
            }}
            muiTopToolbarProps={{
              className: 'card text-light mb-2',
              sx: {
                boxShadow: 'none',
                backgroundColor: '#143983'
              },
            }}
            muiBottomToolbarProps={{
              className: 'text-light mb-5 d-flex',
              sx: {
                boxShadow: 'none',
                backgroundColor: '#143983',
                borderRadius: 'var(--bs-card-border-radius)',
                minHeight: '50px'
              },
            }}
            muiSearchTextFieldProps={{
              className: 'bg-light text-light text-muted'
            }}
            muiTableHeadCellFilterTextFieldProps={{
              helperText: false,
              focused: true,
              inputProps: {
                className: "text-black",
              },
              sx: {
                color: 'black'
              }
            }}
            muiTableHeadCellColumnActionsButtonProps={{
              sx: {
                color: 'black'
              }
            }}
            muiTableHeadCellDragHandleProps={{
              sx: {
                color: 'black'
              }
            }}
            icons={{
              FullscreenIcon: () => (<KTIcon iconName='maximize' className='fs-2 text-white' />),
              FullscreenExitIcon: () => (<KTIcon iconName='cross-circle' className='fs-2 text-white' />),
              ViewColumnIcon: () => (<KTIcon iconName='status' className='fs-2 text-white' />),
              FilterListIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
              FilterListOffIcon: () => (<KTIcon iconName='filter-search' className='fs-2 text-white' />),
              SearchIcon: () => (<KTIcon iconName='search-list' className='fs-2 text-white' />),
              CloseIcon: () => (<KTIcon iconName='cross' className='fs-2 text-black' />),
            }}
            muiTablePaginationProps={{
              className: 'text-white',

            }}
            enableGlobalFilter={false}
            muiToolbarAlertBannerProps={{
              className: 'text-white',
              sx: {
                backgroundColor: '#143983',
              }
            }}
            muiToolbarAlertBannerChipProps={{
              className: 'text-white'
            }}
          />
        </div>
      </div >
    </>
  );
};

export default VehicleFinesTable;