// import { useEffect, useState } from "react";
// import axios from "axios";
// import Cookies from "js-cookie";

// const useGetDataWithFullUrl = (url, dependencies = []) => {

//     const [fetchedData, setFetchedData] = useState();

//     useEffect(() => {
//         const fetchData = async () => {

//             const token = Cookies.get("token");

//             const headers = {
//                 'token': token,
//                 'Content-Type': 'application/json'
//             };

//             try {
//                 const response = await axios.get(url, { headers });
//                 setFetchedData(response?.data?.Data);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };

//         fetchData();

//     }, [dependencies]);

//     return { fetchedData, refetch: fetchedData };
// };

// export default useGetDataWithFullUrl;


import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const useGetDataWithFullUrl = (url, dependencies = [], staffId) => {

    const [fetchedData, setFetchedData] = useState();
    const [isLoading,setIsLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            const token = Cookies.get("token");
            const headers = {
                'token': token,
                'Content-Type': 'application/json',
            };

            try {
                const response = await axios.get(url, { headers });
                setFetchedData(response?.data?.Data);
                setIsLoading(false)
            } catch (error) {
                if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  } else {
                    message.destroy();
                    message.error("Something went wrong")
                  }
            }
        };
        if(!url?.includes('undefined')){
            fetchData();
        }
        
    }, [url, ...dependencies]); // Include 'url' and spread 'dependencies' in the dependency array

    return { fetchedData, refetch: fetchedData,isLoading };
};

export default useGetDataWithFullUrl;
