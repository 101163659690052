import { MixedWidget16 } from '../../../../_metronic/partials/widgets';
import { Box, IconButton } from '@mui/material';
import { DocumentScanner } from '@mui/icons-material';
import { useState } from 'react';
import useFetchData from '../../../hooks/getData';
import { Link } from 'react-router-dom';
import { BASE_URL_TWO } from '../../../../utils';

export function EXPdocuments() {
    const [clickedIndex, setClickedIndex] = useState(null);
    const { fetchedData } = useFetchData('GetExpiredDocumentsForEmployee');

    const handleWidgetClick = (index) => {
        setClickedIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <div className='accordion-item bg-transparent'>
            <div
                id='collapseExample2'
                className='accordion-collapse collapse'
                aria-labelledby='kt_accordion_header_2'
                data-bs-parent='#kt_accordion'
            >
                <div className="card card-body mb-2 text-dark" style={{ backgroundColor: "rgba(207,226,255,255)" }}>
                    <div className="card-body">
                        <div className="tab-content" id="myTabContent">
                            <div className='row g-2 g-xl-4 justify-content-md-center'>
                                {fetchedData?.map((data, index) => (
                                    <a
                                        key={index}
                                        onClick={data?.totalCount > 0 ? () => handleWidgetClick(index) : undefined}
                                        className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
                                        data-bs-toggle={data?.totalCount > 0 ? "collapse" : undefined}
                                        href={data?.totalCount > 0 ? `#table_${index}` : undefined}
                                        role="button"
                                        aria-expanded={clickedIndex === index}
                                        aria-controls={`table_${index}`}
                                    >
                                        <MixedWidget16
                                            className='h-md-20'
                                            chartProgressColor={data?.progresscolor}
                                            chartTrackColor={data?.charttrackcolor}
                                            chartHeight='250px'
                                            chartTitle={data?.ExpiryType}
                                            chartCompleted={data?.totalCount}
                                        />
                                    </a>
                                ))}

                                <br />

                                <div className='accordion accordion-borderless' id='kt_accordion2'>
                                    {fetchedData?.map((data, index) => {
                                        if (index === clickedIndex) {
                                            const expiredDocs = data?.ExpiredDocuments ? JSON.parse(data.ExpiredDocuments) : [];
                                            const expire = data?.ExpiryType;

                                            return (
                                                <div key={index} className='accordion-item bg-transparent'>
                                                    <div id={`table_${index}`} className='accordion-collapse collapse show d-flex flex-column align-items-center d-sm-block' aria-labelledby={`kt_accordion2_header_${index}`} data-bs-parent='#kt_accordion2'>
                                                        <div>
                                                            <p className="text-black-50 mb-5 mt-2 fw-bold">
                                                                <i className="bi bi-dot fs-2 svg-icon-muted text-black-50"></i>
                                                                {expire === "Already Expired" ? "Documents are already expired" : `Documents That Will Expire Within ${expire}`}
                                                            </p>
                                                        </div>
                                                        {expiredDocs.length > 0 && (
                                                            <table className="table table-rounded border border-gray-300 table-row-bordered table-row-gray-300 gy-5 gs-3 sm:gs-7 bg-white shadow">
                                                                <thead>
                                                                    <tr className="fw-bold fs-5 border-bottom border-gray-200 bg-primary text-white">
                                                                        <th><i className="bi bi-person-fill fs-3 me-2 svg-icon-muted text-white"></i>Name</th>
                                                                        <th><i className="bi bi-file-earmark-fill fs-3 me-2 svg-icon-muted text-white"></i>Document Name</th>
                                                                        <th><i className="bi bi-calendar2-day-fill fs-3 me-2 svg-icon-muted text-white"></i>Expiration Date</th>
                                                                        <th><i className="bi bi-hourglass-top fs-3 me-2 svg-icon-muted text-white"></i>Expiration Status</th>
                                                                        <th style={{ width: "15px" }}>View</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {expiredDocs.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-primary fw-bold">{item?.Name}</td>
                                                                            <td className="text-primary fw-bold">{item?.documentType}</td>
                                                                            <td className="text-primary fw-bold"><span className="badge badge-primary p-2">{item?.expiryDate}</span></td>
                                                                            <td className="text-primary fw-bold"><span className="badge badge-light-info p-2">{item?.expirationstatus}</span></td>
                                                                            <td>
                                                                                <Box sx={{ justifyContent: 'center' }}>
                                                                                    <IconButton>
                                                                                        <Link to={item.filePath ? `${BASE_URL_TWO}${item.filePath}` : "#"} onClick={() => {
                                                                                            if (!item.filePath) {
                                                                                                alert("No file found");
                                                                                            }
                                                                                        }} target={item.filePath ? `_blank` : "_self"}>
                                                                                            <DocumentScanner className='text-primary' />
                                                                                        </Link>
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
