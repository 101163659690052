
import { FC, useEffect, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material';
import { Archive, Delete, Edit } from '@mui/icons-material';
import { KTSVG } from '../../../../_metronic/helpers';
import useFetchCommon from '../../../hooks/getCommonData';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, BASE_URL_TWO } from '../../../../utils';
import useGetDataWithFullUrl from '../../../hooks/getDataWithFullUrl';
import { useNavigate } from 'react-router-dom';
import { Button, message } from "antd";
import PopUp from '../../../components/Modal/Modal';
import { Modal } from 'bootstrap';

const KPIQuestions = () => {


  const [currentYear, setCurrentYear] = useState();
  const navigate = useNavigate()

  const [qestn, setQstn] = useState();

  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [deadline,setDeadline] = useState()
  const [filterdSelectedItemToEdit, setFilterdSelectedItemToEdit] = useState([])
  const id = Cookies.get('userId');
  const token = Cookies.get("token")
  const headers = {
    'token': token,
    'Content-Type': 'application/json',
  };

  const getCommonData = useFetchCommon('getDivisionList',{id,token}).commonDataFetch

  // console.log("getCommonData", getCommonData ? getCommonData[0] : '');

  const [employerDivisionId, setEmployerDivisionId] = useState(6)

  const [employerQuestnType, setEmployerQuestnType] = useState();

  // const handleQuestionChange =(e)=>{
  //   setEmployerQuestnType(e.target.value)
  //   setShouldRefetch(prevState => !prevState)
  // }

  const handleSelectChange = (e) => {
    setEmployerQuestnType(e.target.value)
    setShouldRefetch(prevState => !prevState)
  };

  const handleChangeDivisionId = (e) => {
    setEmployerDivisionId(e.target.value)
    setShouldRefetch(prevState => !prevState)
  }



  const [qstnType, setQstnType] = useState()

  const [editedItem, setEditedItem] = useState()
  const [currentDeadline, setCurrentDeadline] = useState();
  const [dummy, setDummy] = useState();


  const { fetchedData } = useGetDataWithFullUrl(`${BASE_URL}getKpiQuestionsForAdmin&userId=${id}&questionType=${qstnType ? qstnType : employerQuestnType}&divisionId=${employerDivisionId}`, [shouldRefetch])

  const [selectedData, setSelectedData] = useState("");

  const [deactivateStatuses, setDeactivateStatuses] = useState([])

  useEffect(() => {
    setEditedQstn(editedItem?.questionText)
  }, [editedItem])

  const handleCheckboxChange = (value) => {
    if (selectedData?.split(",")?.includes(value)) {
      setSelectedData(selectedData.split(',').filter(item => item !== value).join(','));
    } else {
      setSelectedData(selectedData ? `${selectedData},${value}` : `${value}`);
    }
  };

  useEffect(() => {
    setDeactivateStatuses(fetchedData?.map(item => item.status))
  }, [fetchedData])

  const handleStatus = (index, checked, qstnId) => {
    // Update state for the specific question
    const newStatuses = [...deactivateStatuses];
    newStatuses[index] = checked;
    setDeactivateStatuses(newStatuses);
    const updateStatus = async () => {
      const response = await axios.post(`${BASE_URL_TWO}`,
        {
          "sp": "updKPIToggleForStatus",
          "userId": id,
          "kpiQuestionId": qstnId,
          "status": checked
        }, { headers })
    }
    updateStatus();
  };

  const fetchDeadLine = () => {
    try {
      axios.get(`${BASE_URL}getCurrentDeadLine&userId=${id}`, { headers }).then((response) => {
        setCurrentDeadline(response?.data?.Data?.[0]?.deadLine.split('T')[0])
        setDeadline(response?.data?.Data?.[0]?.deadLine.split('T')[0])
      })
    } catch (error) {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        message.destroy();
        message.error(error?.Message || "Something went Wrong");
      }
    }
  }

  useEffect(() => {
    fetchDeadLine();
  }, [])

  const [editedQstn, setEditedQstn] = useState()

  const showMessage = (msg) => {
    message.destroy();
    message.success({
      content: msg,
      duration: 4 // Duration in seconds
    });
  };

  const a = (msg) => {
    message.destroy();
    message.info({
      content: msg,
      duration: 2
    });
  }

  const [show, setShow] = useState(false);
  const [actionStatus, setActionStatus] = useState(false);
  const [deletId, setDeletId] = useState()


  const handleSave = () => {
    axios.post(`${BASE_URL_TWO}`, {
      "sp": "insKpiDeadLine",
      "userId": id,
      "deadLine": deadline
    }, { headers }).then(() => {
      showMessage('successfully saved')
      setCurrentDeadline(deadline)
      fetchDeadLine();
    }).catch((error) => {
      if (error.response?.status === 401) {
        Object.keys(Cookies.get()).forEach((cookieName) => {
          Cookies.remove(cookieName, { path: '/' });
        });
        navigate('/error/login')
      } else {
        a("New deadline must be after the current deadline")
        fetchDeadLine();
      }

    })
  }

  // const [checkedDivisions, setCheckedDivisions] = useState()

  useEffect(() => {
    if (actionStatus && deletId) {
      axios.post(`${BASE_URL_TWO}`, {
        "sp": "delKpiQuestions",
        "userId": parseInt(id),
        "kpiquestionId": deletId
      }, { headers })
        .then(() => {
          showMessage("Deleted successfully");
          setShouldRefetch(prevState => !prevState);
          setActionStatus(false)
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000)
        }).catch((error) => {
          if (error.response?.status === 401) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName, { path: '/' });
            });
            navigate('/error/login')
          } else {
            a("Failed to delete")
          }
        })
    }
  }, [actionStatus, deletId])



  return <>
    <PopUp
      show={show}
      setShow={setShow}
      setActionStatus={setActionStatus}
      title="Delete question"
      message="If you deleted , then it will delete from all divisions.Are you sure you want to delete ?"
      btnName="Delete"
    />

    <h5 className="text-black-50 mb-10"><i className="bi bi-caret-right-fill fs-2 me-2 svg-icon-muted text-black-50"></i> Add / Edit KPI Question </h5>


    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
      <div className="modal-dialog" style={{ maxWidth: '800px' }}>
        <div className="modal-content">

          <div style={{ justifyContent: 'space-between' }} className="modal-header bg-primary-subtle">
            <h5 className="modal-title text-primary"> Add New Question </h5>

            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              aria-label="Close"
              data-bs-dismiss="modal"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>

          </div>

          <div className="modal-body">

            <div className='row mb-1'>
              <label className='col-lg-5 col-form-label fw-bold fs-6'>Question Type</label>

              <div className='col-lg-6 fv-row'>
                <select
                  onChange={(e) => setQstnType(e.target.value)}
                  value={qstnType}
                  className="form-select text-white fs-7 bg-primary fw-bold" aria-label="Default select example">
                  <option> select the type</option>
                  <option className="fw-bold p-10" value="General Question">General Question</option>
                  <option className="fw-bold p-10" value="Professional Question">Professional Question</option>
                </select>
              </div>

            </div>

            <div className='row mt-5 mb-1 fw-bold'>
              <label className='col-lg-5 col-form-label fw-bold fs-6'>Select Divisions</label>
              <div className='col-lg-6 fv-row'>
                <div className="row">

                  <Grid container sx={{ bgcolor: '', justifyContent: '' }}>

                    {
                      getCommonData?.map((data, index) =>
                        <Grid container sx={{ ml: 1, mt: 1 }} xs={11} sm={11} md={5} lg={5}>
                          <div className="form-check mb-2">
                            <input onChange={() => handleCheckboxChange(`${data?.divisionId}`)}
                              className="form-check-input border border-primary"
                              type="checkbox" value="Admin"
                              
                              checked={selectedData.split(",").includes(`${data?.divisionId}`)} // Check if the divisionId is in selectedData
                              id={`division-${data?.divisionId}`} />
                            <label className="form-check-label text-primary" htmlFor="Admin">
                              {data?.divisionName}
                            </label>
                          </div>
                        </Grid>
                      )
                    }

                  </Grid>

                </div>
              </div>
            </div>

            <div className='row mb-1 mt-5'>
              <label className='col-lg-5 col-form-label fw-bold fs-6'>Question Content</label>
              <div className='col-lg-6 fv-row'>
                <div className="mb-3 w-100">

                  <textarea
                    onChange={(e) => setQstn(e.target.value)}
                    value={qestn}
                    className="form-control" id="exampleFormControlTextarea1" style={{ height: "150px" }}></textarea>
                </div>
              </div>
            </div>

          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn bg-body-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>


            <button onClick={async () => {

              try {
                if (selectedData.length === 0) {
                  a("please select a division")
                } else if (!qstnType) {
                  a("please select the type")
                } else if (!qestn) {
                  a("question is required")
                } else {
                  await axios?.post(`${BASE_URL_TWO}`, {
                    "sp": "insKpiQuestions",
                    "userId": id,
                    "questionText": qestn,
                    "questionType": qstnType,
                    "divisionId": selectedData

                  }, { headers }).then(() => {
                    showMessage("Successfully created")
                    // Close the modal manually
                    setQstnType('');
                    setSelectedData("")
                    setQstn('');
                    setShouldRefetch(prevState => !prevState)
                    const modalElement = document.querySelector('#kt_modal_1'); // Replace with your modal's ID
                    const modal = Modal.getInstance(modalElement);
                    modal.hide();
                    // setTimeout(() => { window.location.reload() }, 1000);
                  }).catch((error) => {
                    if (error.response?.status === 401) {
                      Object.keys(Cookies.get()).forEach((cookieName) => {
                        Cookies.remove(cookieName, { path: '/' });
                      });
                      navigate('/error/login')
                    } else {
                      a("Something went wrong")

                    }
                  })
                }

              }
              catch {
                a('Questions not created')
              }

            }} type="button" className="btn btn-primary" >
              Add
            </button>
          </div>

        </div>
      </div>

    </div>

    {/* All Questions Details */}

    <div className='d-flex justify-content-between align-items-center'>
      <div className='mb-4'>
        <div className='d-inline-block me-2'>
          <label htmlFor="" className='fw-bold'>Deadline</label> <br />
          <input type="date" className='py-2 px-2 rounded boder-primary bg-secondary border-1px-solid' value={deadline} onChange={(e) => {
            setDeadline(e.target.value)
          }} />
        </div>
        <button className="btn btn-sm bg-primary text-white" type="button" onClick={handleSave}><i className="bi bi-check-circle-fill fs-2 svg-icon-muted text-white me-3 mb-.5"></i>Save</button>
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end" >
        {(new Date() < new Date(currentDeadline)) && <button className="btn btn-sm bg-primary text-white" type="button" data-bs-toggle="modal" data-bs-target="#kt_modal_1" >
          <i className="bi bi-plus-circle fs-2 svg-icon-muted text-white me-3 mb-.5"></i>
          Add
        </button>}
        {(new Date() > new Date(currentDeadline)) && <button className="btn btn-sm bg-primary text-white" type="button" onClick={() => a("DeadLine is ended")}>
          <i className="bi bi-plus-circle fs-2 svg-icon-muted text-white me-3 mb-.5"></i>
          Add
        </button>}
        {}
      </div >
    </div>

    <table className="table table-rounded border border-gray-100 table-row-bordered table-row-gray-100  gy-5 gs-7 shadow" style={{ background: "#e8e6e6" }}>
      <thead>

        <tr className="fw-bold fs-5 border-bottom border-gray-200 text-white bg-primary">

          <th style={{ width: "20px" }}></th>

          <th colSpan={3}>

            <div className="d-flex flex-row">

              <select
                onChange={handleSelectChange}
                value={employerQuestnType}
                className="form-select text-white fs-7 bg-primary fw-bold mx-5"
                // aria-label="Default select example"
                style={{ width: "200px" }}
              >
                <option> Select the type</option>
                <option className="fw-bold p-10" value="General Question">General Question</option>
                <option className="fw-bold p-10" value="Professional Question">Professional Question</option>
              </select>

              <select onChange={handleChangeDivisionId} className="form-select text-white fs-7 bg-primary fw-bold" aria-label="Default select example" style={{ width: "200px" }}>
                <option>Select a division</option>
                {
                  getCommonData?.map((data) => <option className="fw-bold" value={data?.divisionId}>{data?.divisionName}</option>)
                }

              </select>

            </div>
          </th>
          <th></th>

        </tr>
      </thead>
      <tbody>

        {
          fetchedData?.map((data, index) =>

            <tr key={index}>
              <td className="text-primary fw-bold" colSpan={1}>{index + 1}</td>

              <td className="text-primary fw-bold" colSpan={3}>
                {data.questionText}
              </td>

              <td>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <div className="form-check form-switch form-check-custom form-check-solid me-10">
                    <input
                      className="form-check-input h-30px w-50px"
                      type="checkbox"
                      value=""
                      id={`flexSwitch${index}`}
                      checked={deactivateStatuses?.[index]}
                      onChange={(e) => {
                        if (new Date() > new Date(currentDeadline)) {
                          a("Deadline is ended");
                        } else {
                          handleStatus(index, e.target.checked, data?.kpiQuestionId)
                        }

                      }}
                    />
                    {/* <label className="form-check-label fw-bold text-primary" htmlFor={`flexSwitch${index}`}>
                 disable question
                </label> */}
                  </div>
                  <div
                    onClick={() => {
                      if (new Date() > new Date(currentDeadline)) {
                        a("Deadline is ended");
                      } else {
                        setSelectedData(
                          JSON.parse(fetchedData?.filter((data, i) => index === i)?.[0]?.divisions)
                            ?.map(item => item?.divisionId)
                            .join(",")
                        );
                        setEditedQstn(data?.questionText)
                        setEditedItem(data);
                      }
                    }}
                    className="btn btn-icon btn-sm btn-light-primary ms-2 bg-white"
                    data-bs-toggle={`${new Date() > new Date(currentDeadline) ? "" : "modal"}`}
                    data-bs-target={`${new Date() > new Date(currentDeadline) ? "" : "#kt_modal_2"}`}
                  >
                    <KTSVG
                      path="/media/icons/duotune/art/art005.svg"
                      className="svg-icon svg-icon-2x"
                    />
                  </div>

                  <div
                    onClick={() => {
                      if (new Date() > new Date(currentDeadline)) {
                        a("Deadline is ended");
                      } else {

                        setShow(true);
                        setDeletId(data.kpiQuestionId);
                      }
                    }}
                    className="btn btn-icon btn-sm btn-light-primary btn-active-light-danger ms-2 bg-white"
                    aria-label="Close"
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen027.svg"
                      className="svg-icon svg-icon-2x"
                    />
                  </div>
                </div>
              </td>
            </tr>

          )
        }

      </tbody>
    </table>

    <Grid container justifyContent='center' my={4}>
      {fetchedData?.length == 0 && "No Data ..."}
    </Grid>

    <div className="modal fade" tabIndex={-1} id="kt_modal_2">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content shadow-none">
          <div className="modal-header bg-primary-subtle">
            <h5 className="modal-title pb-2 pt-2 ms-2 text-primary">
              <i className="bi bi-file-earmark-bar-graph-fill fs-1 px-1 svg-icon-muted text-primary"></i> Edit / View Question Details
            </h5>
            <div
              // onClick={() => window.location.reload()}
              className="btn btn-icon btn-sm btn-light-primary btn-active-light-danger ms-2 bg-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=>setSelectedData('')}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>

          <div className="modal-body">

            <table className="table table-rounded border border-gray-100 table-row-bordered table-row-gray-100  gy-5 gs-7 shadow" style={{ background: "#e8e6e6" }}>

              <thead>
                <tr className="fw-bold fs-5 border-bottom border-gray-200 text-white bg-primary">

                  <th style={{ width: "200px" }}><i className="bi bi-question-square-fill fs-2x px-5 svg-icon-muted text-white"></i>Question Type </th>
                  <th colSpan={2}><i className="bi bi-check-square-fill fs-2x px-5 svg-icon-muted text-white"></i>Division Type </th>
                  <th><i className="bi bi-card-text fs-2x px-5 svg-icon-muted text-white"></i>Question Content</th>
                </tr>
              </thead>

              <tbody>
                {
                  // console.log("data", data?.divisionName);
                  (
                    <tr>

                      <td className="text-primary fw-bold">

                        <div>
                          <p className='border border-primary rounded-3 text-white bg-primary p-3 fs-7'>
                            {editedItem?.questionType}
                          </p>
                        </div>

                      </td>

                      <td className="text-primary fw-bold">

                        {
                          getCommonData?.slice(0, getCommonData?.length / 2).map((data, index) => {

                            // console.log("data", data?.divisionId, data?.divisionName);

                            return (
                              <div className="form-check mb-2">
                                <input className="form-check-input border border-primary" type="checkbox" checked={selectedData?.split(",")?.includes(`${data?.divisionId}`)} value="Admin" id="Admin"
                                  onChange={() => handleCheckboxChange(`${data?.divisionId}`)}
                                />
                                <label className="form-check-label text-primary" htmlFor="Admin">
                                  {data?.divisionName}
                                </label>
                              </div>
                            )
                          }
                          )}

                      </td>

                      <td className="text-primary fw-bold">

                        {getCommonData?.slice(getCommonData?.length / 2, getCommonData?.length).map((data, index) =>

                          <div className="form-check mb-2">
                            <input
                              onChange={() => handleCheckboxChange(`${data?.divisionId}`)}
                              className="form-check-input border border-primary" type="checkbox" checked={selectedData?.split(",")?.includes(`${data?.divisionId}`)} value="Admin" id="Admin" />
                            <label className="form-check-label text-primary" htmlFor="Admin">
                              {data?.divisionName}
                            </label>
                          </div>
                        )}

                      </td>

                      <td className="text-primary fw-bold">

                        <div className="mb-3 w-100">
                          <textarea

                            onChange={(e) => {
                              setEditedQstn(e.target.value)
                            }}
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            style={{ height: "180px" }}
                            value={editedQstn}
                          >

                          </textarea>
                        </div>

                      </td>

                    </tr>
                  )
                }

              </tbody>
            </table>

          </div>
          <div className="modal-footer">

            <button onClick={() => {
              if (selectedData.length === 0) {
                a("please select a division")
              } else if (!editedQstn.trim()) {
                a("question is required")
              } else {
                axios.post(`${BASE_URL_TWO}`, {
                  "sp": "updKpiQuestions",
                  "userId": id,
                  "kpiquestionId": editedItem ? editedItem.kpiQuestionId : '',
                  "questionText": editedQstn ? editedQstn : editedItem?.questionText,
                  "divisionId": selectedData
                }, { headers }).then(() => {
                  showMessage("Successfully edited")
                  setShouldRefetch(prevState => !prevState)
                  setSelectedData('')
                  // Close the modal manually
                  const modalElement = document.querySelector('#kt_modal_2'); // Replace with your modal's ID
                  const modal = Modal.getInstance(modalElement);
                  modal.hide();
                  // setTimeout(() => { window.location.reload() }, 1000);
                }).catch((error) => {
                  if (error.response?.status === 401) {
                    Object.keys(Cookies.get()).forEach((cookieName) => {
                      Cookies.remove(cookieName, { path: '/' });
                    });
                    navigate('/error/login')
                  } else {
                    a("Failed to edit")
                  }
                })
              }
            }} type="button" className="btn btn-primary"
              aria-label="Close">
              Save changes
            </button>

          </div>
        </div>
      </div>
    </div>

  </>

}
export { KPIQuestions }